import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../../assets/assets";
import "./ImprovedCrm.css";
import { Link } from 'react-router-dom';

function ImprovedCrm() {
    return (
        <>
            <section className='improved-crm-section'>
                <Container fluid className="custom-container">
                    <Row className='heading-content justify-content-center align-items-center text-center'>
                        <Col lg={7}>
                            <h5 className='green-clr'>Are You Ready For</h5>
                            <h4 className='theme-clr'>New And Improved Comlink's Crm</h4>
                            <p className='mt-lg-3 mt-md-2 mt-1'>The Comlink CRM platform is the only open-source CRM explicitly designed for residential and commercial telecom dealers. Contact us today to discuss all of our programs and providers you can start offering.</p>
                            <div className='btn-holder'>
                                <Link to="/sign-up"><button className='btn theme-btn'>Lets Connect</button></Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className='services-detail'>
                        <Col lg={6} className="mt-lg-5 mt-3">
                            <div className='services-card light-greenbg'>
                                <div className='crm-img-wraper mb-lg-3 mb-2'>
                                    <img src={Images.crm1} className="img-fluid" alt="img" />
                                </div>
                                <h6 className='mb-lg-3 mb-2'>Commission Reports</h6>
                                <p>This includes the year-to-date, monthly, and weekly sales totals and percentages and charge-back totals and rates, with eye-catching bar graphs broken down by the carrier.</p>
                            </div>
                        </Col>
                        <Col lg={6} className="mt-lg-5 mt-3">
                            <div className='services-card pink-bg'>
                                <div className='crm-img-wraper mb-lg-3 mb-2'>
                                    <img src={Images.crm2} className="img-fluid" alt="img" />
                                </div>
                                <h6 className='mb-lg-3 mb-2'>Address Qualification</h6>
                                <p>Our CRM includes a customer qualification tool that will help locate the best provider in the customer’s area.</p>
                            </div>
                        </Col>
                        <Col lg={6} className="mt-lg-5 mt-3">
                            <div className='services-card lemon-bg'>
                                <div className='crm-img-wraper mb-lg-3 mb-2'>
                                    <img src={Images.crm3} className="img-fluid" alt="img" />
                                </div>
                                <h6 className='mb-lg-3 mb-2'>Customer Interaction</h6>
                                <p>In order to keep a record of your calls for quality assurance and performance purposes, partners can upload the call recordings in the database.</p>
                            </div>
                        </Col>
                        <Col lg={6} className="mt-lg-5 mt-3">
                            <div className='services-card solid-greenbg'>
                                <div className='crm-img-wraper mb-lg-3 mb-2'>
                                    <img src={Images.crm4} className="img-fluid" alt="img" />
                                </div>
                                <h6 className='mb-lg-3 mb-2'>Marketing Leads</h6>
                                <p>You can upload your leads in our database, which you get from social media or another resource. It will help you have composed prospects lists.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ImprovedCrm
