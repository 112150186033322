import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Images } from "../../../assets/assets";
import "./Header.css";
import Lottie from 'react-lottie-player'
import greenCall from "../../../assets/json-files/call-green.json";
import { Container } from "react-bootstrap";

const Header = () => {
    const location = useLocation();

    return (
        <div>
            <header className="site-header">
                <Container className="custom-container">
                    <Navbar expand="lg">
                        <Link to="/">
                            <img
                                src={Images.siteLogo}
                                className="img-fluid site-main-logo"
                                alt="img"
                            />
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="pages-names">
                            <Nav className="ms-auto">
                                <Link to="/" className={location.pathname === "/" ? "active" : ""}>Home</Link>
                                <Link to="/sign-up" className={location.pathname === "/sign-up" ? "active mb-md-0 mb-3" : "mb-lg-0 mb-3"}>Sign Up</Link>
                                {/* <Link to="/legal" className={location.pathname === "/legal" ? "active" : ""}>Legal</Link> */}
                                <div className= {location.pathname === "/#contactUs" ? " active" : ""}><Link to="https://calendly.com/gocomlink/60min" target='_blank'>Contact Us</Link></div>
                            </Nav>
                            <div className="haeder-phone d-flex align-items-center">
                                <a
                                    className="d-flex align-items-center ms-xl-3 ms-lg-2 ms-1 gray-clr"
                                    href="tel:+18335260200"
                                >
                                    <Lottie
                                        className="call-lottie"
                                        loop
                                        animationData={greenCall}
                                        play
                                    />
                                    833-526-0200
                                </a>
                            </div>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </header>
        </div>
    );
};

export default Header;
