import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import pdfFile from "../../../../assets/Comlink Dealer Compensation Guide 9-23-23.pdf";
import Compensate from "../Compensate/Compensate";

const ThirdForm = (props) => {
  const [errors, setErrors] = useState({});
  const startRef = useRef(null);

  useEffect(() => {
    scrollToSection(startRef);
  }, [props.flag]);
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    props?.setData({
      ...props?.data,
      [name]: checked,
    });
  };

  const handleSubmitThird = (e) => {
    // e.preventDefault();
    const newErrors = {};
    // Validate compensate
    if (props?.data.compensate == false) {
      newErrors.compensate = "Please accept the compensate";
    }
  console.log(Object.keys(newErrors).length,'Object.keys(newErrors).length')
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      props.setFlag(!props.flag);
      // props.setData({ ...props.data, ...formData });
      props.setStep(4);
      props.nextStep();
    }
  };
  return (
    <div className="Qoute__content">
      <div className="custom-container">
        <div className="contact-headings text-center">
          <h4 className="theme-clr mb-lg-3 mb-2">Basic Information</h4>
          {/* <p>
            Leave your information below, one of our representatives will get in
            touch with you.
          </p> */}
        </div>
        <div className="row" ref={startRef}>
          <div className="col-12">
            <Compensate data={props.data} />
            <div className="form_content p-2">
              <div className="terms_condition d-flex align-items-start">
                <label className="custom-control overflow-checkbox relative">
                  <input
                    name="compensate"
                    checked={props?.data?.compensate}
                    className={`overflow-control-input ${
                      errors.compensate ? "is-invalid" : ""
                    }`}
                    type="checkbox"
                    onChange={handleCheckboxChange} 
                  />
                  <span className="overflow-control-indicator "></span>
                  <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                    <p className="Submitting_form">
                      I agree to the above rate card. All orders are subject to
                      verification. Any order deemed fraudulent will not be
                      compensated and result in immediate removal from the
                      Comlink program. Compensation can change anytime without
                      written notice. For additional questions contact{" "}
                      <a className="text-lowercase" href="mailto:onboarding@gocomlink.com">
                       onboarding@gocomlink.com
                      </a>{" "}
                      or call <a href="tel:+18335260200">833-526-0200</a>.
                      {/*  Or
                      visit{" "}
                      <a href="mailto:www.comlinkprogram.com">
                        www.comlinkprogram.com
                      </a> */}
                      <span className="red_staric">*</span>
                    </p>
                  </span>
                </label>
              </div>
              {errors.compensate && (
                <div className="red_staric">{errors.compensate}</div>
              )}
              <div className="d-flex  justify-content-between mt-xl-4 mt-3 mb-xl-4 mb-3">
                <div
                  onClick={() => {
                    props.setStep(2);
                    props.previousStep();
                  }}
                  className="btn theme-btn flex-start"
                >
                  Previous
                </div>
                <div
                  onClick={() => handleSubmitThird()}
                  className="btn theme-btn"
                >
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdForm;
