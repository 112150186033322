import React from "react";
import "./ProgressBar.css";
import Tick from "../../../../assets/svgs/TickSvg/Tick";
const ProgressBar = ({ step }) => {
  return (
    <React.Fragment>
      {step == 6 ? null : (
        <React.Fragment>
          <div className="d-flex">
            <h5 className="step_number">Step {step} of 5</h5>
          </div>
          <div className="progressbar_wrap">
            <div className="progress_bar">
              <div className={`number ${step >= 1 ? " active" : null}`}>
                <span>{step > 1 ? <Tick /> : "1"}</span>
              </div>
              <div className={`number ${step >= 2 ? " active" : null}`}>
                <span>{step > 2 ? <Tick /> : "2"}</span>
              </div>
              <div className={`number ${step >= 3 ? " active" : null}`}>
                <span>{step > 3 ? <Tick /> : "3"}</span>
              </div>
              <div className={`number ${step >= 4 ? " active" : null}`}>
                <span>{step > 4 ? <Tick /> : "4"}</span>
              </div>
              <div className={`number ${step >= 5 ? " active" : null}`}>
                <span>{step > 5 ? <Tick /> : "5"}</span>
              </div>
              <div className="progress_line">
                <span
                  style={
                    step == 1
                      ? { width: 30 }
                      : step == 2
                      ? { width: 145 }
                      : step == 3
                      ? { width: 260 }
                      : step == 4
                      ? { width: 375 }
                      : { width: 0 }
                  }
                ></span>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ProgressBar;
