import React from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { Images } from "../../../../assets/assets";
import "./Compensate.css";

function Compensate({ data }) {
  return (
    <>
      <div className="compensate-pg w-100">
        <Row>
          <Col lg={12}>
            <div className="compensate-content d-flex flex-column justify-content-center align-items-center">
              <h5 className="green-clr text-center">Compensation</h5>
              <h4 className="theme-clr text-center">Comprehensive Insights</h4>
            </div>
          </Col>
        </Row>
        <div className="compensate-pdf-text">
          <Row>
            <Col lg={12}>
              <h2 className="theme-clr text-center">Compensation Plan!</h2>
            </Col>
            <Col lg={12}> 
              <div className="compensate-accordian">
                <Accordion>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p className="green-clr text-uppercase"><b>Telecom Carriers</b></p>
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>BuyFlow/Provider</th>
                            <th>Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                            <th>DSL Internet</th>
                            <td>$10</td>
                          </tr> */}

                          {/* start */}

                          {/* for Yellow box */}
                          {data.programName ==
                            "Comlink affiliate referral partner" && (
                            <>
                            <>
                              <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                 Platform C - Monthly Funded - No Chargeback Period
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Internet</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$45.00</td>
                                      <td className="w-30">$35.00</td>
                                      <td className="w-30">$25.00</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$31.25</td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Brightspeed</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00</td>
                                      <td className="w-30">$17.50</td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Buckeye*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>CenturyLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00</td>
                                      <td className="w-30">$17.50</td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Comcast</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$15.00</td>
                                      <td className="w-30">$7.50</td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00</td>
                                      <td className="w-30">$12.50</td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>EarthLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.00</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$62.50</td>
                                      <td className="w-30">$12.50</td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Google Fiber</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$22.50</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>HughesNet</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$30.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mediacom*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$15.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Static</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>T-Mobile**</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00 </td>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon - New</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$30.00 </td>
                                      <td className="w-30">$10.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon - Transfer</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.00 </td>
                                      <td className="w-30">$3.75 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon 5G</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$26.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Windstream</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Video</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Comcast</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$22.50 </td>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DirectTV*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.75 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Phone</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Brightspeed</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>CenturyLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              <>
                              <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform P - Weekly Funded - 90 Days Chargeback Period 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>EarthLink Affiliate Order Entry Portal (Standard & WHI)</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">HyperLink Internet below 100M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$43.75</td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>HyperLink Internet 100M+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$68.75</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Wireless Home Internet (WHI) 50 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$12.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 GB at 5</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.00</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.00</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$18.75</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>200 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50</td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>300 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$50.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>- No Credit Check <br/>
                        -   90 Day Chargeback Program (Unless DID Method)"</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Optimum</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>GIG INTERNET</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.25  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500 MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$68.75  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>All other Speeds </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$43.75 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum Advantage Internet (OAI) *</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$36.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$36.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mobility</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Viasat</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Sales Commission</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$50.00  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Windstream</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Fiber at 1GB and greater</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$75.00  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Fiber (speed btw 500M & 1GB)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$62.50   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Internet (speed under 500M)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$43.75   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic secure</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">5.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Frontier</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$150.00  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$125.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$106.25    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$56.25   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Fiber Internet Under 500M </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Digital Voice & Unlimited Voice </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$31.25    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 5 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 1 Gig </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 500M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade &lt; 500M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>All HSI Internet </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$12.50   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Whole Home Wi-Fi</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$11.25   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier Secure </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>

                              {/* brigevine */}
                              <>
                              <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform B - Monthly Funded - 90 Days Chargeback Period 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>COX</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">Cable Internet Go Fast 100 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$17.25 </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Faster 250 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Even Faster 500 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Super Fast 1  Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.25 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Beyond Fast 2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$33.25 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Starter</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$9.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Preferred</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$15.75 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Preferred Plus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Ultimate</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$22.25  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>VOIP Voice Preferred</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.25  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>more carriers</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$18.75   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.75   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>TDS all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.50  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>

                              {/* Platform R - Monthly Funded -  90 Days Chargeback Period */}
                              <>
                               <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform R - Monthly Funded - 90 Days Chargeback Period
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T - NDS</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">AT&T INTERNET 1Gig+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$110.00  </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 500Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$90.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 300Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$90.00   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 100Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$90.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 50-75Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$60.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <td>AT&amp;T INTERNET &lt; 50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$30.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                            
                          
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Wireless </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Gross Add  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Next Up </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Extra </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Elite/Premium  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Tablet / Wearable Activation </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mobile Insurance </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">#VALUE!   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Referrals AARP </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Auto Bill Pay  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$2.00 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* Platform D - Weekly Funded -  90/180 Days Chargeback Period  */}
                          
                              <>
                                <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform D - Weekly Funded - 90/180 Days Chargeback Period 

                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T - NDS</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">AT&T INTERNET 1Gig+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$125.00   </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 500Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$100.00   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 300Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$100.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 100Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 50-75Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr> 

                                    <td>AT&T INTERNET &lt; 50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$31.25   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                            
                          
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Copper to Fiber Migrations</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 1GIG+  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 500 Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00     </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 300 &lt; Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$18.75    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                            
                              <>
                              <tr>

                              <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Wireless </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                             
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Gross Add  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Next Up  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Extra </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Elite   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Auto Bill Pay  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$1.25    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* <tr>
                                <td className="text-center">90 Day Chargeback Period	</td>
                              </tr> */}

                              {/* centurtylink */}
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>CenturyLink/Lumen</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>900MBPS+ </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$75.00      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-899MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$68.75      </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10-99MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$31.25     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Less than 10MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Local Wireline  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Basic Service </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Residential Service Package   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.00      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}

                              {/* frontier communication */}
                              <>
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Frontier Communications</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5GIG</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$119.00      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2GIG  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$100.25       </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1GIG </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.50      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$50.25      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Fiber under 500MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.75     </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DSL </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$12.75      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Copper to Fiber Upgrade  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$32.50       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Digital Voice  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$40.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier Secure  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}
                              
                              {/* windstreamkinetics */}
                               
                              <>
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Windstream/Kinetic</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>&gt; 100MPBS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$31.25      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-499MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$40.00       </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500-999MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$45.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1GIG+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$51.25       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                             
                              
                            {/* bundlepackage */}
                       
                             <>  
                              
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Bundle Packages</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle Voice with HIS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$45.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle 100-499MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$53.75        </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle 500-999MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$58.75        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle 1GIG+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$63.75       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                             
                              
                              {/* kinetic secure */}
                             
                       
                               
                             <> 
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Kinetic Secure</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Secure </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$1.25       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Complete  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$2.00        </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Total </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$2.50        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}

                              {/* earthlink */}
                             
  
                             <> 
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Earthlink &lt; 100MBPS</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                             
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Speeds up to 99mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$45.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Speeds 100+mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$50.00         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>12 AT&T Markets - 100mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$62.50         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>12 AT&T Markets - 300mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.50         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* Earthlink wireless internet */}
                            <>
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Earthlink Wireless Internet</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$40.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$30.00         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$12.50          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* brightspeed */}
                             
                            <>
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Brightspeed</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>900MBPS+ </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$75.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-899MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$68.75         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10-99MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$31.25          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Less than 10MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Local Wireline </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Basic Service </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.50            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Residential Service Package </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrades </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Double Play </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* altafiber */}
                            <>
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Altafiber - Cincinnati Bell</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>750MB - 1GB  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$33.75         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100MB - 500MB </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$28.75          </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10MB - 50MB </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$21.25          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DSL &lt; 10MB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$15.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Phone  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.25           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* wow! */}
                            <>
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>WOW!</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1200MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1000MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50           </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$31.25          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>200MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$15.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Phone   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$8.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Whole Home Wifi  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Data </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                              {/* hugesnet */}
                              
                            <>
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th >HughesNet</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Commission  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$30.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Bonus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00           </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>MDF </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                              {/* directtv */}

                           <>
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>DIRECTV</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Low  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$53.75         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Medium</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$18.75            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>High/Unknown </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$1.25            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Protection Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.75             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Sports </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.75             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Choice and Above </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$8.75            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrade </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              
                              
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* installation */}
                          <>
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Installations</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$37.50          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2 Room</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$43.75             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>3 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$50.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>4 Room</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$56.25              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$62.50              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>6 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$68.75            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrade Install </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                                 
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Service Call Fee </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">($25.00)             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              
                              
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                               {/* hugesnet */}
                               <>
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">HughesNet - Monthly Funded - No Chargeback Period</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Commission</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$30.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Bonus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.00             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>MDF</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>RESIDUAL (up to 40 months)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>

                              {/* quarterly bonus */}
                              <>
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">QUARTERLY BONUS</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>300 plus sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 to 299 sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$8.75              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 to 149 sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.75               </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>less than 75</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">  $2.50            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>

                              {/* spectrum */}
                              <>
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">Spectrum Residential - Weekly Funded - 90 Days Chargeback Period  </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Gig or above  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$55.00              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum below Gig </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.00              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>7Mobility Line </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.00                </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus 1000 plus activations  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $5.00             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus  500 to 999 activations </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $3.00              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus 240 to 499 activations  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $2.00             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* lastspectrum */}
                              <>
                                <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">Spectrum Business - Monthly Funded - No Chargeback Period   </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum all speeds   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> 3x MRC              </td>
                                    
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>RESIDUAL  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                            
                            
                            
                            

                              
                             
                              
                            </>
                          )}
                        

                          

                          {/* for pink box */}
                          {data.programName ==
                            "Comlink Call Center Partner" && (
                            <>
                            <>
                              <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                  Platform C - Monthly Funded - No Chargeback Period
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Internet</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$117.00 </td>
                                      <td className="w-30">$91.00 </td>
                                      <td className="w-30">$50.00 </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$81.25 </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Brightspeed</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00 </td>
                                      <td className="w-30">$45.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Buckeye*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$26.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>CenturyLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00 </td>
                                      <td className="w-30">$45.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Comcast</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$39.00 </td>
                                      <td className="w-30">$19.50 </td>
                                      <td className="w-30">$10.00 </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00 </td>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>EarthLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$162.50 </td>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Google Fiber</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$58.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>HughesNet</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$78.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mediacom*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$39.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Static</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>T-Mobile**</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00 </td>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon - New</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$78.00 </td>
                                      <td className="w-30">$26.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon - Transfer</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$26.00 </td>
                                      <td className="w-30">$9.75 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon 5G</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$68.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Windstream</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              <>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Video</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$45.50 </td>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Comcast</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$58.50 </td>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$45.50 </td>
                                      <td className="w-30">$32.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DirectTV*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$9.75 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              </>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Phone</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Brightspeed</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>CenturyLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform P - Weekly Funded -  90 Days Chargeback Period 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>EarthLink Affiliate Order Entry Portal (Standard & WHI)</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">HyperLink Internet below 100M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$113.75 </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>HyperLink Internet 100M+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$178.75 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Wireless Home Internet (WHI) 50 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$32.50  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 GB at 5</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>$100.00 </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$48.75  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>200 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>300 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$130.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>- No Credit Check <br/>
                        -   90 Day Chargeback Program (Unless DID Method)"</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Optimum</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>GIG INTERNET</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$211.25   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500 MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$178.75   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>All other Speeds </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$113.75  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum Advantage Internet (OAI) *</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$94.25 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$94.25  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mobility</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.50   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Viasat</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Sales Commission</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$130.00  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Windstream</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Fiber at 1GB and greater</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$195.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Fiber (speed btw 500M & 1GB)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$162.50    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Internet (speed under 500M)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$113.75    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic secure</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Frontier</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$390.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$325.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$276.25    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$146.25    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Fiber Internet Under 500M </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Digital Voice & Unlimited Voice </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.25     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 5 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 1 Gig </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 500M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade &lt; 500M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>All HSI Internet </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$32.50    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Whole Home Wi-Fi</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$29.25    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier Secure </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                                {/* brigevine */}
                                <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform B - Monthly Funded - 90 Days Chargeback Period 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>COX</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                  
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">Cable Internet Go Fast 100 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$44.85  </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Faster 250 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$53.30  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Even Faster 500 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$49.40   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Super Fast 1  Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.65  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Beyond Fast 2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$86.45  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Starter</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$23.40  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Preferred</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$40.95  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Preferred Plus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$49.40  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Ultimate</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$57.85   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>VOIP Voice Preferred</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$8.45   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>more carriers</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$48.75    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.75    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>TDS all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.50   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                                {/* Platform R - Monthly Funded -  90 Days Chargeback Period */}
                              
                                <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform R - Monthly Funded -  90 Days Chargeback Period
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T - NDS</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">AT&T INTERNET 1Gig+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$286.00   </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 500Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$234.00   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 300Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$234.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 100Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$234.00   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 50-75Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$156.00   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <td>AT&amp;T INTERNET &lt; 50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$78.00   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            
                          
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Wireless </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Gross Add  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$169.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Next Up </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Extra </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Elite/Premium  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$18.20    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Tablet / Wearable Activation </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$26.00  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mobile Insurance </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.20   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Referrals AARP </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$26.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Auto Bill Pay  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.20 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                                 {/* Platform D - Weekly Funded -  90/180 Days Chargeback Period  */}
                          
                              
                                 <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform D - Weekly Funded - 90/180 Days Chargeback Period 

                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T - NDS</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">AT&T INTERNET 1Gig+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$325.00   </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 500Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$260.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 300Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$260.00     </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 100Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 50-75Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr> 

                                    <td>AT&T INTERNET &lt; 50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.25    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            
                          
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Copper to Fiber Migrations</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 1GIG+  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 500 Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 300 &lt; Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$48.75   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              <tr>

                              <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Wireless </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Gross Add  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Next Up  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Extra </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Elite   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Auto Bill Pay  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.25    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="text-center">90 Day Chargeback Period	</td>
                              </tr> */}

                              {/* centurtylink */}
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>CenturyLink/Lumen</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>900MBPS+ </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$195.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-899MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$178.75       </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10-99MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.25      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Less than 10MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Local Wireline  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$0.00     </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Basic Service </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.50      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Residential Service Package   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.00      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}

                              {/* frontier communication */}
                              
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Frontier Communications</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5GIG</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$309.40       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2GIG  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$260.65        </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1GIG </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$211.90      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$130.65      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Fiber under 500MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$98.15      </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DSL </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$33.15      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Copper to Fiber Upgrade  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$84.50        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Digital Voice  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$104.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier Secure  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}
                              
                              {/* windstreamkinetics */}
                               
                              
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Windstream/Kinetic</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>&gt; 100MPBS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.25       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-499MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$104.00       </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500-999MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$117.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1GIG+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$133.25       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              
                            {/* bundlepackage */}
                       
                               
                              
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Bundle Packages</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle Voice with HIS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$117.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle 100-499MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$139.75         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle 500-999MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$152.75         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Bundle 1GIG+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$165.75        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              
                              {/* kinetic secure */}
                             
                       
                               
                              
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Kinetic Secure</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Secure </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.25       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Complete  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.20        </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Total </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$6.50        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}

                              {/* earthlink */}
                             
  
                              
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Earthlink &lt; 100MBPS</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                           
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Speeds up to 99mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$117.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Speeds 100+mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$130.00         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>12 AT&T Markets - 100mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$162.50          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>12 AT&T Markets - 300mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$227.50          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* Earthlink wireless internet */}
 
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Earthlink Wireless Internet</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$104.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$78.00         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$32.50           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* brightspeed */}
                             
 
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Brightspeed</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>900MBPS+ </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$195.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-899MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$178.75          </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10-99MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.25           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Less than 10MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Local Wireline </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> Compensation         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Basic Service </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.50            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Residential Service Package </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrades </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Double Play </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$26.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* altafiber */}

                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Altafiber - Cincinnati Bell</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>750MB - 1GB  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.75         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100MB - 500MB </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$74.75           </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10MB - 50MB </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$55.25          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DSL &lt; 10MB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$39.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Phone  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$16.25           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* wow! */}

                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>WOW!</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1200MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1000MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50           </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$81.25           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>200MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$39.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Phone   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$20.80            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Whole Home Wifi  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Data </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                              {/* hugesnet */}
                              

                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>HughesNet</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Commission  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$78.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Bonus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00           </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>MDF </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$45.50            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                              {/* directtv */}

                          
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>DIRECTV</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Low  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$139.75          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Medium</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$48.75             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>High/Unknown </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.25            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Protection Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$9.75              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Sports </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$9.75              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Choice and Above </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$22.75             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrade </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* installation */}
                          
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Installations</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                            
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$97.50           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2 Room</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$113.75             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>3 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$130.00              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>4 Room</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$146.25               </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$162.50               </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>6 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$178.75             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrade Install </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$65.00              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                                 
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Service Call Fee </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">($65.00)             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                               {/* hugesnet */}

                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">HughesNet - Monthly Funded - No Chargeback Period </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Commission</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$78.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Bonus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$13.00             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>MDF</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$45.50              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>RESIDUAL (up to 40 months)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              {/* quarterly bonus */}
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">QUARTERLY BONUS</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>300 plus sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$45.50            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 to 299 sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$22.75               </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 to 149 sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$9.75               </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>less than 75</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $6.50            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                               {/* spectrum */}
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">Spectrum Residential - Weekly Funded -  90 Days Chargeback Period  </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Gig or above  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$143.00              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum below Gig </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$91.00              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>7Mobility Line </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.00                </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus 1000 plus activations  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $13.00            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus  500 to 999 activations </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $7.80               </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus 240 to 499 activations  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $5.20             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* lastspectrum */}
                                <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">Spectrum Business - Monthly Funded - No Chargeback Period   </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum all speeds   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> 3x MRC              </td>
                                    
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>RESIDUAL  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                            
                            </>
                          )}

                          {/* for parrot color */}
                          {data.programName ==
                            "Comlink Door-to-door/event sales partner" && (
                            <>
                              {/* <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                  Platform C - Monthly Funded - No Chargeback Period
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Internet</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$126.00 </td>
                                      <td className="w-30">$98.00 </td>
                                      <td className="w-30">$70.00 </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$87.50 </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Brightspeed</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00 </td>
                                      <td className="w-30">$49.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Buckeye*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$28.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>CenturyLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00 </td>
                                      <td className="w-30">$49.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Comcast</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$42.00 </td>
                                      <td className="w-30">$21.00 </td>
                                      <td className="w-30">$14.00 </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00 </td>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>EarthLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$56.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$175.00 </td>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Google Fiber</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$63.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>HughesNet</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$84.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mediacom*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$42.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Static</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$21.00 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>T-Mobile**</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00 </td>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon - New</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$84.00 </td>
                                      <td className="w-30">$28.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon - Transfer</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$28.00 </td>
                                      <td className="w-30">$10.50 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Verizon 5G</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$73.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Windstream</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Video</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$49.00 </td>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Comcast</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$63.00 </td>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$49.00 </td>
                                      <td className="w-30">$35.00 </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DirectTV*</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50</td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Phone</th>
                                    </tr>

                                    <tr>
                                      <td>ATT</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <th>Class A <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="High Speed">i</button></th>
                                      <th>Class B <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Medium Speed">i</button></th>
                                      <th>Class C <button type="button" class=" custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Basic Speed">i</button></th>
                                    </tr>

                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Breezeline</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Brightspeed</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>CenturyLink</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cox</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum/Altice</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Portal</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW!</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50 </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform P - Weekly Funded - 90 Days Chargeback Period 
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>EarthLink Affiliate Order Entry Portal (Standard & WHI)</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">HyperLink Internet below 100M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$174.30  </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>HyperLink Internet 100M+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$274.30  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Wireless Home Internet (WHI) 50 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$49.30   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 GB at 5</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$79.30  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$79.30  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>$100 GB </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$74.30   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$99.30   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>200 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$149.30  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>300 GB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$199.30   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>- No Credit Check <br/>
                        -   90 Day Chargeback Program (Unless DID Method)"</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Optimum</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>GIG INTERNET</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$227.50    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500 MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$192.50    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>All other Speeds </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$122.50   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Optimum Advantage Internet (OAI) *</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$101.50  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$101.50   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mobility</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$21.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Viasat</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Sales Commission</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$140.00  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Windstream</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Fiber at 1GB and greater</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$210.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Fiber (speed btw 500M & 1GB)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$175.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Internet (speed under 500M)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$122.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic secure</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Frontier</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                    
                                  
                                </td>
                              </tr>
                             
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$420.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$350.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$297.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$157.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Fiber Internet Under 500M </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Digital Voice & Unlimited Voice </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.50      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 5 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 1 Gig </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade 500M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Broadband Copper to Fiber Upgrade  500M</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>All HSI Internet </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.00     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Whole Home Wi-Fi</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$31.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier Secure </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.00    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                               {/* brigevine */}
                               <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform B - Monthly Funded - 90 Days Chargeback Period 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>COX</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                             
                                  
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">Cable Internet Go Fast 100 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$48.30   </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Faster 250 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$57.40   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Even Faster 500 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$53.20    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Super Fast 1  Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.70   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Internet Go Beyond Fast 2 Gig</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$93.10   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Starter</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$25.20   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Preferred</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$44.10   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Preferred Plus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$53.20   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Cable Contour TV Ultimate</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$62.30    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>VOIP Voice Preferred</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$9.10    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                     <tr>
                                      <th>more carriers</th>
                                      </tr>
                                    

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.50     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>WOW all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$38.50     </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>TDS all speeds</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$21.00   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                               {/* Platform R - Monthly Funded -  90 Days Chargeback Period */}
                              
                               <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform R - Monthly Funded -  90 Days Chargeback Period
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T - NDS</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">AT&T INTERNET 1Gig+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$308.00     </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 500Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$252.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 300Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$252.00  </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 100Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$252.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 50-75Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$168.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                    <td>AT&amp;T INTERNET &lt; 50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$84.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            
                          
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Wireless </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Gross Add  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$182.0    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Next Up </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.0    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Extra </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.0   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Elite/Premium  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$19.6    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Tablet / Wearable Activation </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$28.0  </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Mobile Insurance </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.6   </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Referrals AARP </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$28.0    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Auto Bill Pay  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.6</td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                               {/* Platform D - Weekly Funded -  90/180 Days Chargeback Period  */}
                          
                              
                               <tr>
                                <td colSpan={2} className="theme-bg-blue">
                                Platform D - Weekly Funded -  90/180 Days Chargeback Period 

                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T - NDS</th>
                                    </tr>

                                   
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="a">AT&T INTERNET 1Gig+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td class="w-30">$350.00   </td>
                                      <td class="w-30"></td>
                                      <td class="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 500Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$280.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 300Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$280.00     </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 100Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T INTERNET 50-75Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.00    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr> 

                                    <td>AT&T INTERNET &lt; 50 Mbps</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.50    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            
                          
                             
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Copper to Fiber Migrations</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 1GIG+  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.0     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 500 Mbps  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.0   </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>AT&T Internet 300 &lt; Mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.5    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              <tr>

                              <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>AT&T Wireless </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                             
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Gross Add  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.0     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Next Up  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.0    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Extra </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.0    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Installment Plan Unlimited Elite   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$21.0    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Auto Bill Pay  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.5    </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="text-center">90 Day Chargeback Period	</td>
                              </tr> */}

                              {/* centurtylink */}
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>CenturyLink/Lumen</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>900MBPS+ </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$210.0       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-899MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$192.5       </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10-99MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.5      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Less than 10MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.0       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Local Wireline  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">    </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Basic Service </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$21.0     </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Residential Service Package   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$56.0      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}

                              {/* frontier communication */}
{/*                               
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Frontier Communications</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5GIG</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$333.20       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2GIG  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$280.70        </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1GIG </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$228.20       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$140.70      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Fiber under 500MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.70      </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DSL </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.70      </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Copper to Fiber Upgrade  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$91.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Digital Voice  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$112.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Frontier Secure  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}
                              
                              {/* windstreamkinetics */}
                               
                              
                               <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Windstream/Kinetic</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>&gt; 100MPBS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.50       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-499MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$112.00       </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500-999MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$126.00        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            

                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1GIG+</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$143.50        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                             
                              
                            {/* bundlepackage */}
                       
{/*                                
                              
                            // <tr>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <th>Bundle Packages</th>
                            //         </tr>

                                    
                            //       </table>
                            //     </td>
                            //     <td className="p-0">
                               
                                
                                
                                    
                                  
                            //     </td>
                            //   </tr>
                            //   <tr>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td>Bundle Voice with HIS</td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td className="w-30">$126.00        </td>
                            //           <td className="w-30"> </td>
                            //           <td className="w-30"></td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //   </tr>
                            //   <tr>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td>Bundle 100-499MBPS  </td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td className="w-30">$150.50         </td>
                            //           <td className="w-30"></td>
                            //           <td className="w-30"></td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //   </tr>
                            //   <tr>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td>Bundle 500-999MBPS </td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td className="w-30">$164.50        </td>
                            //           <td className="w-30"> </td>
                            //           <td className="w-30"></td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //   </tr>
                            

                              
                            //   <tr>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td>Bundle 1GIG+</td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //     <td className="p-0">
                            //       <table className="w-100">
                            //         <tr>
                            //           <td className="w-30">$178.50       </td>
                            //           <td className="w-30"> </td>
                            //           <td className="w-30"></td>
                            //         </tr>
                            //       </table>
                            //     </td>
                            //   </tr> */}
                             
                              
                              {/* kinetic secure */}
                             
                       
                               
                              
                            {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Kinetic Secure</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Secure </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.50        </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Complete  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$5.60         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Kinetic Total </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$7.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="text-center">No Chargeback Period	</td>
                              </tr> */}

                              {/* earthlink */}
                             
  
                              
                            {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Earthlink &lt; 100MBPS</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Speeds up to 99mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$126.00       </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Speeds 100+mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$140.00         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>12 AT&T Markets - 100mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$175.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>12 AT&T Markets - 300mbps </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$245.00          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* Earthlink wireless internet */}
 
                            {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Earthlink Wireless Internet</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$112.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$84.00         </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$56.00          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>50 Gigabyte Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$35.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* brightspeed */}
                             
 
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Brightspeed</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                              
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>900MBPS+ </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$210.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100-899MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$192.50        </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10-99MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.50         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Less than 10MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Local Wireline </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Basic Service </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$21.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Residential Service Package </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$56.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrades </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Double Play </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$28.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* altafiber */}

                            {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Altafiber - Cincinnati Bell</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                             
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>750MB - 1GB  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$94.50          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100MB - 500MB </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$80.50           </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>10MB - 50MB </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$59.50           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>DSL &lt; 10MB</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$42.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Phone  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$17.50           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}

                              {/* wow! */}
{/* 
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>WOW!</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1200MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.00          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1000MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.00           </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>500MBPS </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$87.50          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>200MBPS</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>100MBPS  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$42.00          </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Phone   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$22.40             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Whole Home Wifi  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Unlimited Data </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                              {/* hugesnet */}
                              
{/* 
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>HughesNet</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Commission  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$84.00         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Bonus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.00            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>MDF </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$49.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                              {/* directtv */}

{/*                           
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>DIRECTV</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Low  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$150.50         </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Medium</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$52.50             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>High/Unknown </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$3.50            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Protection Plan </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.50              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Sports </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.50             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Choice and Above </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$24.50             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrade </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                               */}
                             
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                                 {/* installation */}
{/*                           
                            <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th>Installations</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>1 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$105.00           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>2 Room</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$122.50              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>3 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$140.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>4 Room</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$157.50               </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>5 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$175.00              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>6 Room </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$192.50           </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Upgrade Install </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$70.00             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                                 
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Service Call Fee </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">($70.00)             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              
                              
                              */}
                              {/* <tr>
                                <td className="text-center">No  Chargeback Period	</td>
                              </tr> */}
                                  {/* hugesnet */}

                                  {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">HughesNet - Monthly Funded -  No Chargeback Period </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Commission</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$84.00              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Activation Bonus</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$14.00            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>MDF</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$49.00              </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>RESIDUAL (up to 40 months)</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}

                              {/* quarterly bonus */}
                               {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">QUARTERLY BONUS</th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>300 plus sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$49.00            </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>150 to 299 sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$24.50               </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>75 to 149 sales </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$10.50 </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>less than 75</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $7.00            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}

                              
                                {/* spectrum */}
                                {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">Spectrum Residential - Weekly Funded -  90 Days Chargeback Period  </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                               
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum Gig or above  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$154.00              </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum below Gig </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$98.00               </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>7Mobility Line </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">$56.00                </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus 1000 plus activations  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $14.00            </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus  500 to 999 activations </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $8.40               </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Volume Bonus 240 to 499 activations  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> $5.20             </td>
                                      <td className="w-30"></td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* lastspectrum */}
                                {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <th className="theme-bg-blue">Spectrum Business - Monthly Funded - No Chargeback Period   </th>
                                    </tr>

                                    
                                  </table>
                                </td>
                                <td className="p-0">
                                
                                
                                
                                    
                                  
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>Spectrum all speeds   </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30"> 3x MRC              </td>
                                    
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td>RESIDUAL  </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="p-0">
                                  <table className="w-100">
                                    <tr>
                                      <td className="w-30">             </td>
                                      <td className="w-30"> </td>
                                      <td className="w-30"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr> */}
                            

                             
                            
                            

                              
                             
                              
                            </>
                          )}
                        

                          

                          {/* for pink box */}
                         
                          {/* end */}
                          {/* <tr>
                            <th>Cable Internet</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Fiber Internet</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Satellite Internet</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Home Phone</th>
                            <td>$5</td>
                          </tr>
                          <tr>
                            <th>Wireless Phone Service</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Cable TV</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Satellite TV</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Home Security</th>
                            <td>$150</td>
                          </tr>
                          <tr>
                            <th>Energy</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Solar</th>
                            <td>$200</td>
                          </tr> */}
                        </tbody>
                      </Table>
                      <div className="row logo-wraper-row">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkatat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkAtd}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.alderlogo}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkfiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.breezeline}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.brightSpeed}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.cox}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.consolidatedCommunications}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.dish}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.directTv}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.earthLink}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontier}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontpoint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.googleFiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.hughesNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.metroNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.optimum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.reliant}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.spectrum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.homeInternet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.verizon}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.viasat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.vivint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.windStream}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.wow}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.xfinity}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>{" "}
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.youtubeTv}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.ziplyFiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinktv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Package - Weekly Funded - 30 Days Chargeback Period </th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Entertainment </th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '$25' : '$50'}</td>
                          </tr>
                          {/* <tr>
                            <th>Elite TV</th>
                            <td>$7</td>
                          </tr> */}
                          {/* <tr>
                            <th>Ultimate TV</th>
                            <td>$10</td>
                          </tr> */}
                          {/* <tr>
                            <th>Charge Back Window</th>
                            <td>30 Days</td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
               
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkinternet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Package - Weekly Funded - 30 Days Chargeback</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                          <tr>
                            <th>Blue Essentials</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Blue Elite</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Blue Ultimate</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                         
                          <tr>
                            <th>Green Essentials</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Green Elite</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Green Ultimate</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Starter</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Essentials</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Elite</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Ultimate</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          {/* <tr>
                            <th>Charge Back Window</th>
                            <td>No Charge Backs</td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                 
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkmobile}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Package - Weekly Funded - 30 Days Chargeback Period</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Blue Starter</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Blue starter plus</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Blue Essentials</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Blue Elite</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Blue Ultimate</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Green Starter</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Green Essentials</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Green Elite</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Green Ultimate</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Starter</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Essentials</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Elite</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Red Ultimate</th>
                            <td>{data.programName=='Comlink affiliate referral partner' ? '25% of Package Price' : '50% of Package Price'}</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>No Charge Backs</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  {data.programName=='Comlink affiliate referral partner' && (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p class="green-clr "><b>Leads</b></p>
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Leads</th>
                            <th>Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Residential Verified Lead</th>
                            <td>0.50 cents per lead</td>
                          </tr>
                          <tr>
                            <th>Business Lead Verified Lead</th>
                            <td>0.75 cents per lead</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>No Charge backs</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  
                  )}
                  
               

                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p>Business Carriers</p>
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>DSL Internet</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Cable Internet</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Fiber Internet</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>Satellite Internet</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Business Phone</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Wireless Phone Service</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Cable TV</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Satellite TV</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Business Security</th>
                            <td>$150</td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="row logo-wraper-row">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkatat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkAtd}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.alderlogo}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkfiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.breezeline}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.brightSpeed}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.cox}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.dish}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.directTv}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.earthLink}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontier}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontpoint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.googleFiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.hughesNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.metroNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.optimum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.spectrum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.homeInternet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.verizon}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.viasat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.vivint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.windStream}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.wow}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.xfinity}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinktv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>COMLINK TV PACKAGES</th>
                            <th>Option 1 Residual (life of the customer)</th>
                            <th>Option 2 One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials TV</th>
                            <td>$10</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Elite TV</th>
                            <td>$12</td>
                            <td>$70</td>
                          </tr>
                          <tr>
                            <th>Ultimate TV</th>
                            <td>$12</td>
                            <td>$70</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>30 Days</td>
                            <td>180 Days</td>
                          </tr>
                          <tr>
                            <th>Equipment</th>
                            <td>One-Time Payment</td>
                            <td>One-Time Payment</td>
                          </tr>
                          <tr>
                            <th>Android Stick</th>
                            <td>$20</td>
                            <td>30 Days</td>
                          </tr>
                          <tr>
                            <th>Set Top Box</th>
                            <td>$20</td>
                            <td>30 Days</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkinternet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Comlink Wifi</th>
                            <th>Option 1 Residual (life of the customer)</th>
                            <th>Option 2 One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials starter 25GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Elite starter 50GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Essentials 300GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Essentials 300GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Elite 500GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>5G Wideband Unlimited</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Equipment</th>
                            <td>One-Time Payment</td>
                            <td>Charge back Window</td>
                          </tr>
                          <tr>
                            <th>LTE Router</th>
                            <td>100</td>
                            <td>30 days</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkmobile}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Comlink Mobility</th>
                            <th>Option 1 Residual (life of the customer)</th>
                            <th>Option 2 One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials Plan per line</th>
                            <td>$5</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Elite Plan per line</th>
                            <td>$5</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>ACP TABLET PLAN</th>
                            <td>$8</td>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>ACP SIM OR PHONE PLAN</th>
                            <td>$7</td>
                            <td>$15</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>30 days</td>
                            <td>180 days</td>
                          </tr>
                          <tr>
                            <th>Equipment</th>
                            <td>One-Time Payment</td>
                            <td>Charge back Window</td>
                          </tr>
                          <tr>
                            <th>IPhone Sale</th>
                            <td>$100</td>
                            <td>30 days</td>
                          </tr>
                          <tr>
                            <th>Andriod Sale</th>
                            <td>$100</td>
                            <td>30 days</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkAtd}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Home Security (Owners)</th>
                            <td>$240.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.alderlogo}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Home Security (Renters)</th>
                            <td>$150.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkatat}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>ATT Internet Service</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>AT&T Internet 1 Gig+</th>
                            <td>$250.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 500Mbps+</th>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 300Mbps+</th>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 100Mbps+ </th>
                            <td>$75.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 50-75Mbps</th>
                            <td> $75.00 </td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 50Mbps</th>
                            <td> $60.00</td>
                          </tr>
                          <tr>
                            <th>ATT Fiber Upgrade Internet</th>
                            <td>$75.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 1GIG +</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 500 mbps</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 30</th>
                            <td>$35.00 </td>
                          </tr>
                          <tr>
                            <th>ATT Wireless</th>
                            <td> </td>
                          </tr>
                          <tr>
                            <th>Installment Plan Gross Add</th>
                            <td>$100.00 per line </td>
                          </tr>
                          <tr>
                            <th>Installment Plan Next Up</th>
                            <td>$10.00 per line </td>
                          </tr>
                          <tr>
                            <th>Installment Plan Unlimited Extra</th>
                            <td>$10.00 per line</td>
                          </tr>
                          <tr>
                            <th>Installment Plan Unlimited Elite </th>
                            <td>$10.00 per line</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkfiber}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>750MB - 1GB</th>
                            <td>$68</td>
                          </tr>
                          <tr>
                            <th>100MB - 500MB</th>
                            <td>$58</td>
                          </tr>
                          <tr>
                            <th>10MB - 50MB</th>
                            <td>$42</td>
                          </tr>
                          <tr>
                            <th>DSL 10MB</th>
                            <td>$30</td>
                          </tr>
                          <tr>
                            <th>Phone </th>
                            <td>$$12</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.breezeline}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>TV</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>$20</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.brightSpeed}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Brightspeed Residential</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>High Speed Internet</th>
                            <td>20 Mbps or Higher</td>
                            <td>$80</td>
                          </tr>
                          <tr>
                            <th>High Speed Internet</th>
                            <td>19 Mbps or lower</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Voice</th>
                            <td>Home Phone</td>
                            <td>$20</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.consolidatedCommunications}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>2GIG</th>
                            <td>$225.00</td>
                          </tr>
                          <tr>
                            <th>1GIG</th>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <th>250MBPS</th>
                            <td>$88.00</td>
                          </tr>
                          <tr>
                            <th>50MBPS</th>
                            <td>$63.00</td>
                          </tr>
                          <tr>
                            <th>Voice</th>
                            <td>$12.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img src={Images.cox} className="img-fluid" alt="img" />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>COX RESIDENTIAL</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Voice</th>
                            <th>Voip Line</th>
                            <td>$20.00</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <th>Cable</th>
                            <td>$40.00</td>
                          </tr>
                          <tr>
                            <th>High Speed Internet</th>
                            <th>26 Mbps or Higher</th>
                            <td>$80.00</td>
                          </tr>
                          <tr>
                            <th>High Speed Internet</th>
                            <th>25 Mbps or Lower</th>
                            <td>$20.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.directTv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>DIRECTV STREAM</th>
                            <td>$150</td>
                          </tr>
                          <tr>
                            <th>DIRECTV SATELLITE (MEDIUM CREDIT CLASS)</th>
                            <td>$85</td>
                          </tr>
                          <tr>
                            <th>DIRECTV SATELLITE (LOW CREDIT CLASS)</th>
                            <td>$200</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.dish}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>TV</th>
                            <td>$140</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.earthLink}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Earthlink {"<"} 100MBPS</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Speeds up to 99mbps</th>
                            <td>$90</td>
                          </tr>
                          <tr>
                            <th>Speeds 100+mbps</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>12 AT&T Markets - 100mbps</th>
                            <td>$125</td>
                          </tr>
                          <tr>
                            <th>12 AT&T Markets - 300mbps</th>
                            <td>$175</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Earthlink Wireless Internet</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>150 Gigabyte Plan</th>
                            <td>$80</td>
                          </tr>
                          <tr>
                            <th>100 Gigabyte Plan</th>
                            <td>$60</td>
                          </tr>
                          <tr>
                            <th>75 Gigabyte Plan</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>50 Gigabyte Plan</th>
                            <td>$25</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.frontpoint}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Plans</th>
                            <th>Dealer One-time Compensation (NEW SERVICE)</th>
                            <th>
                              Dealer One-time Compensation (Transfer of service)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Home Security</th>
                            <td>$300</td>
                            <td>$200</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="15">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.frontier}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>ALL FIBER SALES</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>ALL DSL SALES</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>COPPER TO FIBER UPGRADES</th>
                            <td>$75</td>
                          </tr>
                          <tr>
                            <th>VOICE</th>
                            <td>$20</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="16">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.googleFiber}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>ALL FIBER SALES</th>
                            <td>$72</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="17">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.hughesNet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <td>$90</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="18">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.metroNet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>MetroNet Residential</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>1GB</th>
                            <td>$133</td>
                          </tr>
                          <tr>
                            <th>500 MB</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>200 MB</th>
                            <td>$80</td>
                          </tr>
                          <tr>
                            <th>100 MB</th>
                            <td>$60</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Whole Home Wi-Fi</th>
                            <td>$10</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>MetroNet Student</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>100 MB</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>500 MB</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>1GB</th>
                            <td>$63</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>Whole Home Wi-Fi</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>MetroNet Residential</th>
                            <td>Dealer Comp</td>
                          </tr>
                          <tr>
                            <th>1GB</th>
                            <td>$265</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="19">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.optimum}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>
                              Compensation to Distributor (One-time payment)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet Tier 1 or 2</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>Internet Tier 3 or 4</th>
                            <td>$70.00</td>
                          </tr>
                          <tr>
                            <th>Voice</th>
                            <td>$20.00</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <td>$20.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="20">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.reliant}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>NRG/Reliant</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Tier 1</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Tier 2</th>
                            <td>$88</td>
                          </tr>
                          <tr>
                            <th>Tier 3</th>
                            <td>$113</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="21">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.spectrum}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Spectrum Service</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Spectrum Internet 1GIG</th>
                            <td>$70.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum Internet Ultra</th>
                            <td>$60.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum Internet</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum TV - Select / Latino</th>
                            <td>$25.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum TV - Choice</th>
                            <td>$12.50</td>
                          </tr>
                          <tr>
                            <th>Spectrum Phone</th>
                            <td>$20.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum Mobility</th>
                            <td>$75.00 per line</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="22">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.homeInternet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Product TYPE</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <th>5G/4G</th>
                            <td>$40</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="23">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.verizon}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Product TYPE</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <th>FIOS</th>
                            <td>$95</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>5G</th>
                            <td>$84</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>DSL</th>
                            <td>$32</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="24">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.viasat}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Product TYPE</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>High Speed Internet</th>
                            <th>Full Beam</th>
                            <td>$125</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>Other </th>
                            <td>$95</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="25">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.vivint}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Vivint SmartHome</th>
                            <th>Dealer Compensation</th>
                            <th>
                              Volume Tiers Retro to 1st Activation Monthly
                            </th>
                            <th>Bonus Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>$599 Starter Kit @ $29.99/mo</th>
                            <th>$75</th>
                            <th>10 installs/mo</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $39.99/mo</th>
                            <th>$212</th>
                            <th>75 Installs/mo</th>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $44.99/mo</th>
                            <th>$238</th>
                            <th>100 Installs/mo</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $49.99/mo</th>
                            <th>$263</th>
                            <th>125 Installs/mo</th>
                            <td>$75</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $54.99/mo</th>
                            <th>$275</th>
                            <th>150 Installs/mo</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $59.99/mo</th>
                            <th>$288</th>
                            <th>175 Installs/mo</th>
                            <td>$125</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $64.99/mo</th>
                            <th>$313</th>
                            <th>200 Installs/mo</th>
                            <td>$150</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $69.99/mo</th>
                            <th>$338</th>
                            <th>250 Installs/mo</th>
                            <td>$175</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $19.99/mo</th>
                            <th>$240</th>
                            <th>300 Installs/mo</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $24.99/mo</th>
                            <th>$265</th>
                            <th>350 Installs/mo</th>
                            <td>$225</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $29.99/mo</th>
                            <th>$290</th>
                            <th>400 Installs/mo</th>
                            <td>$250</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $34.99/mo</th>
                            <th>$302</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $39.99/mo</th>
                            <th>$315</th>
                            <th>-</th>
                            <td>- </td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $44.99/mo</th>
                            <th>$340</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $49.99/mo</th>
                            <th>$365</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="26">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.windStream}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Windstream/Kinetic (Saraplus OES)</th>
                            <th>Dealer Compensation</th>
                            <th>Windstream/Kinetic (Concert OES)</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>{">"}100MPBS</th>
                            <th>$63</th>
                            <th>Internet</th>
                            <td>$30</td>
                          </tr>
                          <tr>
                            <th>100-499MBPS</th>
                            <th>$80</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>500-999MBPS</th>
                            <th>$90</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>1GIG+</th>
                            <th>$103</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle Packages</th>
                            <th>Dealer Comp</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle Voice with HIS</th>
                            <th>$90</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle 100-499MBPS</th>
                            <th>$107</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle 500-999MBPS</th>
                            <th>$118</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle 1GIG+</th>
                            <th>$128</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Secure</th>
                            <th>Dealer Comp</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Secure</th>
                            <th>$3</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Complete</th>
                            <th>$4</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Total</th>
                            <th>$5</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="27">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img src={Images.wow} className="img-fluid" alt="img" />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>WOW! (Saraplus OES)</th>
                            <th>Dealer Compensation</th>
                            <th>WOW! (Concert OES)</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>1200MBPS</th>
                            <th>$75</th>
                            <th>INTERNET</th>
                            <td>$30</td>
                          </tr>
                          <tr>
                            <th>1000MBPS</th>
                            <th>$75</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>500MBPS</th>
                            <th>$53</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>200MBPS</th>
                            <th>$50</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>100MBPS</th>
                            <th>$30</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <th>$16</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Whole Home Wifi</th>
                            <th>$20</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Unlimited Data</th>
                            <th>$10</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="28">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.xfinity}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Speed</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <th>300 Mbps or Higher</th>
                            <td>$48</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>100 Mbps or Higher</th>
                            <td>$24</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>25 Mbps or Higher</th>
                            <td>$16</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <th>Ultimate</th>
                            <td>$72</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <th>Other</th>
                            <td>$40</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="29">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.youtubeTv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Speed</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>TV</th>
                            <th>Stream</th>
                            <td>$40</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="30">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.ziplyFiber}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Ziply Fiber</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>5GIG</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>2GIG</th>
                            <td>$163</td>
                          </tr>
                          <tr>
                            <th>1GIG</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>200/200</th>
                            <td>$70</td>
                          </tr>
                          <tr>
                            <th>50/50</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>DSL</th>
                            <td>$50</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Compensate;
