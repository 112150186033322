import React from "react";
import ceo from "../../../../assets/images/Matt-new.png";
import commas from "../../../../assets/images/inverted-commas.png";
import "./ceomessage.css";

function CeoMessage() {
  return (
    <div>
      <div className="ceo-section">
        <div className="custom-container">
          <div className="ceo-message-wraper">
            <div className="ceo-founder">
              <img src={ceo} alt="founder" />
            </div>
            <div className="founder-details">
              <img src={commas} alt="commas" className="img-fluid" />
              <p>
                With over 25 years of telecom experience Matt founded Comlink
                Total Solutions in 2015 with the goal of providing customers and
                partners a robust menu of telecom options. Comlink’s mission is
                to provide customers and partners the best telecom technology
                with the guaranteed lowest prices.
              </p>
              <p className="ceo">CEO/Founder</p>
              <h3>Matthew Santini</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CeoMessage;
