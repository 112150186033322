import React, { useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
  w9Fullname: "",
  w9BusinessName: "",
  tin: "",
  ssn: "",
  ein: "",
  address: "",
  apt: "",
  city: "",
  state: "",
  zipcode: "",
  federalTaxClassification: "",
};

const validationSchema = Yup.object().shape({
  w9Fullname: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z\s]+$/, "Fullname  must contain only letters and spaces"),
  w9BusinessName: Yup.string().nullable(),
  tin: Yup.string().required(
    "Please enter your Taxpayer Identification Number (TIN)"
  ),
  federalTaxClassification: Yup.string().required(
    "Please select a Federal Tax Classification"
  ),

  ssn: Yup.string().test(
    'ssn-required',
    'Social Security Number (SSN) is required',
    function (value) {
      const { federalTaxClassification } = this.parent;
      if (federalTaxClassification === 'Individual/sole proprietor') {
        return value ? true : false;
      }
      return true;
    }
  ),
  tin: Yup.string().test(
    'tin-required',
    'Please enter your Taxpayer Identification Number (TIN)',
    function (value) {
      const { federalTaxClassification } = this.parent;
      if (tinRequired.includes(federalTaxClassification)) {
        return value ? true : false;
      }
      return true;
    }
  ),
  // tin: Yup.string().when('federalTaxClassification', {
  //   is: 'Single Member LLC',
  //   then: Yup.string().required('Please enter your Taxpayer Identification Number (TIN)'),
  //   otherwise: Yup.string().nullable(),
  // }),
  // ssn: Yup.string()
  //   .matches(
  //     /^(?!000)(?!666)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/,
  //     "Invalid SSN format"
  //   ),
  //   .nullable(),
  // ein: Yup.string()
  //   .matches(/^(?!00)[0-9]{2}[- ]?(?!0000000)[0-9]{7}$/, "Invalid EIN format")
  //   .nullable(),
  // ssn: Yup.string().when(["federalTaxClassification"], {
  //   is: (value) => ["Limited liability company", "Other"].includes(value),
  //   then: Yup.string()
  //     .matches(
  //       /^(?!000)(?!666)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/,
  //       "Invalid SSN format"
  //     )
  //     .required("SSN is required"),
  //   otherwise: Yup.string().nullable(),
  // }),
  // ein: Yup.string().when(["federalTaxClassification"], {
  //   is: (value) => !["Limited liability company", "Other"].includes(value),
  //   then: Yup.string()
  //     .matches(/^(\d{2}-\d{7}|\d{9})$/, "Invalid EIN format")
  //     .required("EIN is required"),
  //   otherwise: Yup.string().nullable(),
  // }),
  address: Yup.string().required("Please enter your address"),
  // apt: Yup.string().required("Please enter your apt"),
  city: Yup.string()
    .required("City is required")
    .matches(/^[A-Za-z\s]+$/, "City must contain only letters and spaces")
    .nullable(),
  state: Yup.string()
    .required("State is required")
    .matches(/^[A-Za-z\s]+$/, "State must contain only letters and spaces")
    .nullable(),
  zipcode: Yup.string()
    .required("Zip code is required")
    .length(5, "Zip code must be exactly 5 characters")
    .matches(/^[0-9]+$/, "Zipcode must contain only numbers"),
});

const USStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const tinRequired=[
  "Single Member LLC",
  "C Corporation",
  "S Corporation",
  "Partnership",
  "Trust/estate",
  "Limited liability company",
  "Other"
]
const FourthForm = (props) => {
  const startRef = useRef(null);

  // useEffect(() => {
  //   scrollToSection(startRef);
  // }, [props.flag]);  

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      props?.setData({
        ...props?.data,
        ...values,
      });
      props.setStep(5);
      props.nextStep();
    },
  });

  return (
    <div className="Qoute__content" ref={startRef}>
      <div className="custom-container">
        <div className="contact-headings text-center">
          <h4 className="theme-clr mb-lg-3 mb-2">Requirements for W9</h4>
          {/* <p>
            Leave your information below, one of our representatives will get in
            touch with you.
          </p>  */}
        </div>
        <div className="bg-qoute" >
        <div className="qoute__form" >
          <div className="row">
            {/* <div className="col-xl-3">
              <div className="form_image">
                <img src={QouteForm} alt="QouteForm" />
              </div>
            </div> */}
            <div className="col-xl-12">
              <div className="form_content step4-form-content">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-lg-3 mb-2">
                        <label htmlFor="w9Fullname">
                          Name(as shown on your income tax return)
                          <span className="red_staric">*</span>
                        </label>
                        <input
                          id="w9Fullname"
                          name="w9Fullname"
                          type="text"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.w9Fullname}
                          className={`form-control ${
                            formik.errors.w9Fullname &&
                            formik.touched.w9Fullname
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.w9Fullname &&
                          formik.touched.w9Fullname && (
                            <div className="red_staric">
                              {formik.errors.w9Fullname}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-lg-3 mb-2">
                        <label htmlFor="w9BusinessName">Business Name</label>
                        <input
                          id="w9BusinessName"
                          name="w9BusinessName"
                          type="text"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.w9BusinessName}
                          className={`form-control ${
                            formik.errors.w9BusinessName &&
                            formik.touched.w9BusinessName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.w9BusinessName &&
                          formik.touched.w9BusinessName && (
                            <div className="red_staric">
                              {formik.errors.w9BusinessName}
                            </div>
                          )}
                      </div>
                    </div>
                    <p className="mb-lg-3 mb-2 small-heading">
                      Check appropriate box for federal tax classification of
                      the person whose name is entered on line 1.
                    </p>
                    <div className="col-xl-3 col-md-6">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="Individual/sole proprietor"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification ===
                            "Individual/sole proprietor"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">
                              Individual/sole proprietor
                            </label>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="Single Member LLC"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification ===
                            "Single Member LLC"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">Single Member LLC</label>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="C Corporation"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification ===
                            "C Corporation"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">C Corporation</label>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="S Corporation"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification ===
                            "S Corporation"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">S Corporation</label>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="Partnership"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification ===
                            "Partnership"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">Partnership</label>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="Trust/estate"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification ===
                            "Trust/estate"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">Trust/estate</label>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="Limited liability company"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification ===
                            "Limited liability company"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">
                              Limited liability company
                            </label>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="federalTaxClassification"
                          className="overflow-control-input"
                          type="radio"
                          value="Other"
                          onChange={formik.handleChange}
                          checked={
                            formik.values.federalTaxClassification === "Other"
                          }
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">Other</label>
                          </div>
                        </span>
                      </label>
                    </div>
                    {formik.errors.federalTaxClassification &&
                      formik.touched.federalTaxClassification && (
                        <div className="red_staric">
                          {formik.errors.federalTaxClassification}
                        </div>
                      )}
                      {formik.values.federalTaxClassification !=
                      "Individual/sole proprietor" && (
                    <div className="col-xl-12 col-lg-12">
                      <div className="form-group mb-lg-3 mb-2">
                        <label htmlFor="tin">
                          Taxpayer Identification Number (TIN) OR Employer Identification Number (EIN)
                          <span className="red_staric">*</span>
                        </label>
                        <input
                          id="tin"
                          name="tin"
                          type="text"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.tin}
                          className={`form-control ${
                            formik.errors.tin && formik.touched.tin
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.tin && formik.touched.tin && (
                          <div className="red_staric">{formik.errors.tin}</div>
                        )}
                      </div>
                    </div>)}
                    {formik.values.federalTaxClassification ==
                      "Individual/sole proprietor" && (
                      <div className="col-xl-12 col-lg-12">
                        <div className="form-group mb-lg-3 mb-2">
                          <label htmlFor="ssn">
                            Social Security Number (SSN)
                            <span className="red_staric">*</span>
                          </label>
                          <input
                            id="ssn"
                            name="ssn"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ssn}
                            className={`form-control ${
                              formik.errors.ssn && formik.touched.ssn
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.ssn && formik.touched.ssn && (
                            <div className="red_staric">
                              {formik.errors.ssn}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* <div className="col-xl-12 col-lg-12">
                      <div className="form-group mb-lg-3 mb-2">
                        <label htmlFor="ein">
                          Employer Identification Number (EIN)
                          <span className="red_staric">*</span>
                        </label>
                        <input
                          id="ein"
                          name="ein"
                          type="text"
                          placeholder="Enter your Employer Identification Number (EIN)"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ein}
                          className={`form-control ${
                            formik.errors.ein && formik.touched.ein
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.ein && formik.touched.ein && (
                          <div className="red_staric">{formik.errors.ein}</div>
                        )}
                      </div>
                    </div> */}
                    {/* {formik.values.federalTaxClassification &&
                    (formik.values.federalTaxClassification ===
                      "Individual/sole proprietor or single-member LLC" ||
                      formik.values.federalTaxClassification === "Other") ? (
                      <div className="col-xl-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="ssn">
                            Social security number (SSN)
                            <span className="red_staric">*</span>
                          </label>
                          <ReactInputMask
                            id="ssn"
                            name="ssn"
                            type="text"
                            mask="999-99-9999"
                            placeholder="Enter your SSN e.g. 999-99-9999"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ssn}
                            className={`form-control ${
                              formik.errors.ssn && formik.touched.ssn
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.ssn && formik.touched.ssn && (
                            <div className="red_staric">
                              {formik.errors.ssn}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {formik.values.federalTaxClassification &&
                    formik.values.federalTaxClassification !==
                      "Individual/sole proprietor or single-member LLC" &&
                    formik.values.federalTaxClassification !== "Other" ? (
                      <div className="col-xl-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="ein">
                            Employer identification number (EIN)
                            <span className="red_staric">*</span>
                          </label>
                          <ReactInputMask
                            id="ein"
                            name="ein"
                            type="text"
                            mask="99-9999999"
                            placeholder="Enter your EIN"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ein}
                            className={`form-control ${
                              formik.errors.ein && formik.touched.ein
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.ein && formik.touched.ein && (
                            <div className="red_staric">
                              {formik.errors.ein}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null} */}

                    <div className="col-xl-12">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="address">
                          Street Address
                          <span className="red_staric">*</span>
                        </label>
                        <input
                          id="address"
                          name="address"
                          type="text"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                          className={`form-control ${
                            formik.errors.address && formik.touched.address
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.address && formik.touched.address && (
                          <div className="red_staric">
                            {formik.errors.address}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="apt">
                        APT/Suite/Unit
                          {/* <span className="red_staric">*</span> */}
                        </label>
                        <input
                          id="apt"
                          name="apt"
                          type="text"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.apt}
                          className={`form-control ${
                            formik.errors.apt && formik.touched.apt
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.apt && formik.touched.apt && (
                          <div className="red_staric">
                            {formik.errors.apt}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                      <div className="form-group mb-lg-3 mb-2">
                        <label htmlFor="city">
                          City<span className="red_staric">*</span>
                        </label>
                        <input
                          id="city"
                          name="city"
                          type="text"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          className={`form-control ${
                            formik.errors.city && formik.touched.city
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.city && formik.touched.city && (
                          <div className="red_staric">{formik.errors.city}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                      <div className="form-group mb-lg-3 mb-2">
                        <label htmlFor="state">
                          State<span className="red_staric">*</span>
                        </label>
                        <select
                          id="state"
                          name="state"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          className={`form-control ${
                            formik.errors.state && formik.touched.state
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option
                            value=""
                            selected
                            hidden
                            label="Select a state"
                          />
                          {USStates.map((state, index) => (
                            <option key={index} value={state} label={state} />
                          ))}
                        </select>
                        {formik.errors.state && formik.touched.state && (
                          <div className="red_staric">
                            {formik.errors.state}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                      <div className="form-group mb-lg-3 mb-2">
                        <label htmlFor="zipcode">
                          Zip/Postal Code<span className="red_staric">*</span>
                        </label>
                        <ReactInputMask
                          id="zipcode"
                          name="zipcode"
                          type="text"
                          mask="99999"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.zipcode}
                          className={`form-control ${
                            formik.errors.zipcode && formik.touched.zipcode
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.zipcode && formik.touched.zipcode && (
                          <div className="red_staric">
                            {formik.errors.zipcode}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3">
                    <button type="submit" className="btn theme-btn">
                      Next
                    </button>
                  </div> */}
                </form>
                <div className="d-flex  justify-content-between mt-xl-4 mt-3 mb-xl-4 mb-3">
                  <div
                    onClick={() => {
                      props.setStep(3);
                      props.previousStep();
                    }}
                    className="btn theme-btn flex-start"
                  >
                    Previous
                  </div>
                  <button
                    onClick={() => formik.handleSubmit()}
                    className="btn theme-btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default FourthForm;
