import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
const initialValues = {
  firstname: "",
  lastname: "",
  businessName: "",
  phone: "",
  email: "",
  typeOfCompany: "",
  hearAboutUs: "",
  programName: "",
  programDescription: "",
  companyWebsite: "",
  employee: "",
  marketingName: "",
  providerName: "",
  otherInformation:"",
  optIn: false,
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("First Name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "First name must contain only letters and spaces"
    ),
  lastname: Yup.string()
    .required("Last Name is required")
    .matches(/^[A-Za-z\s]+$/, "Last name must contain only letters and spaces"),
  // businessName: Yup.string().required("Business name is required"),
  programName: Yup.string().required("Referral type is required"),
  marketingName: Yup.array().nullable(),
  providerName: Yup.array().test(
    "provider-name-required",
    "This filed is required",
    function (value) {
      const { programName } = this.parent;
      if (
        programName == "Comlink Door-to-door/event sales partner" ||
        programName == "Comlink Call Center Partner"
      ) {
        return value ? true : false;
      }
      return true;
    }
  ),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Phone number must be exactly 10 digits")
    .length(14, "Phone number must be exactly 10 digits"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  typeOfCompany: Yup.string()
    // .required("Company name is required")
    // .matches(/^[A-Za-z\s]+$/, "State must contain only letters and spaces")
    .nullable(),
  programDescription: Yup.string().nullable(),
  employee: Yup.string().nullable(),
  hearAboutUs: Yup.string().required("This filed is required"),
  employee: Yup.string().test(
    "employee-required",
    "This filed is required",
    function (value) {
      const { hearAboutUs } = this.parent;
      if (hearAboutUs == "Comlink Employee") {
        return value ? true : false;
      }
      return true;
    }
  ),
  otherInformation: Yup.string().test(
    "other-information-required",
    "This filed is required",
    function (value) {
      const { hearAboutUs } = this.parent;
      if (hearAboutUs == "Other") {
        return value ? true : false;
      }
      return true;
    }
  ),
  companyWebsite: Yup.string()
    .nullable()
    .matches(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-]+)*\/?$/,
      "Invalid website format"
    ),
  optIn: Yup.boolean()
    .oneOf([true], "You must agree to the terms")
    .required("Agreement is required"),
});

const typeOfCompany = [
  "Moving Company",
  "Real Estate Agency",
  "Real Estate Agent",
  "Property Manager",
  "Affiliate Marketer",
  "Call Center",
  "Outside Sales Company",
  "Builder",
  "Telecom Provider/Reseller",
  "Other",
];

const hearAboutUs = [
  "Facebook",
  "LinkedIn",
  "Google",
  "Comlink Employee",
  "Referral",
  "Other",
];
const comlinkPartner = [
  "Comlink affiliate referral partner",
  "Comlink Call Center Partner",
  "Comlink Door-to-door/event sales partner",
];
const programDescription = {
  "Comlink affiliate referral partner":
    "(Comlink affiliate referral partner) is an individual or entity that promotes Comlink's products and services in exchange for a referral commission on sales generated through Comlink approved promotional efforts with performance-based marketing.",
  "Comlink Call Center Partner":
    "Comlink Call Center Partner: Our call center partners will provide sales support via customer interactions through inbound phone calls or approved outbound calls to customers who have opted in to be contacted and have been cross checked on Comlink's DNC list. No robo dialing is allowed in this program.",
  "Comlink Door-to-door/event sales partner":
    "Comlink Door-to-door/event sales partner: Sales representatives will visit homes or businesses to promote and sell Comlink products and services in Comlink approved territories. These partners can also attend Comlink approved events which can range from concerts, sports games, Home shows and theater performances to conferences, seminars, and local community gatherings. The primary goal is to engage potential customers face-to-face, provide information about Comlink products and services.",
};
const FirstForm = (props) => {
  // const [errors, setErrors] = useState({});

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   props?.setData({
  //     ...props?.data,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const newErrors = {};
  //   // Validate name
  //   if (!props?.data.fullname.trim()) {
  //     newErrors.fullname = "Name is required";
  //   }
  //   // Validate business
  //   if (!props?.data.business.trim()) {
  //     newErrors.business = "Business name is required";
  //   }

  //   // Validate phone
  //   if (!props?.data.phone.trim()) {
  //     newErrors.phone = "Phone is required";
  //   } else if (!/^\d{10}$/.test(props?.data.phone)) {
  //     newErrors.phone = "Phone number should be 10 digits";
  //   }

  //   // Validate email
  //   if (!props?.data.email.trim()) {
  //     newErrors.email = "Email is required";
  //   } else if (
  //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(props?.data.email)
  //   ) {
  //     newErrors.email = "Invalid email address";
  //   }

  //   // If there are errors, set them in state
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //   } else {
  //     // Form is valid, you can submit the data or perform other actions here
  //     setErrors({});
  //     props.setStep(2);
  //     props.nextStep();
  //     console.log("Form data:", props?.data);
  //   }
  // };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      props?.setData({
        ...props?.data,
        ...values,
      });
      props.setStep(2);
      props.nextStep();
    },
  });

  const onProgramChange = (formik, value) => {
    formik.setFieldValue("programName", value);
    formik.setFieldValue("programDescription", programDescription[value]);
  };
  const employeeList = [
    "Matt Santini",
    "Tyler Myers",
    "Gary Peacock",
    "Greg Santini",
  ];
  const providers = [
    { label: "All Providers", value: "All Providers" },
    { label: "At&t", value: "At&t" },
    { label: "Breezeline", value: "Breezeline" },
    { label: "Brightspeed", value: "Brightspeed" },
    { label: "Cox", value: "Cox" },
    { label: "Earthlink", value: "Earthlink" },
    { label: "Frontier", value: "Frontier" },
    { label: "HughesNet", value: "HughesNet" },
    { label: "Mediacom", value: "Mediacom" },
    { label: "Optimum", value: "Optimum" },
    { label: "Spectrum", value: "Spectrum" },
    { label: "Spectrum Business", value: "Spectrum Business" },
    { label: "Verizon", value: "Verizon" },
    { label: "Viasat", value: "Viasat" },
    { label: "Windstream", value: "Windstream" },
    { label: "WOW", value: "WOW" },
    { label: "Xfinity", value: "Xfinity" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // maxWidth: '400px',
      margin: "0 auto",
      padding: "8px",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none",
      // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      border: "1px ",
      color: "#888888",
      fontSize: "18px",
      fontWeight: "300",
      zIndex: 9,
      // boxShadow: !state.isFocused ? 'none' : '0 0 8px rgba(0, 0, 0, 0.3)'
    }),
  };
  const marketingName = [
    { value: "Marketing Program", label: "Marketing Program" },
    { value: "Lead Program", label: "Lead Program" },
  ];
  return (
    <div className="Qoute__content">
      <div className="custom-container">
        <div className="contact-headings text-center">
          <h4 className="theme-clr mb-lg-3 mb-2 text-capitalize">
            {formik.values.programName != ""
              ? formik.values.programName
              : "Comlink"}{" "}
            Program{formik.values.programName != "" ? "" : "s"}
          </h4>
          {/* <p>
            Leave your information below, one of our representatives will get in
            touch with you.
          </p> */}
        </div>
        <div className="bg-qoute">
          <div className="qoute__form">
            <div className="row">
              {/* <div className="col-xl-3"> 
              <div className="form_image">
                <img src={QouteForm} alt="QouteForm" />
              </div>
            </div> */}
              <div className="col-xl-12">
                <div className="form_content">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="programName">
                            Program Name<span className="red_staric">*</span>
                          </label>
                          <select
                            id="programName"
                            name="programName"
                            type="text"
                            onChange={(e) =>
                              onProgramChange(formik, e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.programName}
                            className={`form-control ${
                              formik.errors.programName &&
                              formik.touched.programName
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option
                              value=""
                              selected
                              hidden
                              label="Select a program name"
                            />
                            {comlinkPartner.map((partner, index) => (
                              <option
                                key={index}
                                value={partner}
                                label={partner}
                              />
                            ))}
                          </select>
                          {formik.errors.programName &&
                            formik.touched.programName && (
                              <div className="red_staric">
                                {formik.errors.programName}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="programDescription">
                            Program Description
                            <span className="red_staric">*</span>
                          </label>
                          <textarea
                            id="programDescription"
                            name="programDescription"
                            type="text"
                            rows={4}
                            disabled
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.programDescription}
                            className={`form-control ${
                              formik.errors.programDescription &&
                              formik.touched.programDescription
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.programDescription &&
                            formik.touched.programDescription && (
                              <div className="red_staric">
                                {formik.errors.programDescription}
                              </div>
                            )}
                        </div>
                      </div>
                      {
                      (formik.values.programName ==
                        "Comlink Door-to-door/event sales partner" ||
                        formik.values.programName ==
                          "Comlink Call Center Partner") && (
                          <div className="col-md-12">
                            <div className="form-group mb-xl-4 mb-lg-3 mb-2 ">
                              <label
                                htmlFor="providerName"
                                className="min-height-52"
                              >
                                Which service providers are you interested in
                                offering
                                <span className="red_staric">*</span>
                              </label>
                              <Select
                                isMulti
                                name="providerName"
                                styles={customStyles}
                                options={providers}
                                className="basic-multi-select form-control p-0"
                                classNamePrefix="select"
                                onChange={(selectedOptions) =>
                                  formik.setFieldValue(
                                    "providerName",
                                    selectedOptions
                                  )
                                }
                              />

                              {formik.errors.providerName &&
                                formik.touched.providerName && (
                                  <div className="red_staric">
                                    {formik.errors.providerName}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}

                      <div className="col-md-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="firstname">
                            First Name<span className="red_staric">*</span>
                          </label>
                          <input
                            id="firstname"
                            name="firstname"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            className={`form-control ${
                              formik.errors.firstname &&
                              formik.touched.firstname
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.firstname &&
                            formik.touched.firstname && (
                              <div className="red_staric">
                                {formik.errors.firstname}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="lastname">
                            Last Name<span className="red_staric">*</span>
                          </label>
                          <input
                            id="lastname"
                            name="lastname"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            className={`form-control ${
                              formik.errors.lastname && formik.touched.lastname
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.lastname &&
                            formik.touched.lastname && (
                              <div className="red_staric">
                                {formik.errors.lastname}
                              </div>
                            )}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="businessName">
                          Business Name
                 
                        </label>
                        <input
                          id="businessName"
                          name="businessName"
                          type="text"
                          placeholder="Enter your business name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.businessName}
                          className={`form-control ${
                            formik.errors.businessName &&
                            formik.touched.businessName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.businessName &&
                          formik.touched.businessName && (
                            <div className="red_staric">
                              {formik.errors.businessName}
                            </div>
                          )}
                      </div>
                    </div> */}
                      <div className="col-md-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="email">
                            Email<span className="red_staric">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className={`form-control ${
                              formik.errors.email && formik.touched.email
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <div className="red_staric">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="phone">
                            Phone<span className="red_staric">*</span>
                          </label>
                          <ReactInputMask
                            id="phone"
                            name="phone"
                            mask="(999) 999-9999"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            className={`form-control ${
                              formik.errors.phone && formik.touched.phone
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.phone && formik.touched.phone && (
                            <div className="red_staric">
                              {formik.errors.phone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="typeOfCompany">
                            Company Name <small> (If Available)</small>
                            {/* <span className="red_staric">*</span> */}
                          </label>
                          <input
                            id="typeOfCompany"
                            name="typeOfCompany"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.typeOfCompany}
                            className={`form-control ${
                              formik.errors.typeOfCompany &&
                              formik.touched.typeOfCompany
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.typeOfCompany &&
                            formik.touched.typeOfCompany && (
                              <div className="red_staric">
                                {formik.errors.typeOfCompany}
                              </div>
                            )}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="typeOfCompany">
                          Type of company<span className="red_staric">*</span>
                        </label>
                        <select
                          id="typeOfCompany"
                          name="typeOfCompany"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.typeOfCompany}
                          className={`form-control ${
                            formik.errors.typeOfCompany &&
                            formik.touched.typeOfCompany
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option
                            value=""
                            selected
                            hidden
                            label="Select a type of company"
                          />
                          {typeOfCompany.map((type, index) => (
                            <option key={index} value={type} label={type} />
                          ))}
                        </select>
                        {formik.errors.typeOfCompany &&
                          formik.touched.typeOfCompany && (
                            <div className="red_staric">
                              {formik.errors.typeOfCompany}
                            </div>
                          )}
                      </div>
                    </div> */}
                      <div className="col-md-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="companyWebsite">
                            Website URL <small>(If Available)</small>
                          </label>
                          <input
                            id="companyWebsite"
                            name="companyWebsite"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyWebsite}
                            className={`form-control ${
                              formik.errors.companyWebsite &&
                              formik.touched.companyWebsite
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.companyWebsite &&
                            formik.touched.companyWebsite && (
                              <div className="red_staric">
                                {formik.errors.companyWebsite}
                              </div>
                            )}
                        </div>
                      </div>
                      {formik.values.programName ==
                        "Comlink Call Center Partner" && (
                        <div className="col-md-12">
                          <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                            <label htmlFor="marketingName">
                              Which of these additional programs you would be
                              interested in
                              {/* <span className="red_staric">*</span> */}
                            </label>
                            <Select
                              isMulti
                              name="marketingName"
                              options={marketingName}
                              styles={customStyles}
                              className="basic-multi-select form-control p-0"
                              classNamePrefix="select"
                              onChange={(selectedOptions) =>
                                formik.setFieldValue(
                                  "marketingName",
                                  selectedOptions.map((market) => market.value)
                                )
                              }
                            />
                            {formik.errors.marketingName &&
                              formik.touched.marketingName && (
                                <div className="red_staric">
                                  {formik.errors.marketingName}
                                </div>
                              )}
                          </div>
                          {formik.values.marketingName.length === 1 ? (
                            formik.values.marketingName[0] ===
                            "Marketing Program" ? (
                              <div className="marketing-inner-wrap">
                                <p className="font_700">Marketing Program</p>
                                <p className="font_600 my-2">
                                  Social media Ad management Program{" "}
                                </p>
                                <ul>
                                  <li>$300 per month </li>
                                  <li>Google add on $100 per month</li>
                                </ul>
                                <p className="font_600 my-1">Includes</p>{" "}
                                <ul>
                                  <li>Ad creative</li> <li>Ad copy</li>
                                  <li>Website lead form </li>{" "}
                                </ul>{" "}
                              </div>
                            ) : (
                              <div className="marketing-inner-wrap">
                                <p className="font_700 text-capitalize">
                                  Lead Program
                                </p>
                                <ul>
                                  <li>$5 per exclusive lead</li>
                                </ul>
                                <p className="font_600 my-1">Bulk Discount</p>
                                <ul>
                                  <li>
                                    $4 per exclusive lead (500 lead bundle)
                                  </li>
                                  <li>
                                    $3.50 per exclusive lead (1000 or more)
                                  </li>{" "}
                                </ul>
                              </div>
                            )
                          ) : formik.values.marketingName.length > 1 ? (
                            <React.Fragment>
                              <div className="marketing-name-wrap">
                                <div>
                                  <p className="font_700">Marketing Program</p>
                                  <p className="font_600 my-2">
                                    Social media Ad management Program{" "}
                                  </p>
                                  <ul>
                                    <li>$300 per month </li>
                                    <li>Google add on $100 per month</li>
                                  </ul>
                                  <p className="font_600 my-1">Includes</p>{" "}
                                  <ul>
                                    <li>Ad creative</li> <li>Ad copy</li>
                                    <li>Website lead form </li>{" "}
                                  </ul>{" "}
                                </div>
                                <div>
                                  <p className="font_700 text-capitalize">
                                    Lead Program
                                  </p>
                                  <ul>
                                    <li>$5 per exclusive lead</li>
                                  </ul>
                                  <p className="font_600 my-1">Bulk Discount</p>
                                  <ul>
                                    <li>
                                      $4 per exclusive lead (500 lead bundle)
                                    </li>
                                    <li>
                                      $3.50 per exclusive lead (1000 or more)
                                    </li>{" "}
                                  </ul>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : null}
                        </div>
                      )}

                      <div className="col-md-6">
                        <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="hearAboutUs">
                            How did you hear about us:
                            <span className="red_staric">*</span>
                          </label>
                          <select
                            id="hearAboutUs"
                            name="hearAboutUs"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.hearAboutUs}
                            className={`form-control ${
                              formik.errors.hearAboutUs &&
                              formik.touched.hearAboutUs
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option
                              value=""
                              selected
                              hidden
                              label="Select a reference"
                            />
                            {hearAboutUs.map((hearUs, index) => (
                              <option
                                key={index}
                                value={hearUs}
                                label={hearUs}
                              />
                            ))}
                          </select>
                          {formik.errors.hearAboutUs &&
                            formik.touched.hearAboutUs && (
                              <div className="red_staric">
                                {formik.errors.hearAboutUs}
                              </div>
                            )}
                        </div>
                      </div>
                      {formik.values?.hearAboutUs == "Other" && (
                        <div className="col-md-6">
                          <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                          <label htmlFor="otherInformation">
                          Specify the Source<span className="red_staric">*</span>
                          </label>
                          <input
                            id="otherInformation"
                            name="otherInformation"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.otherInformation}
                            className={`form-control ${
                              formik.errors.otherInformation &&
                              formik.touched.otherInformation
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                            {formik.errors.otherInformation &&
                              formik.touched.otherInformation && (
                                <div className="red_staric">
                                  {formik.errors.otherInformation}
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                      {formik.values?.hearAboutUs == "Comlink Employee" && (
                        <div className="col-md-6">
                          <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                            <label htmlFor="employee">
                              Employee Name:
                              <span className="red_staric">*</span>
                            </label>
                            <select
                              id="employee"
                              name="employee"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.employee}
                              className={`form-control ${
                                formik.errors.employee &&
                                formik.touched.employee
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <option
                                value=""
                                selected
                                hidden
                                label="Select the name"
                              />
                              {employeeList.map((hearUs, index) => (
                                <option
                                  key={index}
                                  value={hearUs}
                                  label={hearUs}
                                />
                              ))}
                            </select>
                            {formik.errors.employee &&
                              formik.touched.employee && (
                                <div className="red_staric">
                                  {formik.errors.employee}
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                      <div className="col-12">
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            name="optIn"
                            className="overflow-control-input"
                            type="checkbox"
                            onChange={formik.handleChange}
                            checked={formik.values.optIn}
                          />
                          <span className="overflow-control-indicator "></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                            <div className="Submitting_form box main-wrapper-tooltip">
                              <label className="pl-25">
                                <p>
                                  I agree to the
                                  <span className="tooltip-wrapper">
                                    <span className="tooltips">
                                      Terms & Conditions
                                    </span>
                                    <span className="tooltiptext">
                                      By submitting this form, you acknowledge
                                      that you permit Comlink Total Solutions
                                      express consent to contact you at the
                                      number and/or email address you have
                                      provided above with automated technology
                                      in relation to this inquiry via phone,
                                      e-mail, or text message. You understand
                                      that making a purchase is not required to
                                      consent to receive communications from
                                      Comlink Total Solutions.
                                    </span>
                                  </span>
                                </p>
                              </label>
                            </div>
                          </span>
                        </label>
                        {formik.errors.optIn && formik.touched.optIn && (
                          <div className="red_staric">
                            {formik.errors.optIn}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3">
                      <button type="submit" className="btn theme-btn">
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstForm;
