import React, { useEffect, useState } from 'react'
import "./ContactUs.css"
import { Images } from "../../../../assets/assets";
import { toast } from 'react-toastify'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactInputMask from 'react-input-mask';

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
const ORIGIN = process.env.REACT_APP_ORIGIN

const headers = {
    'Content-Type': 'application/json',
    origin: ORIGIN, // Replace with the actual client URL
    apikey: API_KEY, // Replace with your actual API key
};

const apiUrl = `${API_URL}/leads/create`

const USStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida',
    'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
    'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
    'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah',
    'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const initialValues = {
    fullname: '',
    businessName: '',
    phone: '',
    email: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
};

const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Name is required').matches(/^[A-Za-z\s]+$/, 'Fullname  must contain only letters and spaces'),
    businessName: Yup.string().required('Business name is required'),
    phone: Yup.string()
        .required('Phone number is required')
        .matches(/^[^_]*$/, 'Phone number must be exactly 10 digits')
        .length(14, 'Phone number must be exactly 10 digits'),
    email: Yup.string()
        .required('Email is required')
        .email('Invalid email address'),
    city: Yup.string()
        .required('City is required')
        .matches(/^[A-Za-z\s]+$/, 'City must contain only letters and spaces')
        .nullable(),
    state: Yup.string()
        .required('State is required')
        .matches(/^[A-Za-z\s]+$/, 'State must contain only letters and spaces')
        .nullable(),
    country: Yup.string().required('Country is required'),
    zipcode: Yup.string()
        .required('Zip code is required')
        .length(5, 'Zip code must be exactly 5 characters')
        .matches(/^[0-9]+$/, 'Zipcode must contain only numbers'),
});

function ContactUs() {

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const requestOptions = {
                method: 'POST',
                headers,
                body: JSON.stringify({ ...values, source: "Contact Us" }),
            };
            toast.dismiss();
            fetch(apiUrl, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json(); // Parse the response body as JSON
                })
                .then(data => {
                    // Handle the successful response here
                    formik.resetForm();
                    toast.success("Thanks for contacting. Have a great day!")
                })
                .catch(error => {
                    // Handle any errors that occur during the request
                    toast.success("Thanks for contacting.")
                    console.error('Error:', error);
                })
        },
    });
    return (
        <div></div>
        // <div id="contactUs">
        //     <div className='Qoute__content'>
        //         <div className='custom-container'>
        //             <div className='contact-headings text-center'>
        //                 <h4 className='theme-clr mb-lg-3 mb-2'>Contact Us</h4>
        //                 <p>Leave your information below, one of our representatives will get in touch with you.</p>
        //             </div>
        //             <div className='qoute__form'>
        //                 <div className='row'>
        //                     <div className='col-xl-3'>
        //                         <div className='form_image'>
        //                             {/* <img
        //                                 src={Images.quoteimg}
        //                                 className="img-fluid"
        //                                 alt="img"
        //                             /> */}
        //                         </div>
        //                     </div>
        //                     <div className='col-xl-9'>
        //                         <div className='form_content'>
        //                             <form onSubmit={formik.handleSubmit}>
        //                                 <div className='row'>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="fullname">Your Name<span className='red_staric'>*</span></label>
        //                                             <input
        //                                                 id="fullname"
        //                                                 name="fullname"
        //                                                 type="text"
        //                                                 placeholder="Enter your name"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.fullname}
        //                                                 className={`form-control ${formik.errors.fullname && formik.touched.fullname ? 'is-invalid' : ''}`}
        //                                             />
        //                                             {formik.errors.fullname && formik.touched.fullname && <div className="red_staric">{formik.errors.fullname}</div>}
        //                                         </div>
        //                                     </div>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="businessName">Business Name<span className='red_staric'>*</span></label>
        //                                             <input
        //                                                 id="businessName"
        //                                                 name="businessName"
        //                                                 type="text"
        //                                                 placeholder="Enter your business name"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.businessName}
        //                                                 className={`form-control ${formik.errors.businessName && formik.touched.businessName ? 'is-invalid' : ''}`}
        //                                             />
        //                                             {formik.errors.businessName && formik.touched.businessName && <div className="red_staric">{formik.errors.businessName}</div>}
        //                                         </div>
        //                                     </div>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="phone">Phone<span className='red_staric'>*</span></label>
        //                                             <ReactInputMask
        //                                                 id="phone"
        //                                                 name="phone"
        //                                                 mask='(999) 999-9999'
        //                                                 type="text"
        //                                                 placeholder="Enter your phone"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.phone}
        //                                                 className={`form-control ${formik.errors.phone && formik.touched.phone ? 'is-invalid' : ''}`}
        //                                             />
        //                                             {formik.errors.phone && formik.touched.phone && <div className="red_staric">{formik.errors.phone}</div>}
        //                                         </div>
        //                                     </div>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="email">Email<span className='red_staric'>*</span></label>
        //                                             <input
        //                                                 id="email"
        //                                                 name="email"
        //                                                 type="email"
        //                                                 placeholder="Enter your email"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.email}
        //                                                 className={`form-control ${formik.errors.email && formik.touched.email ? 'is-invalid' : ''}`}
        //                                             />
        //                                             {formik.errors.email && formik.touched.email && <div className="red_staric">{formik.errors.email}</div>}
        //                                         </div>
        //                                     </div>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="city">City<span className='red_staric'>*</span></label>
        //                                             <input
        //                                                 id="city"
        //                                                 name="city"
        //                                                 type="text"
        //                                                 placeholder="Enter your city"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.city}
        //                                                 className={`form-control ${formik.errors.city && formik.touched.city ? 'is-invalid' : ''}`}
        //                                             />
        //                                             {formik.errors.city && formik.touched.city && <div className="red_staric">{formik.errors.city}</div>}
        //                                         </div>
        //                                     </div>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="state">State<span className='red_staric'>*</span></label>
        //                                             <select
        //                                                 id="state"
        //                                                 name="state"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.state}
        //                                                 className={`form-control ${formik.errors.state && formik.touched.state ? 'is-invalid' : ''}`}
        //                                             >
        //                                                 <option value="" selected hidden label="Select a state" />
        //                                                 {USStates.map((state, index) => (
        //                                                     <option key={index} value={state} label={state} />
        //                                                 ))}
        //                                             </select>
        //                                             {formik.errors.state && formik.touched.state && <div className="red_staric">{formik.errors.state}</div>}
        //                                         </div>
        //                                     </div>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="zipcode">Zip/Postal Code<span className='red_staric'>*</span></label>
        //                                             <ReactInputMask
        //                                                 id="zipcode"
        //                                                 name="zipcode"
        //                                                 type="text"
        //                                                 mask="99999"
        //                                                 placeholder="Enter your zipcode"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.zipcode}
        //                                                 className={`form-control ${formik.errors.zipcode && formik.touched.zipcode ? 'is-invalid' : ''}`}
        //                                             />
        //                                             {formik.errors.zipcode && formik.touched.zipcode && <div className="red_staric">{formik.errors.zipcode}</div>}
        //                                         </div>
        //                                     </div>
        //                                     <div className='col-xl-6'>
        //                                         <div className="form-group mb-xl-4 mb-lg-3 mb-2">
        //                                             <label htmlFor="country">Country<span className='red_staric'>*</span></label>
        //                                             <input
        //                                                 id="country"
        //                                                 name="country"
        //                                                 type="text"
        //                                                 placeholder="Enter your country"
        //                                                 onChange={formik.handleChange}
        //                                                 onBlur={formik.handleBlur}
        //                                                 value={formik.values.country}
        //                                                 className={`form-control ${formik.errors.country && formik.touched.country ? 'is-invalid' : ''}`}
        //                                             />
        //                                             {formik.errors.country && formik.touched.country && <div className="red_staric">{formik.errors.country}</div>}
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className='d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3'>
        //                                     <button type="submit" className='btn theme-btn'>Get In Touch</button>
        //                                 </div>
        //                             </form>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ContactUs
