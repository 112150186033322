import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../../assets/assets";
import "./Banner.css";
import { Link } from 'react-router-dom';

function Banner() {
    return (
        <>
            <section className='baner-section'>
                <Container fluid className="custom-container">
                    <Row className='content--row'>
                        <Col lg={7}>
                            <div className='baner-content'>
                                <h5 className='theme-clr'>THE WORLDS ONLY</h5>
                                <h3 className='green-clr'>RESIDENTIAL AND COMMERCIAL</h3>
                                <h1 className='theme-clr'>MASTER AGENT</h1>
                                <ul className='top-services'>
                                    <li>
                                        <p className='gray-clr'>All Major Carriers!</p>
                                    </li>
                                    <li>
                                        <p className='gray-clr'>Join The Comlink Partner Program Today!</p>
                                    </li>
                                    <li>
                                        <p className='gray-clr'>Top Paying Commissions And Weekly Fundings!</p>
                                    </li>
                                </ul>
                                <div className='baner-btn'>
                                    <Link to="https://calendly.com/gocomlink/60min" target='_blank'><button className='btn theme-btn'>Contact Us</button></Link>
                                    {/* https://calendly.com/gocomlink/60min */}
                                    <Link to="/sign-up" ><button className='btn green-borderd-btn ms-md-3 mt-md-0 mt-3'>Sign Up</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col className='mobile-banner w-100'>
                            <div className='w-100'>
                                <div className='baner-img-wraper'>
                                    <img src={Images.bannerimg1} className="img-fluid" alt="img" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Banner
