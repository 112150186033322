import React from "react";
import "./OurTeam.css";
import member1 from "../../../../assets/images/Greg-Santini.png";
import member2 from "../../../../assets/images/Melody-Mcnulty.png";
import member3 from "../../../../assets/images/Jahanzaib Dar.png";
import member4 from "../../../../assets/images/Gary-Peacock.png";

function OurTeam() {
  return (
    <>
      <div className="our_team">
        <div className="custom-container">
          <div className="team_wraper">
            <h5>Our</h5>
            <h4>Team Members</h4>
            <p>
              We have experts who are here to help you with all the
              opportunities you may get your hands onto.
            </p>

            <div className="team-members">
              <div className="first_member">
                <div className="overlay-position">
                  <img src={member1} alt="first-memebr" className="img-fluid" />
                </div>
                <div className="member-detail">
                  <h6>Greg-Santini</h6>
                  <p>Vice-President (GS)</p>
                </div>
              </div>
              <div className="first_member position-mt-102">
                <div className="overlay-position">
                  <img src={member2} alt="first-memebr" className="img-fluid" />
                </div>
                <div className="member-detail">
                  <h6>Arlenis Rivas</h6>
                  <p>Director-of-Operations</p>
                </div>
              </div>
              <div className="first_member">
                <div className="overlay-position">
                  <img src={member3} alt="first-memebr" className="img-fluid" />
                </div>
                <div className="member-detail">
                  <h6>Jahanzaib Dar</h6>
                  <p>Marketing Director (JD)</p>
                </div>
              </div>
              <div className="first_member position-mt-102 mr-0">
                <div className="overlay-position">
                  <img src={member4} alt="first-memebr" className="img-fluid" />
                </div>
                <div className="member-detail">
                  <h6>Gary-Peacock</h6>
                  <p>
                    Senior-Account-
                    <br />
                    Manager
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurTeam;
