import React, { useRef, useState } from "react";
import "./OurBusiness.css";
import dummy_Video from "../../../../assets/images/main-video.mp4";
import poster from "../../../../assets/images/newThumb.png";

function OurBusiness() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = async () => {
    if (videoRef.current.paused) {
      // Use 'paused' property
      await videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  return (
    <div className="business-linear-background">
      <div className="our-business">
        <div className="custom-container">
          <div className="our-business-wraper">
            <div className="our-business-video">
              <video poster={poster} ref={videoRef} controls>
                <source src={dummy_Video} type="video/mp4" />
              </video>
              <div
                className="play-button-wrpaer"
                style={{ display: isPlaying ? "none" : "block" }}
                onClick={handlePlayPause}
              >
                <lottie-player
                  src="https://lottie.host/39fe8e3a-4c11-4b6b-ab4b-cd433607034b/pSb2JGhSWL.json"
                  background="transparent"
                  speed="1"
                  style={{ width: " 150px", height: "150px" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>

            <div className="our-business-content">
              <h5>Our Business</h5>
              <h4>Is Your Business</h4>
              <p>
                As a reseller, you don’t have to worry about the product
                manufacturing and sourcing. All you have to do is to focus on
                closing sales and grabbing your profit. We will handle the rest.
                Earn recurring commissions, manage the customer experience with
                simplified billing and support.
              </p>
            </div>
          </div>
          <div className="Compensation-Plan">
            <h5>Check Out Your Incentives</h5>
            <h3>Please Go Through Our Amazing</h3>
            <h2>Compensation-Plan!</h2>
            <div className="Compensation-Plan-btn">
              <button class="btn theme-btn">Compensation Plan</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBusiness;
