import React, { useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
const initialValues = {
  accountName: "",
  routingNumber: "",
  accountNumber: "",
  country: "",
  // decline: true,
};

const validationSchema = Yup.object().shape({
  accountName: Yup.string().nullable(),
    // .matches(/^[A-Za-z\s]+$/, "Only letters and spaces allowed")
    // .required("Account name is required"),
  routingNumber: Yup.string().nullable(),
  // .required("Please enter your routing number"),
  // country: Yup.string(),
  // .matches(/^[0-9]{9}$/, "Routing number must be exactly 9 digits")
  // .length(9, "Routing number must be exactly 9 digits")
  // .nullable(),
  accountNumber: Yup.string().nullable(),
  // required("Please enter your account number"),
  // .matches(/^[0-9]{12}$/, "Account number must be exactly 12 digits")
  // .nullable(),
  // .length(12, "Account number must be exactly 12 digits").nullable(),
  // decline: Yup.boolean(),
});

// const validationSchema = Yup.object().shape({
//   accountName: Yup.string().when("decline", (decline, schema) =>
//     decline === false
//       ? schema.matches(/^[A-Za-z\s]+$/, "Only letters and spaces allowed")
//       : schema.nullable()
//   ),

//   routingNumber: Yup.string().when("decline", {
//     is: false,
//     then: Yup.string()
//       .matches(/^[0-9]{9}$/, "Routing number must be exactly 9 digits")
//       .length(9, "Routing number must be exactly 9 digits"),
//     otherwise: Yup.string().nullable(),
//   }),
//   accountNumber: Yup.string().when("decline", {
//     is: false,
//     then: Yup.string()
//       .matches(/^[0-9]{12}$/, "Account number must be exactly 12 digits")
//       .length(12, "Account number must be exactly 12 digits"),
//     otherwise: Yup.string().nullable(),
//   }),
//   decline: Yup.boolean(),
// });
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};
const FifthForm = (props) => {
  const sectionRef = useRef(null);
  const startRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const apiUrl = `${API_URL}/leads/create`;
  useEffect(() => {
    scrollToSection(sectionRef);
    scrollToSection(startRef);
  }, []);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const createPartner = (data) => {
    const newValues={
      ...data,
      ...formik.values
    }
    const formData = new FormData(); // Create a new FormData object
    for (const key of Object.keys(newValues)) {
      if (key !== "sign") {
        const value = newValues[key];
        formData.append(key, value);
      } else {
        formData.append("sign", newValues.sign);
      }
    }
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({ ...newValues, source: "Sign Up" }),
    };
    toast.dismiss();
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          console.log("🚀 ~ .then ~ response:", response)

          throw new Error(
            response.status == 429
              ? "Too many requests from this IP, please try again after a day"
              : response.status == 409 ?  "A lead with the same email already exists"  : "Something went wrong"
          );
        }
        return response.json(); // Parse the response body as JSON
      })
      .then((data) => {
        props.nextStep();
        props.setStep(6);
        props.setFlag(!props.flag);
        setLoading(false);
        console.log("Response data:", data);
        props.setData({});
      })
      .catch((error) => {
        setLoading(false);
        // Handle any errors that occur during the request
        toast.error("" + error);
        console.log("Error:", error);
      });
  };
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      props?.setData({
        ...props?.data,
        ...values,
      });
    },
  });
  const submitForm=()=>{
       setLoading(true);
      createPartner(props?.data);
  }
  return (
    <div className="Qoute__content">
      <div className="custom-container">
        <div className="contact-headings text-center">
          <h4 className="theme-clr mb-lg-3 mb-2">Direct Deposit Setup</h4>
          {/* <p>
            Leave your information below, one of our representatives will get in
            touch with you.
          </p> */}
        </div>
        <div className="bg-qoute">
        <div ref={sectionRef} className="qoute__form">
          <div className="row">
            {/* <div className="col-xl-3">
              <div className="form_image">
                <img src={QouteForm} alt="QouteForm" />
              </div>
            </div> */}
            <div className="col-xl-12">
              <p className="text-success text-center mb-2">Direct deposit is optional but will delay commission payments. You can click Next to complete the application.</p>
              <div className="form_content">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    {/* <div className="col-md-12">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="country">
                          Country<span className="red_staric">*</span>
                        </label>
                        <select
                          id="country"
                          name="country"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                          className={`form-control ${
                            formik.errors.country && formik.touched.country
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option
                            value="United States"
                            selected
                            label="United States"
                          />
                        </select>
                        {formik.errors.country && formik.touched.country && (
                          <div className="red_staric">
                            {formik.errors.country}
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div className="col-xl-12">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label for="exampleInputEmail1">
                          Account Holder Name
                          {/* <span className="red_staric">*</span> */}
                        </label>
                        <input
                          id="accountName"
                          name="accountName"
                          type="text"
                          placeholder="Enter your name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.accountName}
                          className={`form-control ${
                            formik.errors.accountName &&
                            formik.touched.accountName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.accountName &&
                          formik.touched.accountName && (
                            <div className="red_staric">
                              {formik.errors.accountName}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label for="exampleInputEmail1">
                          Routing Number
                          {/* <span className="red_staric">*</span> */}
                        </label>
                        <input
                          id="routingNumber"
                          name="routingNumber"
                          // mask="999999999"
                          type="text"
                          placeholder="Enter your routing number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.routingNumber}
                          className={`form-control ${
                            formik.errors.routingNumber &&
                            formik.touched.routingNumber
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.routingNumber &&
                          formik.touched.routingNumber && (
                            <div className="red_staric">
                              {formik.errors.routingNumber}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label for="exampleInputEmail1">
                          Account Number
                          {/* <span className="red_staric">*</span> */}
                        </label>
                        <input
                          id="accountNumber"
                          name="accountNumber"
                          type="text"
                          placeholder="Enter your account number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.accountNumber}
                          className={`form-control ${
                            formik.errors.accountNumber &&
                            formik.touched.accountNumber
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.accountNumber &&
                          formik.touched.accountNumber && (
                            <div className="red_staric">
                              {formik.errors.accountNumber}
                            </div>
                          )}
                      </div>
                    </div>
                    {/* <div className="col-xl-12">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="decline"
                          className="overflow-control-input"
                          type="checkbox"
                          onChange={formik.handleChange}
                          checked={formik.values.decline}
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">Decline</label>
                          </div>
                        </span>
                      </label>
                    </div> */}
                  </div>
                  <div className="d-flex  justify-content-between mt-xl-4 mt-3 mb-xl-4 mb-3">
                    <div
                      onClick={() => {
                        props.setStep(4);
                        props.previousStep();
                      }}
                      className="btn theme-btn flex-start"
                    >
                      Previous
                    </div>
                    {loading ? (
                      <div className="btn theme-btn">
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>{" "}
                      </div>
                    ) : (
                      <button type="submit" onClick={()=>submitForm()} className="btn theme-btn">
                        Next
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default FifthForm;
