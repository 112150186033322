import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Link } from 'react-router-dom';

const ThanksPage = (props) => {
  const myElement = useRef(null);
 
  useLayoutEffect(() => {
    console.log(myElement.current,'myElement.current')
    if (myElement.current) {
      myElement.current.scrollIntoView({
        behavior: "smooth",
        block: "center", 
      });
    }
  }, [props.flag]); 

  return (
    <div className="Qoute__content" ref={myElement}>
      <div className="custom-container">
          <div className="row justify-content-center align-items-center">
            {/* <div className="col-xl-3">
              <div className="thanks_image">
                <img src={QouteForm} alt="QouteForm" />
              </div>
            </div> */}
            <div  className="col-lg-8 col-md-9">
              <div className="form_content qoute__form thanku-form">
                <div className="terms_condition d-flex align-items-start mt-0">
                  <div className="contact-headings text-center">
                    <h4 className="theme-clr mb-lg-3 mb-2">
                      Thanks for signing up
                    </h4>
                    <p>
                      Please check your email for your CRM credentials. If there are any questions, you can email us  at &nbsp; {" "}
                      <a href="mailto:onboarding@gocomlink.com">
                        onboarding@gocomlink.com {" "}
                      </a>
                       or call us  at &nbsp; <a href="tel:+18335260200">833-526-0200</a>,
                      our hours of operations are 9 AM - 6 PM EST (Monday -
                      Friday)
                    </p>
                    <div className='baner-btn mt-3'>
                    <p className="mb-4 appointment-by-button">Schedule your appointment by clicking the button below.</p>

                                    <Link to="https://calendly.com/gocomlink/60min" target='_blank'><button className='btn theme-btn'>Make Appointment</button></Link>
                                    {/* https://calendly.com/gocomlink/60min */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default ThanksPage;
