import React, { useEffect, useRef, useState } from "react";
import "./Agreement.css";
import agreementLogo from "../../../../assets/images/comlink-partner.svg";
import confidentialLogo from "../../../../assets/images/confidential.png";
import SignatureCanvas from "react-signature-canvas";
import upArrow from "../../../../assets/icons/upArrow.svg";
import downArrow from "../../../../assets/icons/downArrow.svg";

const Agreements = ({
  setSignatureData,
  sign,
  signatureRef,
  setErrors,
  errors,
  // agreement,
  setData,
  data,
  handleCheckboxChange,
  validationRef,
  scrollToSection,
}) => {
  console.log(errors,'errors')

  const divRef = useRef(null);
  // Function to clear the signature
  const clearSignature = () => {
    signatureRef.current.clear();
    setSignatureData(null);
  };

  // Function to get the signature data
  const getSignatureData = () => {
    console.log(signatureRef.current);
    if (signatureRef.current.isEmpty()) {
      // Handle empty signature case
      errors.sign = "We need your sign for agreement";
      setErrors(errors);
      return;
    }
    const data = signatureRef.current.toDataURL();
    setSignatureData(data);
    signatureRef.current.clear();
  };

  const handleUpButtonClick = () => {
    if (divRef.current) {
      divRef.current.scrollTop -= 320;
    }
  };

  const handleDownButtonClick = () => {
    scrollToSection(validationRef);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isEmpty(errors)){
      scrollToSection(validationRef);
    }
    // const newErrors = {};
    // // Validate signersName
    // if (!data.signersName.trim()) {
    //   newErrors.fullname = "Signers name is required";
    // }
    // // Validate signersTitle
    // if (!data.signersTitle.trim()) {
    //   newErrors.signersTitle = "Signers title name is required";
    // }

    // // If there are errors, set them in state
    // if (Object.keys(newErrors).length > 0) {
    //   setError(newErrors);
    // } else {
    //   // Form is valid, you can submit the data or perform other actions here
    //   setError({});
    //   // props.setStep(2);
    //   // props.nextStep();
    //   // console.log("Form data:", data);
    // }
  };
  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;   
    if (name == "signersName") {
      let initialValue = getInitials(value);
      setData({
        ...data,
        signersTitle: initialValue,
        [name]: value,
      });
    }else{
      setData({
        ...data,
        [name]: value,
      });
    }
   
   
  };
  function getInitials(name) {
    // Split the name by spaces to get individual parts and filter out any empty strings
    const nameParts = name.trim().split(/\s+/).filter(Boolean);

    // Map through the name parts and take the first character of each, then join them
    const initials = nameParts.map((part) => part[0].toUpperCase()).join("");

    return initials;
  }
  return (
    <div>
      <div className="agreement-header">
        <img src={agreementLogo} alt="agreementLogo" />
      </div>
      <div className="agreement-setting" ref={divRef}>
        <button
          onClick={() => handleDownButtonClick()}
          type="button"
          className="btn btn-sm btn-primary fix-ed-butt-on-scro-ll"
        >
          <img src={downArrow} alt="downArrow" />
        </button>
        <h5 className="d-flex justify-content-center py-2">MASTER AGREEMENT</h5>
        <h6 className="py-2 px-5">RECITALS</h6>
        {data.programName == "Comlink affiliate referral partner" ||
        data.programName == "Comlink Call Center Partner" ? (
          <React.Fragment>
            <ol>
              <li className="agreement-listing">
                Comlink provides an online Customer Records Management portal to
                multi-system operators and other telecommunications companies
                who offer services to end users including services such as
                video, high speed internet access and telephone together with
                cross-selling complimentary services (collectively, Comlink and
                the additional companies offering such services are referred to
                as <b>Comlink Service Providers</b>)
              </li>
              <li className="agreement-listing">
                Comlink offers online marketing services through the use of
                various tactics including, but not limited to: custom websites,
                lead generation, landing page optimization, targeted
                advertising, organic search strategies and paid search
                advertising (<b>Comlink Services</b>). The Comlink Services
                provide prospects with available offers from Comlink Service
                Providers and then attempts to convert the prospect into a
                customer using Comlink systems including web order, on-line chat
                and call center assets. Comlink is paid by the customer or the
                Comlink Service Provider, typically after the prospect who has
                been converted to a customer by way of an order from the Comlink
                Service Provider has installed and made an initial payment to
                the Comlink Service Provider or Comlink directly.
              </li>
              <li className="agreement-listing">
                Independent Contractor has the desire and capability to generate
                leads for the Comlink Service Providers products through the use
                of Comlink approved marketing tactics, specifically for the
                products and services of the Comlink Service Providers. (
                <b>Independent Contractor Services</b>).
              </li>
              <li className="agreement-listing">
                The Parties desire to enter into an agreement pursuant to which
                Independent Contractor will provide the Independent Contractor
                Services to Comlink, as further described herein.
              </li>
            </ol>
            <p className="agreement-listing">
              <b>NOW THEREFORE,</b>in consideration of the mutual covenants
              contained herein, the Parties agree as follows:
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                <b>Definitions</b> Capitalized terms used herein and not
                otherwise defined herein are used as defined in this Section A.
              </li>
              <li className="agreement-listing">
                <b>Advertisements</b> means direct marketing, search marketing,
                digital advertising, door to door marketing, sponsorship
                messages, banner advertisements, buttons, toll-free numbers and
                other promotional items developed for online and direct
                promotions.
              </li>
              <li className="agreement-listing">
                <b>Applicable Law</b> means any law or regulation governing the
                transaction, consumer, prospect or any of the parties or
                Independent Contractors, under the circumstances of the
                particular event or action.
              </li>
              <li className="agreement-listing">
                <b>Banner Ad</b> means an Advertisement that is placed on an
                Independent Contractor Site or by Independent Contractor in
                accordance with the brand and other guidelines of this Agreement
              </li>
              <li className="agreement-listing">
                <b>Commission</b> means the manner in which an Independent
                Contractor Customer is referred to a Comlink Service Provider,
                which shall occur when the Independent Contractor Customer
                contacts Comlink via a warm transfer from Independent Contractor
                to a Comlink call center or using the COMLINK portal such other
                manner as the Parties may mutually agree upon from time-to-time.
              </li>
              <li className="agreement-listing">
                <b>Commission Fees</b> means any fees earned by Independent
                Contractor under this Agreement.
              </li>
              <li className="agreement-listing">
                <b>Confidential Information</b> means any and all nonpublic
                business or technical information disclosed by a Party hereto (a{" "}
                <b>Disclosing Party</b>) to the other Party hereto (a{" "}
                <b>Receiving Party</b>) in connection with this Agreement
                including information concerning the Disclosing Party’s past,
                present and future customers, suppliers, technology (including
                software), and business, and any other information identified as
                confidential, including without limitation know-how, data,
                technology, trade secrets, processes, techniques, programs,
                programming interfaces, designs, formulae, marketing,
                advertising, financial, commercial, sales or programming
                materials, written materials, compositions, drawings, diagrams,
                computer programs, studies, work in progress, visual
                demonstrations, ideas, concepts, and other data, in oral,
                written, graphic, electronic, or any other form whatsoever.
                Notwithstanding the foregoing, “Confidential Information” does
                not include:
                <ol>
                  <li className="agreement-listing">
                    Information which is generally known to the public when
                    received by the Receiving Party or which subsequently
                    becomes generally known to the public through no fault of
                    the Receiving Party;
                  </li>
                  <li className="agreement-listing">
                    Information which is already known to the Receiving Party at
                    the time of its disclosure and is not the subject of an
                    obligation of confidence of any kind;
                  </li>
                  <li className="agreement-listing">
                    Information which is independently developed by the
                    Receiving Party without reference to the Confidential
                    Information of the Disclosing Party;
                  </li>
                  <li className="agreement-listing">
                    Information which is rightfully received by the Receiving
                    Party from a third party without an obligation of
                    confidence; and
                  </li>
                  <li className="agreement-listing">
                    Information that is required to be disclosed by law,
                    provided that the Receiving Party gives prompt notice of
                    such requested disclosure to the Disclosing Party and
                    complies with any valid protective order obtained by the
                    Disclosing Party.
                  </li>
                </ol>
              </li>
              <li className="agreement-listing">
                <b>Customer Proprietary Network Information</b> or <b>CPNI</b>{" "}
                means the data collected by Independent Contractor which can be
                used to distinguish or trace an individual's identity, such as
                their name, social security number, credit card information,
                biometric records, etc. alone, or when combined with other
                personal or identifying information which is linked or linkable
                to a specific individual, such as date and place of birth,
                mother’s maiden name, et, and information about any consumer who
                Links onto an Independent Contractor Site, which may include,
                but not be limited to, the time, date, duration and destination
                of the Link, the type of network a consumer subscribes to, and
                any other personal information that Independent Contractor
                receives about such consumer and that may be considered CPNI
                under Applicable Law.
              </li>
              <li className="agreement-listing">
                <b>Comlink Sites</b> means the URLs/domains used by Comlink to
                offer the Comlink Services.
              </li>
              <li className="agreement-listing">
                <b>Hosted</b> means to provide and manage servers, facilities,
                telecommunications, maintenance, and operations related to the
                delivery of Internet based services and content.
              </li>
              <li className="agreement-listing">
                <b>Independent Contractor Customer</b> means a consumer who
                contacts COMLINK, through its portal or call center, through
                Independent Contractor’s Advertisement efforts, is processed
                through Independent Contractor’s call center or is
                warm-transferred by Independent Contractor’s call center to a
                COMLINK portal or call center in all cases directly due to
                Independent Contractor’s Marketing Tactics performed pursuant to
                this Agreement.
                <p className="agreement-listing">
                  <b>Intellectual Property Rights</b>means:
                </p>
                <ol>
                  <li className="agreement-listing">
                    Any and all proprietary rights provided under: patent law,
                    copyright law, trademark law, design patent or industrial
                    design law, semi-conductor chip or mask work law, trade
                    secret law, or any other similar statutory provision or
                    common law principle which may provide a right in either
                    ideas, formulae, algorithms, concepts, inventions or
                    know-how generally, or the expression or use of such ideas,
                    formulae, algorithms, concepts, inventions or know-how; and
                  </li>
                  <li className="agreement-listing">
                    Any and all applications, registrations, licenses,
                    sublicenses, agreements or any other evidence of a right in
                    any of the foregoing;
                  </li>
                </ol>
              </li>
              <li className="agreement-listing">
                <b>Link</b> means a URL hidden behind a formatting option that
                may take the form of a colored item of text (such as a URL
                description), logo, button, banner or image, and which allows a
                user to automatically and directly transfer to such other URL,
                that is, to move to or between Internet pages, sites or within a
                document; the term Link will also include the act of moving to
                or between such locations as the context may require.
              </li>
              <li className="agreement-listing">
                <b>Mark</b> means a trademark or service mark of a Party hereto.
              </li>
              <li className="agreement-listing">
                <b>Hosted</b> means to provide and manage servers, facilities,
                telecommunications, maintenance, and operations related to the
                delivery of Internet based services and content.
              </li>
              <li className="agreement-listing">
                <b>Marketing Tactics</b> means the ad copy, lead generation
                techniques, websites, landing pages, call script, collateral and
                other means by which Independent Contractor performs sales and
                marketing services under this Agreement and in accordance with
                Article 2, including the Rules of Engagement.
              </li>
              <li className="agreement-listing">
                <b>Non-qualified Call or Lead</b> means a call or lead that is
                received by the Comlink portal or a COMLINK call center agent
                that is delivered by Independent Contractor where the customer
                is not a potential sale for the products and services offered by
                Comlink from the Comlink Service Providers.
              </li>
              <li className="agreement-listing">
                <b>Qualified Call or Lead</b> means a call or lead that is
                received by the Comlink portal or a COMLINK call center agent,
                delivered from Independent Contractor by means of a toll-free
                number (“TFN”) assigned by Comlink to Independent Contractor or
                warm transfer where the customer has been properly informed and
                is willing to speak with a Comlink call center agent for more
                than two minutes about obtaining products or services from the
                Comlink Service Providers in accordance with the Comlink
                approved Marketing Tactics or pre-qualification guidelines
                submitted by Comlink and as amended from time to time.
              </li>
              <li className="agreement-listing">
                <b>Qualified Sale</b> means a Qualified Call which results in an
                order of an RGU, product or service from a Comlink Service
                Provider.
              </li>
              <li className="agreement-listing">
                <b>RGU</b> means, in context of MSO and Telecommunication
                Companies, a revenue generating unit and refers to the phone,
                Internet and video products or services that Comlink sells to
                Independent Contractor Customers, regardless of tier. Each
                product or service is considered a single RGU even if a customer
                orders more than one product or service in a single transaction.
                For example, a customer who orders video and Internet, will be
                considered to have sold two RGUs.
              </li>
              <li className="agreement-listing">
                <b>RGU Conversion Rate</b> means the rate at which Independent
                Contractor’s Qualified Calls are closed according to the
                following formula: Qualified Sales/Qualified Calls. The RGU
                Conversion Rate will be calculated on a monthly basis and shall
                be targeted for at least twenty percent (20%) RGU Conversion
                Rate.
              </li>
              <li className="agreement-listing">
                <b>RGU Install Rate</b> means the ratio at which Independent
                Contractor’s Qualified Sale or Qualified Web- Generated Sale
                take install or receipt of the products or services offered by
                Comlink Service Providers as a percentage, e.g., Month 1 has 100
                orders; 60 customers take install and pay the first bill. The
                RGU Install rate is equal to 60%. The RGU Install Rate shall be
                targeted for at least fifty percent (50%).
              </li>
              <li className="agreement-listing">
                <b>Commission</b> means the manner in which an Independent
                Contractor Customer is referred to a Comlink Service Provider,
                which shall occur when the Independent Contractor Customer
                contacts Comlink via a warm transfer from Independent Contractor
                to a Comlink call center or using the COMLINK portal such other
                manner as the Parties may mutually agree upon from time-to-time.
              </li>
              <li className="agreement-listing">
                <b>Commission Fees</b> means any fees earned by Independent
                Contractor under this Agreement.
              </li>
              <li className="agreement-listing">
                <b>Rules of Engagement</b> means those rules that Independent
                Contractor must at all time follow in the performance of the
                Independent Contractor Services, and as typically passed from
                the Comlink Service Provider to Comlink for obligatory use by
                Comlink and the Comlink Independent Contractors.
              </li>
              <li className="agreement-listing">
                <b>URL</b> means Universal Resource Locator, which will design
                the unique Internet protocol address for locating and accessing
                an Internet web site and page.
              </li>
            </ol>
            <p className="agreement-listing">
              <b>Description of the Independent Contractor Services.</b>
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                Independent Contractor desires to sell services from Comlink
                Service Providers and as mutually amended, agreed and
                supplemented from time to time.
              </li>
              <li className="agreement-listing">
                <b>Rules of Engagement.</b> Independent Contractor will comply
                in all respects with the Rules of Engagement set forth in
                Schedule 2.
              </li>
              <li className="agreement-listing">
                <b>Use of Marketing Tactics.</b> All of Independent Contractor’s
                Marketing Tactics related to selling under this Agreement,
                including, but not limited to, the use of Advertisements in
                Print, on website(s) and Banner Ads, must be pre-approved in
                writing by Comlink, without exception. Independent Contractor
                acknowledges that Comlink’s ability to continue offering its
                services to Comlink Service Providers is subject to and
                conditioned upon Comlink following the brand guidelines are
                subject to rules of engagement of Comlink Service Providers.
                Accordingly, a material, critical and ongoing condition of this
                Agreement is the compliance with the Rules of Engagement and the
                pre-approval of Independent Contractor’s Marketing Tactics by
                Comlink in accordance with the provisions of this Agreement. In
                the event of a failure to comply with this provision, Comlink
                may, inter alia, immediately suspend this Agreement, terminate
                the Agreement and pursue all legal remedies including injunction
                and damages, and all other remedies available under this
                Agreement. Comlink may, in its reasonable discretion, determine
                when a violation of this provision has occurred. Upon
                notification of a violation of this provision, Independent
                Contractor agrees to immediately suspend the offending Marketing
                Tactic until the parties have resolved the issue to Comlink
                reasonable satisfaction.
              </li>
              <li className="agreement-listing">
                <b>
                  Independent Contractor understands and agrees that the
                  requirement to comply in all respects with the Rules of
                  Engagement and to obtain pre-approval of all Marketing Tactics
                  are material terms of this Agreement, the individual breach of
                  which will give rise to Comlink’s immediate right to terminate
                  this Agreement and the forfeiture of Commission Fees, as
                  further provided in Section 6 (Term and Termination), without
                  prejudice to any other rights and obligations of COMLINK for
                  breach of this provision.
                </b>
              </li>
              <li className="agreement-listing">
                <b>
                  Independent Contractor are not permitted to outbound dial or
                  Robodial residential customers. Residential customers can only
                  be contacted via phone, sms messaging or other forms of
                  electronic communication after they have filled out a Comlink
                  approved opt-in form which gives contractor permission. The
                  contractor also needs to scrub their internal Do Not Call List
                  before contacting said customer.
                </b>
              </li>
            </ol>
            <p className="agreement-listing">
              <b>Commissions.</b>
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                <b>Restrictions on Commissions.</b>
                <ol>
                  <li className="agreement-listing">
                    Independent Contractor will not hold itself out as a Comlink
                    Service Provider or Independent Contractor of a Comlink
                    Service Provider except in accordance with Marketing Tactics
                    approved by Comlink in writing.
                  </li>
                  <li className="agreement-listing">
                    Independent Contractor will not make any representations
                    regarding the Comlink Services or the services of any
                    Comlink Service Provider, other than those expressly
                    approved in writing or distributed by Comlink or the
                    applicable Comlink Service Provider.
                  </li>
                </ol>
              </li>
              <li className="agreement-listing">
                <b>Integration and User Information.</b>
                Beginning from the Effective Date, Independent Contractor will,
                subject to applicable laws, privacy policies and contractual and
                other obligations, disclose to Comlink certain identifying
                information (<b>User Information</b>) it obtains from each
                Independent Contractor Customer that is a Commission so that the
                Independent Contractor Customer can avoid re-entering
                information that it may already have provided to Independent
                Contractor. Comlink will own all rights in and to such User
                Information. Independent Contractor acknowledges and agrees
                that, except as expressly provided herein, all User Information
                will constitute Confidential Information of Comlink, and will be
                treated as Comlink Confidential Information in accordance with
                the confidentiality provisions of this Agreement. In addition,
                each of Parties agrees not to disclose the User Information in a
                manner that would violate any Applicable Law or regulations,
                including without limitation, anti-spamming laws and regulations
                governing the use of Customer Proprietary Network Information
                (CPNI).
              </li>
            </ol>
            <p className="agreement-listing">
              <b>Commission Fees.</b>COMLINK shall pay the Commission Fees on a
              monthly basis as calculated in accordance with INDEPENDENT
              CONTRACTOR Partnership Rate Card hereto.
            </p>
            <p className="agreement-listing">
              <b>Payment Terms.</b>
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                Comlink will pay all Commission Fees on a weekly or monthly
                basis, in US dollars, within thirty (30) days from the end of
                the month in which an order is installed by Comlink. This can
                vary based on when Comlink receives commission funds from the
                customer or carrier.
              </li>
              <li className="agreement-listing">
                Accompanying all payments, Comlink will provide to Independent
                Contractor a statement itemizing the basis for its calculation
                of the Commission Fees.
              </li>
              <li className="agreement-listing">
                Commission Fee schedules are subject to change at any-time
                without written notice. This disclaimer is being posted do to
                the large amount of carriers we offer and volume fluctuations
                that can affect commissions rates. Please see your Comlink rate
                card for more details.
              </li>
              <li className="agreement-listing">
                If applicable, upon execution of this Agreement, Independent
                Contractor will complete and return IRS form W-9 to Comlink.
              </li>
            </ol>
            <p className="agreement-listing">
              <b>Term and Termination.</b>
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                Unless terminated as provided herein, the term of this Agreement
                will begin on the Effective Date and continue for an initial
                period of one year. Thereafter, the Agreement will continue
                automatically for additional periods of one year, unless either
                Party terminates the Agreement as provided herein.
              </li>
              <li className="agreement-listing">
                Either Party may terminate this Agreement for convenience upon
                thirty (30) days prior written notice.
              </li>
              <li className="agreement-listing">
                Comlink may terminate this Agreement immediately by written
                notice (including email) to Independent Contractor if Comlink
                reasonably determines that Independent Contractor has failed to
                comply in all respects with the Rules of Engagement or is
                engaged in improper or unapproved Marketing Tactics or fraud. In
                such event, Independent Contractor agrees that, amongst any
                other available remedies at law, all Commission Fees owed and
                not yet paid by Comlink shall be forfeited.
              </li>
              <li className="agreement-listing">
                Upon the expiration or termination of this Agreement for any
                reason, each Party will immediately deactivate all phone
                numbers, Order Entry Tools and Links to the other Party’s Site.
                Except as provided in Section 6.c. immediately above, all
                accrued payment obligations of COMLINK to Independent
                Contractor, including those of COMLINK with respect to Qualified
                Calls that have not yet been paid, will survive expiration or
                termination of this Agreement. Any and all other obligations
                under this Agreement which, by their very nature, should
                reasonably survive the termination or expiration of this
                Agreement, will so survive. Each Party will, at the request and
                expense of the other, provide reasonable assistance to effect an
                orderly termination.
              </li>
            </ol>
            <p className="agreement-listing">
              <b>Exclusivity.</b>During the term of this Agreement and for
              thirty (30) days following the termination of this Agreement,
              Independent Contractor will not enter into Commission agreements
              with other third parties to provide services that are the same as
              or substantially similar to the Independent Contractor Services
              that Independent Contractor is providing to Comlink unless Comlink
              provides written approval.
            </p>
            <p className="agreement-listing">
              <b>Tracking.</b>Comlink will use commercially reasonably good
              faith efforts to track all Qualified Calls that generate
              Commission Fees to Independent Contractor and report such
              information as part of the payment process. Independent Contractor
              acknowledges and accepts that the tracking system used by Comlink
              may not be fail-safe and that there may, on occasion, be instances
              of that are not credited to Independent Contractor. Comlink will
              try to avoid and/or correct all such omissions; provided, however,
              that the occasional failure to do so shall not be deemed to be a
              material breach by Comlink of this Agreement.
            </p>
            <p className="agreement-listing">
              <b>Confidentiality Obligations.</b>
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                During the Term of this Agreement and for a period of three (3)
                years thereafter, each Party will hold all Confidential
                Information disclosed by the other Party in confidence, using
                the same degree of care that it uses to protect its own
                Confidential Information of like importance. To the extent that
                any Confidential Information disclosed hereunder consists of
                computer software, application programming interfaces (
                <b>APIs</b>) or similar technology, the receiving Party may only
                use such technology in furtherance of the express purposes of
                this Agreement and may not decompile, disassemble or reverse
                engineer any such Confidential Information.
              </li>
              <li className="agreement-listing">
                Each Party may, however, disclose Confidential Information on a
                “need-to-know” basis under an obligation of confidentiality to
                its employees and professional advisors, provided that such
                employees and advisors comply with the provisions of this
                Section.
              </li>
              <li className="agreement-listing">
                The terms and conditions of this Agreement will be deemed to be
                the Confidential Information of each Party and will not be
                disclosed without the written consent of the other Party.
              </li>
              <li className="agreement-listing">
                The terms and conditions of any Comlink Service Provider
                agreement or similar agreement of Comlink and which is provided
                to Independent Contractor for review, will be deemed to be the
                Confidential Information of Comlink.
              </li>
              <li className="agreement-listing">
                Upon the termination of this Agreement, each Party will return
                to the other all Confidential Information of the other which is
                then in its possession or control, and will remove all digital
                representations thereof in any form from all electronic storage
                media in its possession or under its control.
              </li>
              <li className="agreement-listing">
                Each Party acknowledges that its failure to comply with the
                provisions of this Section may cause irreparable harm to the
                other Party which cannot be adequately compensated for in money,
                and accordingly acknowledges that the other Party will be
                entitled to seek, in addition to any other remedies available to
                it, interlocutory and permanent injunctive relief to restrain
                any anticipated, present or continuing breach of this Section
                without having to post a bond or other security.
              </li>
              <li className="agreement-listing">
                <b>Ownership.</b> All software, application programming
                interfaces, business processes, marketing techniques or
                technology used by a Party to fulfill its obligations hereunder
                shall at all times be, and shall be deemed to be, solely the
                property of such Party, including all patents, copyrights,
                trademarks, trade secrets and other Intellectual Property Rights
                therein. Except as it pertains to the limited rights of one
                Party to use the Marks of another Party and relating to User
                Information, no rights in any intellectual property are conveyed
                in this Agreement.
              </li>
            </ol>
            <p className="agreement-listing">
              <b>Representations and Warranties of the Parties.</b>
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                Each Party represents and warrants to the other Party that:
                <ol>
                  <li className="agreement-listing">
                    it has the full corporate power and authority to enter into
                    this Agreement and to perform its obligations hereunder;
                  </li>
                  <li className="agreement-listing">
                    none of the Intellectual Property Rights licensed or
                    furnished by such Party to the other Party hereunder does
                    not and will not, during the term hereof, infringe or
                    conflict with the Intellectual Property Rights of any third
                    party;
                  </li>
                  <li className="agreement-listing">
                    in performing this Agreement, it will comply in all material
                    respects with all applicable laws and regulations;
                  </li>
                  <li className="agreement-listing">
                    in accessing the other Party’s sites, or utilizing any
                    aspect of the other Party’s services on behalf of itself or
                    a prospective customer, it will do so in accordance with the
                    other Party’s stated privacy and other applicable policies;
                    and
                  </li>
                  <li className="agreement-listing">
                    no website used in this Agreement will be used to
                    disseminate any malicious code, including without limitation
                    viruses, worms, trojans, adware or spyware, or contains or
                    will contain any material that is pornographic, libelous,
                    defamatory, obscene or infringing.
                  </li>
                </ol>
              </li>
              <li className="agreement-listing">
                <b>
                  EXCEPT FOR THE REPRESENTATIONS AND WARRANTIES EXPRESSLY SET
                  FORTH IN THIS SECTION, EACH PARTY DISCLAIMS ALL OTHER
                  WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
                  ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                  PURPOSE. NEITHER PARTY WARRANTS THAT ITS WEBSITE OR SERVICES
                  WILL OPERATE ERROR-FREE OR WITHOUT INTERRUPTION.
                </b>
              </li>
            </ol>
            <p className="agreement-listing">
              <b>Indemnities.</b>
              Each Party agrees to defend, indemnify and hold the other Party
              and its directors, officers, employees and contractors (including
              Comlink Service Providers) harmless from and against all third
              party claims, losses, costs, damages, expenses and liabilities,
              including court costs and reasonable legal fees brought by a third
              party (collectively “Claims”) arising from or in connection with,
              or based on allegations: arising out of any material breach or
              non-performance of any provision of this Agreement or personal
              injury or damage to property caused by such Party or its employees
              or contractors in connection with its performance of this
              Agreement. The indemnified Party shall promptly notify the
              indemnifying Party of any Claims, and the indemnifying Party shall
              have the right to defend such Claim with counsel of its choosing.
              At the request of an indemnifying Party, the indemnified Party
              shall reasonably cooperate in the defense and settlement of such
              Claim at the indemnifying Party’s expense.
            </p>
            <p className="agreement-listing">
              <b>
                Limitation of Liability. Neither Party shall be liable to the
                other Party hereunder for any special, consequential, indirect,
                incidental, exemplary or punitive damages or loss of profit,
                whether in contract, tort or otherwise, even if such Party has
                been advised of the possibility of such damages. Except with
                regard to violations by Independent Contractor of the Rules of
                Engagement, the Warranties and as set forth in Article II.C,
                each Party’s liability to the other Party hereunder will not
                exceed the amounts paid to Independent Contractor over an
                immediately preceding twelve (12) month period, calculated as of
                the date that the default or indemnification obligation arose.
              </b>
            </p>
            <p className="agreement-listing">
              <b>Independent Contractor Status of Parties.</b>
              Nothing in the Agreement shall be deemed to constitute a
              partnership or joint venture between Independent Contractor and
              Comlink. No Party or their respective employees, agents,
              customers, or subcontractors shall be deemed an employee, agent,
              customer, or subcontractor of the other Party by virtue of the
              performance of the services set forth in this Agreement. Under no
              circumstances shall a Party’s employees, agents, customers, or
              subcontractors, be entitled to any benefits which the other Party
              may offer to its own employees, agents, customers, or
              subcontractors from time to time.
            </p>
            <p className="agreement-listing">
              <b>Notices.</b>
              All notices and other correspondence to be served under this
              Agreement, whether as notice of process or otherwise, shall be
              made in writing and shall be sufficiently be deemed given on the
              date received by:
              <ol className="alpha-listing">
                <li className="agreement-listing">
                  certified mail (postpaid and return receipt requested), or{" "}
                </li>
                <li className="agreement-listing">
                  Federal Express or comparable overnight courier of national
                  reputation with proof of receipt.
                </li>
              </ol>
            </p>
            <p className="agreement-listing">
              Any required notices or correspondence shall be sent to:
            </p>
            <div className="form--fileds ms-5">
              <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-6" for="exampleInputEmail1">
                      Matthew Santini, CEO
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      Comlink Total Solutions Corp.
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      2891 Center Pointe DR
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">STE 203 </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      Fort Myers, Florida 33916
                    </label>
                  </div>
                </div>
                <p className="mt-4">With a copy to:</p>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Absolute Law, PA </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      12585 New Brittany Blvd.
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      Fort Myers, Florida 33907
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <p className="agreement-listing">
              -----------------------------------------------------------------------------------------------
            </p>
            <p className="agreement-listing">
              <b>Publicity.</b>
              Each Party hereto has the right periodically to publicize the
              relationship set forth in this Agreement, provided the publicity
              has been submitted to and approved by the other Party prior to
              disclosure, such approval not to be unreasonably withheld or
              delayed. The Parties will agree on the timing and content of a
              press release for release promptly upon signing of this Agreement,
              announcing the Parties’ relationship.
            </p>
            <p className="agreement-listing">
              <b>Miscellaneous Provisions.</b>
              <ol className="alpha-listing">
                <li className="agreement-listing">
                  The failure of either Party to exercise any right provided for
                  herein shall not be deemed to be a waiver of any other right
                  hereunder. No waiver of any right or breach of this Agreement
                  shall be deemed to be a waiver of any subsequent exercise of a
                  right or breach of this Agreement. No waiver of any right of
                  obligation in this Agreement may be made except through a
                  writing signed by the waiving Party.
                </li>
                <li className="agreement-listing">
                  Neither Party shall be liable to the other for any loss or
                  damage which may be suffered by the other due to any cause
                  beyond the Party’s reasonable control including, but not
                  limited to, any failure of third-party software, telephone or
                  database systems, act of God, terrorism, inclement weather,
                  failure or shortage of power supplies, power outages, flood,
                  drought, lightning or fire, strike, lock-out, trade dispute or
                  labor disturbance, the act or omission of government,
                  telecommunications operators or administrators or other
                  competent authorities, war, military operation, or difficulty,
                  delay or failure in manufacture, production or supply by third
                  parties of materials or equipment necessary to carry out this
                  Agreement.
                </li>
                <li className="agreement-listing">
                  This Agreement shall be governed by and construed in
                  accordance with the laws of the Delaware without regard to its
                  conflict-of-law rules. The Parties hereby consent to the
                  exclusive jurisdiction of the state or federal courts located
                  in Washington DC for resolution of disputes arising out of
                  this Agreement.
                </li>
                <li className="agreement-listing">
                  If any term of this Agreement is or becomes illegal, invalid
                  or unenforceable in any jurisdiction, that will not affect:
                  (a) the legality, validity or enforceability in that
                  jurisdiction of any other term of this Agreement; or (b) the
                  legality, validity or enforceability in other jurisdictions of
                  that or any other provision of this Agreement.
                </li>
                <li className="agreement-listing">
                  This Agreement and the schedules annexed hereto constitute the
                  entire Agreement between the Parties regarding the subject
                  matter hereunder and supersede all prior and contemporaneous
                  representations, proposals, discussions, and communications,
                  whether made orally or in writing. This Agreement may be
                  modified or amended only in writing signed by each of the
                  Parties.
                </li>
                <li className="agreement-listing">
                  A person who is not a party to this Agreement shall not have
                  any third party rights under this Agreement.
                </li>
                <li className="agreement-listing">
                  This Agreement may be executed and delivered in any number of
                  counterparts by facsimile or electronic mail, all of which
                  taken together will constitute one and the same agreement.
                </li>
              </ol>
            </p>
            <h5 className="d-flex justify-content-center py-2">SCHEDULE 2A</h5>
            <h6 className="py-2 px-5">RULES OFENGAGEMENT</h6>
            <p className="agreement-listing">
              In order to maintain consistency and to honor Comlink’s
              relationships with all of the Comlink Service Providers, it is
              imperative that Comlink accurately represent brands and products
              in accordance with contractual obligations and not engage in any
              actions or omissions that will cause Comlink to be in breach of
              its agreements with the Comlink Providers. These requirements must
              be honored by Independent Contractor, whether Independent
              Contractor is directly or indirectly involved in the offering of
              the products and services of any Comlink Service Provider under
              this Agreement. Comlink may unilaterally update these Rules of
              Engagement from time-to-time to, among other things; remain
              compliant with its agreements with the Comlink Service Providers.
              In the event that Independent Contractor does not agree to any
              amendment of this Schedule 2, it shall immediately cease marketing
              and terminate any campaigns as to the relevant Comlink Service
              Provider.
            </p>
            <ol>
              <li className="agreement-listing">
                <b>Spam/Unsolicited Commercial Email (UCE).</b> Independent
                Contractor may not participate in mass, unsolicited emailing
                (i.e., spamming).
              </li>
              <li className="agreement-listing">
                <b>Spyware and Other Parasitic Software.</b> Spyware and Other
                Parasitic Software.
              </li>
              <li className="agreement-listing">
                <b>Website Restrictions.</b> ndependent Contractor is prohibited
                from including content on any of the Independent Contractor
                Sites that is reasonably determined, in Comlink’s sole
                discretion to contain any of the following:
                <ol className="alpha-listing">
                  <li className="agreement-listing">
                    X-rated or sexually explicit materials;
                  </li>
                  <li className="agreement-listing">Violence or discord;</li>
                  <li className="agreement-listing">
                    Discrimination based on race, sex, religion, nationality,
                    disability, sexual orientation, or age;
                  </li>
                  <li className="agreement-listing">
                    Illegal, morally questionable or controversial activities;
                  </li>
                  <li className="agreement-listing">
                    Violation of intellectual property rights.
                  </li>
                </ol>
              </li>
              <li className="agreement-listing">
                <b>Behavior/Conduct.</b> Independent Contractors are to conduct
                themselves professionally and respectfully when dealing with
                potential customers, Comlink employees, Comlink Service Provider
                or any group or individual associated or Independent Contractord
                Comlink. Abusive and/or unprofessional behavior is prohibited.
              </li>
              <li className="agreement-listing">
                <b>Domain Masking.</b> Independent Contractor is prohibited from
                hiding the actual domain name of any Independent Contractor Site
                from the URL field of a user’s web browser in favor of another
                name. For Comcast, Independent Contractor may not use a Comcast
                branded term in the Comcast table below in any URL without the
                express written permission of Comcast and Comlink.
              </li>
              <li className="agreement-listing">
                <b>Branded Search.</b> Independent Contractor shall not use any
                Comlink Service Provider name or trademark, including a
                trademark of a company Independent Contractord with a Comlink
                Service Provider, in any Advertisement, including paid search.
              </li>
            </ol>
            <h4 className="d-flex justify-content-center py-2">
              COMLINK’S DO NOT CALL and Telemarketing Policy
            </h4>
            <h6 className="py-2 px-5">Telemarketing</h6>
            <p className="agreement-listing">
              The federal Telephone Consumer Protection Act, 47 U.S.C. § 227 et.
              seq., (“TCPA”) places restrictions on the use of telephone
              equipment to market or promote products and services. Numerous
              states have adopted statutes modeled after or more restrictive
              than the TCPA, each with its own penalty scheme. Another related
              and significant regulatory regime in this area is the
              Telemarketing and Consumer Fraud Prevention Act, 15 U.S.C. § 6101
              et. seq., as implemented by the Federal Trade Commission in the
              Telemarketing Sales Rule, 16 CFR Part 310 (“TSR”). In 2003, the
              FTC and FCC established and began enforcement of a National Do Not
              Call Registry. The FCC has also implemented stricter rules for
              enforcing the TCPA which began to take effect in 2012. Many states
              also have their own Do Not Call Registries, as well as
              telemarketer registration and bonding requirements. They also
              often impose stricter requirements than the federal law. In many
              instances these state laws apply to all forms of outbound and
              inbound telemarketing. Thus, even if you are otherwise making a
              permitted call, you may still be required to register and/or post
              a bond in certain states prior to placing or receiving a
              telemarketing call.
            </p>
            <p className="agreement-listing">
              The potential penalties for violating these laws are serious. For
              example, the government can impose $16,000.00 in penalties “for
              each such violation” of the TCPA or the Telemarketing Sales Rule.
              In addition, under the TCPA, consumers can bring private rights of
              action to seek the greater of actual damages or $500, which can be
              trebled to $1500 by the Court if the conduct is deemed willful.
              Because of the myriad of laws making compliance difficult, as well
              as the penalties and loss of reputation and goodwill associated
              with non-compliance, COMLINK does not provide discretionary
              marketing funds for use in connection with outbound telemarketing
              solicitations.
            </p>
            <p className="agreement-listing">
              {" "}
              Following are COMLINK ’s specific policies and guidelines
              regarding particular forms of outbound telemarketing:
            </p>
            <ol className="alpha-listing">
              <li className="agreement-listing">
                <i>Facsimile advertising.</i>
                <p className="agreement-listing">
                  Facsimile advertising is a form of outbound telemarketing
                  solicitation that is expressly disapproved of for ANY use in
                  advertising “COMLINK ” branded products and services. In
                  addition, it is considered a violation of this Policy
                  Statement for any Employee to use facsimile advertising in
                  connection with Dish Network or any “satellite television”
                  product if such advertisement is likely to lead to consumer
                  confusion and the mistaken belief that such advertising
                  relates to COMLINK - brand products or services.
                </p>
              </li>
              <li className="agreement-listing">
                <i>Pre-recorded messages.</i>
                <p className="agreement-listing">
                  Pre-recorded message advertising is also a form of outbound
                  telemarketing solicitation that is expressly disapproved of
                  for ANY use in advertising “COMLINK ” branded products and
                  services. In addition, it is considered a violation of this
                  Policy Statement for any Employee to use pre- recorded message
                  advertising in connection with any Comlink’s Major Telecom
                  Partners example: DIRECTV or any “satellite or cable
                  television” product if such advertisement is likely to lead to
                  consumer confusion and the mistaken belief that such
                  advertising relates to COMLINK-brand products or Comlinks
                  Major telecom carrier partner services.
                </p>
              </li>
              <li className="agreement-listing">
                <i>Text or SMS messages.</i>
                <p className="agreement-listing">
                  Several courts have determined that text or SMS messages are
                  legally the same as phone calls or pre-recorded messages.
                  Therefore, text message advertising is also a form of outbound
                  telemarketing solicitation that is expressly disapproved of
                  for ANY use in advertising “COMLINK ” branded products and
                  services. In addition, it is considered a violation of this
                  Policy Statement for any Employee to use text message
                  advertising in connection with Dish Network or any “satellite
                  television” product if such advertisement is likely to lead to
                  consumer confusion and the mistaken belief that such
                  advertising relates to COMLINK -brand products or services.
                </p>
              </li>
              <li className="agreement-listing">
                <i>
                  Outbound unsolicited telephone calls by live operators (“cold
                  calling”).
                </i>
                <p className="agreement-listing">
                  Using live operators (with or without using dialing equipment)
                  to place unsolicited (no applicable existing business
                  relationship or qualifying inquiry) outbound telemarketing
                  calls, sometimes also referred to as “cold calls,” expressly
                  disapproved of for ANY use in advertising “COMLINK ” branded
                  products and services. In addition, it is considered a
                  violation of this Policy Statement for any Employee to place
                  such calls in connection with Dish Network or any “satellite
                  television” product if such advertisement is likely to lead to
                  consumer confusion and the mistaken belief that such
                  advertising relates to COMLINK -brand products or services.
                </p>
              </li>
              <li className="agreement-listing">
                <i>
                  Returning Calls to Prospective Customers who have made a
                  “qualified” inquiry, and calls to existing or former customers
                </i>
              </li>

              <p className="agreement-listing">
                Inquiries from prospective customers. Employees may generally
                make or return telephone calls to prospective customers who
                initiate contact with them to inquire about COMLINK products and
                services, notwithstanding the consumer’s registration in the
                federal Do Not Call registry or similar state registries.
                Current federal law permits an outbound telemarketing call to a
                consumer if the consumer has made an inquiry within the past 90
                days regarding a product or service offered by the Employee,
                unless the consumer has previously asked to be placed on the
                Employee’s internal Do Not Call list. Some state laws do impose
                more restrictive time frames, require registration and/or
                bonding requirements or even prohibit return calls to consumers
                on their state Do Not Call registries altogether, so obtaining
                advice of counsel regarding the laws in the jurisdictions within
                which you plan to operate is imperative. Moreover, in recent
                years, states have become increasingly active in restricting the
                scope of traditional EBR calls (e.g. New York). You must review
                any state’s telemarketing laws before you choose to place any
                type of telemarketing call into a state.
              </p>
              <p className="agreement-listing">
                You must also have a written Do Not Call policy, train your
                operators on the policy, enforce compliance with the policy,
                provide it upon a consumer request, and timely honor all
                requests to be placed on your internal Do Not Call list. You
                must scrub against your internal list (and more restrictive
                state lists) before making return calls to consumers who have
                made an inquiry.
              </p>
              <p className="agreement-listing">
                Typically, in order to qualify for an “inquiry” exemption to
                Do-Not-Call registries, the inquiry by the consumer must be made
                to you, the actual Employee, and not to a third party marketer
                or lead generator (unless that generator clearly and
                conspicuously identifies you and that it is acting on your
                behalf). The inquiry must also be specific to COMLINK products
                or services, not simply, for example, a general consent to
                receive more information about “electronic products” or
                “satellite systems.”
              </p>
              <p className="agreement-listing">
                <b>
                  <i>
                    COMLINK ’s policy requires that return calls be made by a
                    live operator – no pre-recorded messages, including “Press
                    1” or other approaches should be used. Any other method
                    could result in violation of the law.
                  </i>
                </b>
              </p>
              <p className="agreement-listing">
                <b>
                  <i>
                    In addition, the FCC’s stricter rules under the TCPA
                    announced in 2012 do not allow telemarketing calls to be
                    made using automatic dialing equipment unless the marketer
                    has proof of the consumer’s prior express consent to receive
                    calls. In the case of calls to cellular phones, the proof of
                    consent must be written (requiring written or ESIGN consent
                    proof). Therefore, return calls should not only be made by
                    live operator – no pre-recorded messages – but best
                    practices suggest having operators manually dial to return
                    calls in order to avoid potential problems in proving
                    consent.
                  </i>
                </b>
              </p>
              <p className="agreement-listing">
                <b>
                  <i>
                    If automatic dialing equipment is to be used to return
                    customer inquiries, special steps must be taken to avoid
                    violations. First, return calls should not be made with
                    automatic dialing equipment to any number simply “captured”
                    through a caller ID or other system. Return calls should
                    only be placed to consumers who talked to an agent and
                    provided consent to future calls. Consent must be clearly
                    and expressly given (e.g., “may I call you back at this
                    number --- --- ----?” and/or “would you like to provide a
                    number so I may call you back?”). If the consumer says no or
                    refuses to provide a number, the consumer may not be called
                    back using automatic dialing equipment. If the agent is
                    unable to ask for consent (e.g., the caller hangs up or the
                    call drops), the consumer may not be called using automatic
                    dialing equipment. Second, because using automatic dialing
                    equipment to make telemarketing calls to cellular phones
                    requires consent to be written, calls in which consent is
                    obtained must be recorded and stored to maintain ESIGN proof
                    of consent. Either all calls need to be recorded and
                    preserved, or, policies and procedures could be developed
                    for identifying cellular telephones (including asking the
                    consumer if the number they are providing is a cellular
                    number) and recording and maintaining written consents only
                    insofar as cellular phones are concerned. Finally, all call
                    abandonment rules must be adhered to, including the new
                    FCC’s 2012 rule change requiring abandoned call messages to
                    include an interactive opt out for DNC requests..
                  </i>
                </b>
              </p>
              <p className="agreement-listing">
                {" "}
                There are several key aspects to remember when claiming
                “inquiry” call exemptions to the TSR or state laws:
              </p>
              <ul>
                <li className="agreement-listing">
                  Time frames in which calls can be returned must be strictly
                  obeyed, both in terms of overall call return period (as noted,
                  federal law permits return calls within 90 days of inquiry,
                  but some state laws are more restrictive) and hour, day of
                  week and holiday restrictions (e.g., no calls before 8 AM or
                  on holidays). In addition, some states impose registration and
                  bonding requirements which may apply to all types of
                  telemarketing calls, including calls to consumers who have
                  made an inquiry, and to inbound calls. You must consult with
                  legal counsel to ensure compliance in every state to which you
                  return or place calls to inquiring consumers.
                </li>
                <li className="agreement-listing">
                  Consumer inquiries to third parties do not create an exemption
                  unless, in the process of obtaining the lead, the Independent
                  Contractor clearly and conspicuously discloses that the
                  consumer will receive a call from you, the actual
                  Employee/seller.
                </li>
                <li className="agreement-listing">
                  That is true even as to subsidiaries and Independent
                  Contractord companies. Consumer inquiries to your subsidiary
                  or Independent Contractor do not provide you an exemption to
                  call the consumer back, unless, the consumer would reasonably
                  expect you to respond to the inquiry. Similarly, your
                  subsidiaries and Independent Contractors cannot return calls
                  to consumers who make an inquiry to you, unless, the consumer
                  would reasonably expect the subsidiary or Independent
                  Contractor to call. And finally, such permissible return calls
                  must involve the product the consumer originally inquired
                  about.
                </li>
                <li className="agreement-listing">
                  <b>Remember:</b> COMLINK ’s approval is required if you plan
                  on using a third party to generate leads, and under no
                  circumstance should you call a consumer whose name and phone
                  number was provided by a lead generator unless the consumer is
                  aware of your identity and you can prove the consumer
                  consented to receiving a call from you, as the actual
                  Employee/seller, about COMLINK products or services.
                </li>
                <li className="agreement-listing">
                  The burden is on you to maintain records and documents to
                  “prove that an inquiry was made by the consumer” and to the
                  extent autodialing equipment is used, the appropriate consent
                  was obtained, including proof of written/ESIGN consent for
                  returning calls to cellular phones. This is not only a COMLINK
                  policy requirement, but a legal requirement. You should never
                  place a return call to a consumer unless you have proof that
                  the consumer made an inquiry and consented to receiving calls
                  from you. Relying on capturing a call number through caller –
                  ID technology is not sufficient. You must provide such proof
                  upon the request of COMLINK or any regulatory enforcement
                  agency.
                </li>
                <li className="agreement-listing">
                  Calls made to a consumer where there is an existing prior
                  business relationship which includes a financial transaction.
                  Under federal law, you may also call consumers who have
                  engaged in a financial transaction with you within the past 18
                  months prior to the telemarketing call. Again, however,
                  several states have stricter laws and it is incumbent upon you
                  to consult with counsel to ensure compliance in every
                  jurisdiction in which you plan to make such calls, as every
                  year more states are passing more restrictive laws. Further,
                  as with the consumer inquiry exemption described above, the
                  following restrictions apply:
                  <ul>
                    <li className="agreement-listing">
                      This exemption does not apply if the consumer has asked to
                      be placed on your internal Do Not Call list.
                    </li>
                    <li className="agreement-listing">
                      This exemption does not apply to your Independent
                      Contractors or subsidiaries, as described above.
                    </li>
                    <li className="agreement-listing">
                      The burden is on you to maintain documents and records in
                      order to establish that the exemption exists. These
                      documents proving the relationship must be produced upon
                      the request of COMLINK or any regulatory enforcement
                      agency.
                    </li>
                    <li className="agreement-listing">
                      Some state laws are more restrictive, so you must consult
                      with local counsel to determine whether or not existing
                      business relationship telemarketing calls can be made to
                      consumers in particular states.
                    </li>
                  </ul>
                </li>
              </ul>
              <li className="agreement-listing">
                <i className="agreement-listing">
                  General Telemarketing Requirements
                </i>
                <p className="agreement-listing">
                  In addition to the national database Do Not Call provisions,
                  the Telemarketing Sales Rule also sets forth significant
                  requirements which must be complied with when engaging in any
                  type of telemarketing (including calls made in response to a
                  consumer’s inquiry or to customers with whom you have an
                  existing business relationship). Similar requirements also
                  exist at the state level. At a minimum, Employees engaging in
                  any type of telemarketing sales call must comply with the
                  following requirements:
                </p>
                <p className="agreement-listing">
                  Immediate disclosures. You must immediately and promptly
                  disclose at the beginning of the call your identity, the
                  purpose of the call, the nature of products being sold and
                  certain other disclosures.
                </p>
                <ul>
                  <li className="agreement-listing">
                    Calling time restrictions – you can generally only make
                    calls between 8:00 a.m. and 9:00 p.m. in the consumer’s time
                    zone. Some states have more restrictive time restrictions.
                  </li>
                  <li className="agreement-listing">
                    Maintenance of internal Do Not Call policies – all Employees
                    or Contractors who call consumers must maintain a written Do
                    Not Call policy, train all employees about that policy and
                    police for compliance. Further, upon request, a copy of the
                    written policy must be sent to any consumer requesting the
                    same. Lastly, you must maintain and scrub against your
                    internal Do Not Call list and place consumers on that list
                    immediately after receiving the request.
                  </li>
                  <li className="agreement-listing">
                    Additional disclosures. Prior to the conclusion of the call,
                    you must disclose the total cost including shipping and
                    handling charges of any product purchased, all material
                    conditions of the sale including any material limitations on
                    free equipment or installation offers (such as any COMLINK
                    requirement to maintain a certain level of service for a
                    specified period of time) and certain additional disclosures
                    regarding any prize promotions.
                  </li>
                  <li className="agreement-listing">
                    Record Keeping Requirements. In addition to maintaining
                    records concerning any claimed exemption from a Do Not Call
                    registry, you are also required to maintain other records
                    concerning the telemarketing sale of products or services,
                    including copies of all advertisements and promotional
                    materials, sales and prize records, and certain contact
                    information concerning telemarketing employees.
                  </li>
                  <li className="agreement-listing">
                    Bonding and Registration Requirements: Some states require
                    telemarketers to be registered and/or provide a bond, even
                    for inbound calls in some states. You must consult with
                    counsel to determine whether or not these requirements are
                    triggered by your intended activities.
                  </li>
                  <li className="agreement-listing">
                    Miscellaneous requirements: You may not engage in threats of
                    intimidation, repetitive and annoying calls, or make false
                    and misleading statements.
                  </li>
                </ul>
              </li>
            </ol>

            <ol class="alpha-listing">
              <li class="agreement-listing">
                <p>
                  <b>
                    <i>No Third Party Solicitors/Marketing Agents</i>
                  </b>
                </p>
                Your Agreement with COMLINK does not allow the use of third
                parties to solicit sales absent express written approval of
                COMLINK . Approval must be given by both the Regional Vice
                President/Senior Director and Senior Vice President – Sales. No
                other employee is authorized to provide approval, written or
                otherwise. Thus, you are not authorized to use any agent,
                independent contractor or any other third party to conduct
                marketing campaigns as addressed in this Policy Statement. In
                the event that COMLINK suspects you are using a third party to
                telemarket, email, text, conduct home solicitations, or any
                other similar form of marketing, COMLINK may immediately
                terminate your Agreement.
              </li>
              <li class="agreement-listing">
                <p>
                  <b>
                    <i>Internet Marketing</i>
                  </b>
                </p>
                E-Mail
                <p>
                  A federal law (The Can Spam Act), effective January 1, 2004,
                  places numerous restrictions on e-mail marketing messages that
                  companies may send to users. The Can Spam Act creates tough
                  penalties such as criminal sanctions with up to 5-year jail
                  sentences and fines including statutory damages of up to $2
                  million per incident (trebled to $6 million for knowing
                  violations). The law prohibits deceptive practices that
                  mislead consumers, such as using misleading subject lines or
                  headers, masking the marketer’s identity in the reply address,
                  or falsifying registration information. This federal law
                  pre-empts most state laws related to e-mail regulation, but
                  you need to check with counsel to ensure no additional state
                  requirements apply.
                  <br></br>
                  COMLINK does not engage in nor does it condone illegal e-mail
                  marketing, sometimes referred to as “spamming.” Accordingly,
                  COMLINK will not tolerate spamming from Employees. Your
                  Agreement requires you to comply with all applicable laws, and
                  also requires that in all of your activities as an independent
                  contractor for COMLINK , as well as in your separate equipment
                  business, you engage in no practice which impugns COMLINK ’s
                  commercial reputation and goodwill. Spamming not only may
                  result in the violation of laws, but also reflects poorly on
                  the COMLINK -brand name.
                  <br></br>
                  If you choose to market via e-mail, you are responsible for
                  adopting and adhering to policies and procedures that will
                  prevent illegal spamming. You need to speak with your legal
                  counsel to ensure compliance with the law, but at a minimum,
                  your policies should address the following: E-mail messages
                  containing advertisements are required to include a clear and
                  conspicuous identification that the message is an
                  advertisement or solicitation. Further, the law prohibits
                  misleading practices such as using misleading subject lines or
                  headers, masking the marketer’s identity in the reply address,
                  or falsifying registration information. Messages must also
                  include a clear and conspicuous notice that recipients can
                  “opt-out” of receiving future messages, and the message itself
                  must include an immediate opt-out mechanism – either a
                  functioning return address or an automated opt-out method. The
                  opt-out mechanism must work for at least thirty (30) days
                  after the e-mail was sent. The sender has ten (10) days to
                  remove an opt-out from its marketing list. Messages must
                  further contain a valid physical postal address. Harvesting of
                  e-mail addresses on the Internet or randomly generating
                  electronic mail addresses by computer is strictly prohibited.
                  Harvesting activities constitute aggravated violations which
                  may result in trebled fines.
                  <br></br>
                  Some prohibitions (not any related to deceptive practices) may
                  not apply if the advertiser has the recipient’s express
                  affirmative consent to receive e-mail advertisements. However,
                  consent can only be demonstrated where the recipient expressly
                  consented to receive e-mail advertisements from that
                  advertiser either in response to a clear and conspicuous
                  request for consent or at the recipient's own initiative.
                  Finally, e-mail messages sent to facilitate, complete or
                  confirm a commercial transaction are exempt. This includes
                  messages that reflect account statements, change of status or
                  terms, product updates and upgrades, warranty information,
                  safety or security information, subscriptions, memberships and
                  other similar commercial relationships. However, this
                  exemption does not provide marketers with a broad “existing
                  business relationship” exemption. Thus, before undertaking any
                  campaign, you should consult with your legal counsel to ensure
                  compliance with all new developments in the law.
                  <br></br>
                  COMLINK expects you to keep, maintain and utilize the required
                  “opt-out” list to prevent sending messages to consumers who
                  have expressed a desire not to be contacted via e-mail. You
                  must have in place, and train all employees involved in this
                  marketing activity on, written policies and procedures to
                  ensure that all requests – including any that may be made to
                  your postal address – are timely addressed and honored. These
                  written policies and procedures must be made available to
                  COMLINK upon request.
                  <br></br>
                  To the extent you claim exemption from the law due to
                  affirmative consent or completion or confirmation of a
                  commercial transaction, you must preserve and maintain proof
                  of such exemption status. Such proof must be made available to
                  COMLINK upon request.
                  <br></br>
                  Failure to comply or produce materials to COMLINK upon its
                  request may result in termination of your Agreement.
                </p>
              </li>
              <li class="agreement-listing">
                <p>
                  <b>
                    <i>Banner Ads and Other Website Marketing</i>
                  </b>
                </p>
                State, federal, and foreign laws generally related to
                advertising apply in both the online and off- line environments.
                Accordingly, when using or operating banner ads or otherwise
                advertising on websites or through other Internet channels, you
                should consult with your legal counsel to ensure compliance with
                all such laws. This includes ensuring that the content of any
                banner ads or other advertisements contain no misrepresentations
                or misleading statements about COMLINK -branded products or
                services, and that all material information is clearly and
                conspicuously disclosed to consumers, including a clear and
                conspicuous disclosure in close proximity to any offer of the
                material limitations and requirements associated with such
                offer. In March 2013, the FTC released staff guidance entitled “
                .com Disclosures: How to Make Effective Disclosures in Digital
                Advertising.” This guidance takes into account the growing use
                of small screen devices by consumers, and provides examples of
                advertising that may be problematic, so should be reviewed by
                you and your counsel.
                <br></br>
                Your legal counsel can provide information and advice about
                other required disclosures in the on-line environment, but at a
                minimum, your on-line marketing should clearly and conspicuously
                provide your identity and contact information (to avoid
                confusion that the advertising was placed by COMLINK ), the
                nature of the products and services being offered, all material
                terms associated with that offer, and the types of information
                (including personally identifiable information) that you collect
                from and about consumers.
                <br></br>
                With regard to the collection of information, you should be
                certain to disclose the types of information collected passively
                from the consumer, such as through cookies, Internet Protocol
                (“IP”) addresses, web bugs, and other passive collection
                devices. Employees should also fully disclose to consumers
                information about any third parties that help support, or
                otherwise obtain information from, the Employee’s Internet
                marketing activities (such as DoubleClick, Coremetrics, etc.).
                You should realize that the failure to fully disclose and
                otherwise properly handle information collection, use, and
                disclosure might violate any number of applicable state,
                federal, and/or foreign consumer protection and privacy laws.
                COMLINK has the right to immediately terminate its Agreement
                with any Employee that COMLINK believes might have engaged in
                illegal or inappropriate banner or other website advertising.
                <br></br>
                Employees & Contractors must also comply with all applicable
                laws and intellectual property rights when using search engines
                and search engine listings. In particular, Employees must
                observe all such laws and rights protecting COMLINK ’s company
                name, logos and/or trademarks. Employees must not use COMLINK ’s
                marks in their web addresses or metatags, and must identify
                themselves as Employees that sell COMLINK -branded products.
                <br></br>
                Finally, it is important to note that internet marketing cannot
                be used to generate consumer “inquiries” for subsequent
                telemarketing activities unless the e-mail, web-form, etc.,
                clearly and conspicuously identifies your business and you
                obtain the consumer’s demonstrable consent to receive a
                follow-up telephone call from you about COMLINK products or
                services. For more information on this topic, see Section I on
                Telemarketing above.
              </li>
              <li class="agreement-listing">
                <p>
                  <b>
                    <i>Cooling-Off Laws</i>
                  </b>
                </p>
                Federal, state and local jurisdictional laws govern the sale of
                goods or services at a consumer’s place of residence. Under the
                FTC Rule, 17 CFR 429, a buyer in a home solicitation sale must
                be given a “cooling off” period which consists of three business
                days during which he or she may cancel an agreement without
                obligation. The seller may not collect or retain any
                cancellation fee. The Federal Rule defines a door-to-door sale
                as the sale of consumer goods or services in which seller or his
                representative solicits the sale (including those in response to
                buyer invitation) and the buyer’s agreement or offer to purchase
                is made at a place other than the seller’s place of business.
                Many states have similar statutes which address sales that
                result from direct contact by the supplier at a place other than
                the seller’s place of business. It is important to note that
                several state home solicitation statutes also apply to sales
                that are conducted through telemarketing activities. Various
                local ordinances also apply to such sales activities, including
                peddler registration requirements. Similarly, some local
                municipalities prohibit door sales.
                <br></br>
                If you are conducting sales of goods or services which fall
                under these criteria, you should consult your legal counsel
                before undertaking any home solicitation marketing activities.
                At a minimum, in order to comply with the FTC Rule, state
                statutes and local ordinances, you must provide the buyers with
                all required documents and information. You should use a signed
                agreement or offer to purchase form that is signed by the buyer.
                This form should include a statement regarding the buyer’s right
                to cancel. Several states also require that the seller provide a
                Notice of Cancellation form that the buyer can complete and
                submit to the seller in the event he wishes to cancel the
                contract. One example of these forms is provided below; however,
                you must consult your legal counsel to ensure compliance with
                varied federal, state and local laws:
              </li>
            </ol>
            <div class="d-flex justify-content-center py-2 text-center">
              <h5>CONTRACTOR NON-EMPLOYEE AGREEMENT</h5>
            </div>
            <ol>
              <li class="agreement-listing">
                The Contractor is not an employee of Comlink, but is an
                independent contractor.
              </li>
              <li class="agreement-listing">
                The Contractor must comply with all of the rules and regulations
                for Comlink and each of its Telecom partners or subsidiaries.
              </li>
              <li class="agreement-listing">
                For a full list of rules and regulations, see the rules and
                regulations section of the{" "}
                <a href="comlinkpartnerprograml.com">
                  comlinkpartnerprograml.com
                </a>{" "}
                website.
              </li>
              <li class="agreement-listing">
                The Contractor will be compensated for all contracted service
                provider installed sales properly submitted through the Comlink
                portal.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Comlink only compensates contractors for installed sales.
                  </li>
                  <li class="agreement-listing">
                    All independent contractor’s commissions are subject to
                    Comlink receiving funds from the Service provider /carrier
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                See Comlink approved provider list in the Comlink Compensation
                structure for more details.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Once commissions have been received, funds will be dispersed
                    to the Contractor via Direct Deposit on Comlink’s payroll
                    funding date
                  </li>
                  <li class="agreement-listing">
                    See the Comlink compensation guide for full detail on
                    carrier compensation
                  </li>
                  <li class="agreement-listing">
                    All sales are subject to carrier charge back time frames.
                  </li>
                  <li class="agreement-listing">
                    Charge backs are sales that are installed but cancelled
                    within a set period of time by each individual telecom
                    carrier.
                  </li>
                  <li class="agreement-listing">
                    If Charge backs occur said contract will be deducted the
                    charge backed commissions from future commissions or be
                    required to reimburse Comlink for charged back commissions
                    within thirty (30) days of the charge back.
                  </li>
                  <li class="agreement-listing">
                    A terminated contractor’s final commission payments will be
                    held in escrow until the allotted carrier charge back window
                    has expired.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                Once the charge back window expired, any remaining funds will be
                released to the contractor for final payment.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    terminate this agreement if it is determined that the
                    Contractor is:
                  </li>
                  <li class="agreement-listing">
                    committing fraudulent or illegal activity towards new or
                    existing customers or potential customers
                  </li>
                  <li class="agreement-listing">
                    violating any of the rules and regulations for Comlink or
                    its Telecom carrier partners or subsidiaries;
                  </li>
                  <li class="agreement-listing">
                    doing anything considered detrimental to Comlink’s business
                    and reputation.
                  </li>
                  <li class="agreement-listing">
                    withhold and forfeit any commissions from the Contractor if:
                  </li>
                  <li class="agreement-listing">
                    fraudulent or illegal practices, including but not limited
                    to marketing practices and selling practices, were used to
                    obtain those commissions.
                  </li>
                  <li class="agreement-listing">
                    obtained in violation of the rules and regulations for
                    Comlink or any of its Telecom partners or subsidiaries.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                {" "}
                This agreement is subject to change at any time without written
                notice provided by Comlink.
              </li>
            </ol>

            <div class="d-flex justify-content-center py-2 text-center">
              <h5>INTERNATIONAL CONTRACTOR NON-EMPLOYEE AGREEMENT</h5>
            </div>
            <ol>
              <li class="agreement-listing">
                The International Contractor is not an employee of Comlink, but
                is an independent contractor.
              </li>
              <li class="agreement-listing">
                The Contractor must comply with all of the rules and regulations
                for Comlink and each of its Telecom partners or subsidiaries
              </li>
              <li class="agreement-listing">
                For a full list of rules and regulations, see the rules and
                regulations section of the
                <a href="comlinkpartnerprograml.com">
                  comlinkpartnerprograml.com
                </a>
                website.
              </li>
              <li class="agreement-listing">
                The International Contractor will be compensated for all
                contracted service provider installed sales properly submitted
                through the Comlink portal.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Comlink only compensates international contractors for
                    installed sales.
                  </li>
                  <li class="agreement-listing">
                    All international independent contractor’s commissions are
                    subject to Comlink receiving funds from the Service provider
                    /carrier.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                See Comlink approved provider list in the Comlink Compensation
                structure for more details.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Once commissions have been received, funds will be dispersed
                    to the International Contractor via Direct Deposit on
                    Comlink’s payroll funding date.
                  </li>
                  <li class="agreement-listing">
                    See the Comlink compensation guide for full detail on
                    carrier compensation.
                  </li>
                  <li class="agreement-listing">
                    All sales are subject to Comlink carrier charge back time
                    frames.
                  </li>
                  <li class="agreement-listing">
                    Charge backs are sales that are installed but cancelled
                    within a set period of time by each individual telecom
                    carrier.
                  </li>
                  <li class="agreement-listing">
                    If Charge backs occur said contract will be deducted the
                    charge backed commissions from future commissions or be
                    required to reimburse Comlink for charged back commissions
                    within thirty (30) days of the charge back.
                  </li>
                  <li class="agreement-listing">
                    A terminated international contractor’s final commission
                    payments will be held in escrow until the allotted carrier
                    charge back window has expired.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                Once the charge back window expired, any remaining funds will be
                released to the international contractor for final payment.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Comlink has the right to:
                    <br></br>
                    terminate this agreement if it is determined that the
                    International Contractor is:
                    <br></br>
                    committing fraudulent or illegal activity towards new or
                    existing customers or potential customers.
                    <br></br>
                    violating any of the rules and regulations for Comlink or
                    its Telecom carrier partners or subsidiaries;
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                doing anything considered detrimental to Comlink’s business and
                reputation.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    withhold and forfeit any commissions from the Contractor if:
                    <br></br>
                    fraudulent or illegal practices, including but not limited
                    to marketing practices and selling practices, were used to
                    obtain those commissions
                  </li>
                  <li class="agreement-listing">
                    obtained in violation of the rules and regulations for
                    Comlink or any of its Telecom partners or subsidiaries.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                The International Contractor shall open and maintain a bank
                account within the United States or its territories for receipt
                of payment of all commissions during the period of time this
                agreement is in effect.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    The International Contractor shall pay any and all wire
                    transfer fees or other expenses, including, but not limited
                    to any taxes that may be due and owing on amounts received
                    or disbursed.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                This agreement is subject to change at any time without written
                notice provided by Comlink.
              </li>
            </ol>

            <div class="d-flex flex-column mt-2 justify-content-center py-2 text-center">
              <h5>MUTUAL NON-DISCLOSURE AGREEMENT</h5>
              <p>
                The Parties to this Agreement, in consideration of the mutual
                covenants contained herein, hereby agree as follows:
              </p>
            </div>
            <ol>
              <li class="agreement-listing">
                CONFIDENTIAL INFORMATION.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Comlink only compensates international contractors for
                    installed sales.For the purposes of this Agreement,
                    "Confidential Information" will include all information
                    designated as confidential or which could reasonably be
                    assumed to be confidential, including without limitation,
                    any and all present or future product or service
                    information, technical or financial information, business
                    strategies, practices, procedures, customer names or related
                    data, details of any contracts entered into by the parties,
                    advertising and promotional ideas or material, other
                    business information, technical information, documents,
                    drawings, models or inventions that may be disclosed by one
                    party ("Disclosing Party") to the other party ("Recipient")
                    during the term of this Agreement, whether such disclosure
                    is in written, oral, el electronic, website-based or other
                    form.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                TERM.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    This Agreement will commence on the Effective Date and will
                    continue for three (3) years, unless earlier terminated.
                  </li>
                  <li class="agreement-listing">
                    This Agreement may be terminated by either party with thirty
                    (30) days prior written notice to the other.
                  </li>
                  <li class="agreement-listing">
                    The Agreement will automatically renew for another three (3)
                    year term upon expiration of the initial term or the most
                    recent renewal term, unless terminated as set forth in
                    Section 2.b.
                  </li>
                  <li class="agreement-listing">
                    Upon termination, the Recipient will cease all use of the
                    Confidential Information provided by the Disclosing Party
                    and, upon request, will return or destroy such confidential
                    Information
                  </li>
                  <li class="agreement-listing">
                    The terms and conditions of this Agreement will survive
                    termination with respect to Confidential Information
                    disclosed prior to termination.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                PURPOSE
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    The Recipient may use the Confidential Information it
                    receives only for the purpose of exploring a possible
                    mutually agreeable business arrangement between the parties.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                CONFIDENTIALITY.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    The Recipient and its representatives will maintain the
                    secret and confidential nature of the Confidential
                    Information it receives, will permit access only to
                    employees with a need to know, and will prevent access to it
                    by third parties.
                  </li>
                  <li class="agreement-listing">
                    Further, the Recipient will, at a minimum, use the same
                    degree of care that it uses with respect to its own
                    confidential information to prevent its use or disclosure,
                    but in no event will Recipient use less than reasonable
                    care.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                EXCEPTIONS.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    This Agreement imposes no obligation upon the Recipient with
                    respect to the following categories of information
                  </li>
                  <li class="agreement-listing">
                    information that at the time of disclosure to Recipient was
                    in the public domain (other than as a result of any breach
                    of this Agreement);
                  </li>
                  <li class="agreement-listing">
                    information that was known by the Recipient prior to receipt
                    from the Disclosing Party (as proven by Recipient's written
                    records);
                  </li>
                  <li class="agreement-listing">
                    information that, after disclosure to the Recipient, becomes
                    known to the general public through no act or omission of
                    Recipient; or
                  </li>
                  <li class="agreement-listing">
                    information that is disclosed to the Recipient without an
                    obligation of confidentiality by a third party having the
                    legal right to do so (as proven by its written records).
                  </li>
                  <li class="agreement-listing">
                    The Recipient may disclose Confidential Information as
                    required by court order, but only to the extent required by
                    such order; provided, however, that the Recipient provides
                    notice to the Disclosing Party of such requirement to
                    disclose, unless such notice is lawfully prohibited by the
                    requesting governmental entity.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                OWNERSHIP
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    The Recipient acquires no rights under this Agreement to any
                    Confidential Information of the Disclosing Party, and no
                    right under any patent, copyright or trademark is granted by
                    Disclosing Party to Recipient under this Agreement.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                DISCLAIMER
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    THE DISCLOSING PARTY MAKES NO REPRESENTATION OR WARRANTY
                    WITH RESPECT TO THE CONFIDENTIAL INFORMATION AND, IN
                    PARTICULAR WITH RESPECT TO THE NON-INFRINGEMENT OF
                    INTELLECTUAL PROPERTY OR OTHER RIGHTS OF THIRD PARTIES,
                    EXCEPT THE DISCLOSING PARTY DOES REPRESENT THAT IT HAS THE
                    RIGHT TO DISCLOSE THE CONFIDENTIAL INFORMATION TO THE
                    RECIPIENT
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                RELATIONSHIP
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    This Agreement does not create any joint venture,
                    partnership or other fiduciary relationship between the
                    parties
                  </li>
                  <li class="agreement-listing">
                    Neither this Agreement nor the disclosure or receipt of
                    Confidential Information will constitute or imply any
                    promise or intention to make any purchase of products or
                    services by either party or any commitment by either party
                    with respect to the present or future marketing, or use of
                    any product or service of the other, nor will it constitute
                    any promise by either party to have any further transactions
                    whatsoever with the other.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                EQUITABLE RELIEF.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Each party acknowledges that damages for improper disclosure
                    of Confidential Information may be irreparable; therefore,
                    the injured party is entitled to seek equitable relief,
                    including without limitation, injunction and preliminary
                    injunction, in addition to other remedies.
                  </li>
                </ol>
              </li>
              <li class="agreement-listing">
                GENERAL CONDITIONS.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Governing Law. This Agreement will be construed in
                    accordance with Florida law without reference to conflict of
                    laws principles. For any disputes arising out of this
                    Agreement, the parties hereby consent and submit to the
                    exclusive jurisdiction of the federal and state courts
                    sitting in Lee County, Florida.
                  </li>
                  <li class="agreement-listing">
                    Entire Agreement. This Agreement sets forth the entire
                    understanding and agreement between the parties and
                    supersedes all proposals or communications, oral or written,
                    between the parties relating to the subject matter of the
                    Agreement. No modification of the Agreement will be binding
                    unless it is in writing and is signed by authorized
                    representatives of both parties.
                  </li>
                  <li class="agreement-listing">
                    Waiver. No waiver of any right under this Agreement will be
                    deemed effective unless contained in writing signed by a
                    duly authorized representative of the party waiving such
                    right, and no waiver of any past or present right arising
                    from any breach or failure to perform will be deemed to be a
                    waiver of any future right arising under this Agreement
                  </li>
                  <li class="agreement-listing">
                    Severability. If any provision in this Agreement is invalid
                    or unenforceable, that provision will be construed, limited,
                    modified or, if necessary, severed, to the extent necessary,
                    to eliminate its invalidity or unenforceability, and the
                    other provisions of this Agreement will remain in full force
                    and effect.
                  </li>
                  <li class="agreement-listing">
                    Assignment. Neither party may assign this Agreement without
                    the express written consent of the other party, which will
                    not be unreasonably withheld or delayed.
                  </li>
                  <li class="agreement-listing">
                    Notices. Any notice required or permitted to be sent under
                    this Agreement will be delivered by hand, by overnight
                    courier, by facsimile, or by registered mail, return receipt
                    requested, to the address of the parties first set forth in
                    this Agreement or to such other address of the parties
                    designated in writing in accordance with this Section
                  </li>
                  <li class="agreement-listing">
                    Execution in counterparts. This Agreement may be executed in
                    any number of counterparts, each of which will be deemed an
                    original, but such counterparts will together constitute but
                    one and the same document. Facsimile copies of this
                    Agreement are given the dignity of original documents.
                  </li>
                </ol>
              </li>
            </ol>
          </React.Fragment>
        ) : (
          <React.Fragment>
<div>
  
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="1.">
      <p className="agreement-listing">Comlink provides an online Customer Records Management portal to multi-system operators and other telecommunications companies who offer services to end users including services such as video, high speed internet access and telephone together with cross-selling complimentary services (collectively, Comlink and the additional companies offering such services are referred to as ““ <b>Comlink Service Providers</b>””). </p>
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="2.">
      <p className="agreement-listing">Comlink offersonline marketing services through the use of various tactics including, but not limited to: custom websites, lead generation, landing page optimization, targeted advertising, organic search strategies and paid search advertising (the “ <b>Comlink Services</b>”). The Comlink Services provide prospects with available offers from Comlink Service Providers and then attempts to convert the prospect into a customer using Comlink systems including web order, on-line chat and call center assets. Comlink is paid by the customer or the Comlink Service Provider, typically after the prospect who has been converted to a customer by way of an order from the Comlink Service Provider has installed and made an initial payment to the Comlink Service Provider or Comlink directly. </p>
    </li>
    <li className="agreement-listing" data-list-text="3.">
      <p className="agreement-listing">Independent Contractor has the desire and capability to generate leads for the Comlink Service Providers products using Comlink approved marketing tactics, specifically for the products and services of the Comlink Service Providers. (“ <b>Independent Contractor Services</b>”). </p>
    </li>
    <li className="agreement-listing" data-list-text="4.">
      <p className="agreement-listing">The Parties desire to enter into an agreement pursuant to which Independent Contractor will provide the Independent Contractor Services to Comlink, as further described herein.</p>
    </li>
  </ol>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">The parties agree as follows:</p>
  <p className="agreement-listing">
    
  </p>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="1.">
      <p className="agreement-listing">Scope of Services</p>
      <p className="agreement-listing">The Independent Contractor is hereby engaged by the Company to perform door-to-door or Event sales activities, including but not limited to:</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">Presenting and selling the Company&#39;s and Carrier products and/or services. Demonstrating product features and benefits. Answering potential customers&#39; questions. Closing sales transactions.</p>
      <p className="agreement-listing">Collecting payments and issuing receipts, if applicable.</p>
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="2.">
      <p className="agreement-listing">Territory</p>
    </li>
  </ol>
  <p className="agreement-listing">The Sales Representative is authorized to conduct sales activities in Comlink Approved geographical areas TBD: [Specific Territories, Neighborhoods, or Regions will vary]</p>
  <p className="agreement-listing">
    
  </p>
  <b>NOW THEREFORE,</b> in consideration of the mutual covenants contained herein, the Parties agree as follows:
  
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="I.">
      <b>Definitions.</b> Capitalized terms used herein and not otherwise defined herein are used as defined in this Section I.
      
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <b>“Advertisements” </b> means direct marketing, search marketing, digital advertising, door to door marketing, sponsorship messages, banner advertisements, buttons, toll-free numbers and other promotional items developed for online and direct promotions.
         
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <p className="agreement-listing">“ <b>Applicable Law</b>” means any law or regulation governing the transaction, consumer, prospect or any of the parties or Independent Contractors, under the circumstances of the particular </p>
          <p className="agreement-listing">1</p>
          <p className="agreement-listing">event or action.</p>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="C.">
          <b>“Banner Ad”</b> means an Advertisement that is placed on an Independent Contractor Site or by Independent Contractor in accordance with the brand and other guidelines of this Agreement
          
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="D.">
          <b>“Commission”</b> means the manner in which an Independent Contractor Customer is referred to a Comlink Service Provider, which shall occur when the Independent Contractor Customer contacts Comlink via a warm transfer from Independent Contractor to a Comlink call center or using the COMLINK portal such other manner as the Parties may mutually agree upon from time-to-time.
          
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="D.">
              <b>“Commission Fees”</b> means any fees earned by Independent Contractor under this Agreement.
              
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="E.">
              <b>“Confidential Information”</b> means any and all nonpublic business or technical information disclosed by a Party hereto (a “Disclosing Party ”) to the other Party hereto (a “Receiving Party ”) in connection with this Agreement including information concerning the Disclosing Party’s past, present and future customers, suppliers, technology (including software), and business, and any other information identified as confidential, including without limitation know-how, data, technology, trade secrets, processes, techniques, programs, programming interfaces, designs, formulae, marketing, advertising, financial, commercial, sales or programming materials, written materials, compositions, drawings, diagrams, computer programs, studies, work in progress, visual demonstrations, ideas, concepts, and other data, in oral, written, graphic, electronic, or any other form whatsoever. Notwithstanding the foregoing, “Confidential Information” does not include:
              
              <p className="agreement-listing">
                
              </p>
              <ol className="alpha-listing"

>
                <li className="agreement-listing" data-list-text="1.">
                  <p className="agreement-listing">Information which is generally known to the public when received by the Receiving Party or which subsequently becomes generally known to the public through no fault of the Receiving Party;</p>
                  <p className="agreement-listing">
                    
                  </p>
                </li>
                <li className="agreement-listing" data-list-text="2.">
                  <p className="agreement-listing">Information which is already known to the Receiving Party at the time of its disclosure and is not the subject of an obligation of confidence of any kind;</p>
                  <p className="agreement-listing">
                    
                  </p>
                </li>
                <li className="agreement-listing" data-list-text="3.">
                  <p className="agreement-listing">Information which is independently developed by the Receiving Party without reference to the Confidential Information of the Disclosing Party;</p>
                  <p className="agreement-listing">
                    
                  </p>
                </li>
                <li className="agreement-listing" data-list-text="4.">
                  <p className="agreement-listing">Information which is rightfully received by the Receiving Party from a third party without an obligation of confidence; and</p>
                  <p className="agreement-listing">
                    
                  </p>
                </li>
                <li className="agreement-listing" data-list-text="5.">
                  <p className="agreement-listing">Information that is required to be disclosed by law, provided that the Receiving Party gives prompt notice of such requested disclosure to the Disclosing Party and complies with any valid protective order obtained by the Disclosing Party.</p>
                  <p className="agreement-listing">
                    
                  </p>
                </li>
              </ol>
            </li>
            <li className="agreement-listing" data-list-text="F.">
              <b>“Customer Proprietary Network Information”</b> or <b>“CPNI”</b> means the data collected by Independent Contractor which can be used to distinguish or trace an individual&#39;s identity, such as their name, social security number, credit card information, biometric records, etc. alone, or when combined with other personal or identifying information which is linked or linkable to a specific individual, such as date and place of birth, mother’s maiden name, et, and information about any consumer who Links onto an Independent Contractor Site, which may include, but not be limited to, the time, date, duration and destination of the Link, the type of network a consumer subscribes to, and any other personal information that Independent Contractor receives about such consumer and that may be considered CPNI under Applicable Law.
              
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="G.">
              <p className="agreement-listing">“ <b>Comlink Sites</b>” means the URLs/domains used by Comlink to offer the Comlink Services. </p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="H.">
              <b>“Hosted”</b> means to provide and manage servers, facilities, telecommunications, maintenance, and operations related to the delivery of Internet based services and content.
              
              <p className="agreement-listing">
                
              </p>
              <p className="agreement-listing">2</p>
            </li>
            <li className="agreement-listing" data-list-text="I.">
              <b>“Independent Contractor Customer”</b> means a consumer who contacts COMLINK, through its portal or call center, through Independent Contractor’s Advertisement efforts, is processed through Independent Contractor’s call center or is warm-transferred by Independent Contractor’s call center to a COMLINK portal or call center in all cases directly due to Independent Contractor’s Marketing Tactics performed pursuant to this Agreement.
              
              <ol className="alpha-listing"

>
                <li className="agreement-listing" data-list-text="I.">
                  <b>“Intellectual Property Rights” means:
                  </b>
                  <p className="agreement-listing">
                    
                  </p>
                  <ol className="alpha-listing"

>
                    <li className="agreement-listing" data-list-text="1.">
                      <p className="agreement-listing">Any and all proprietary rights provided under: patent law, copyright law, trademark law, design patent or industrial design law, semi-conductor chip or mask work law, trade secret law, or any other similar statutory provision or common law principle which may provide a right in either ideas, formulae, algorithms, concepts, inventions or know-how generally, or the expression or use of such ideas, formulae, algorithms, concepts, inventions or know-how; and</p>
                      <p className="agreement-listing">
                        
                      </p>
                    </li>
                    <li className="agreement-listing" data-list-text="2.">
                      <p className="agreement-listing">Any and all applications, registrations, licenses, sublicenses, agreements or any other evidence of a right in any of the foregoing;</p>
                      <p className="agreement-listing">
                        
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="agreement-listing" data-list-text="J.">
              <b>“Link”</b> means a URL hidden behind a formatting option that may take the form of a colored item of text (such as a URL description), logo, button, banner or image, and which allows a user to automatically and directly transfer to such other URL, that is, to move to or between Internet pages, sites or within a document; the term Link will also include the act of moving to or between such locations as the context may require.
              
            </li>
            <li className="agreement-listing" data-list-text="K.">
              <b>“Mark” means</b> a trademark or service mark of a Party hereto.
              
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="L.">
              <b>“Marketing Tactics”</b> means the ad copy, lead generation techniques, websites, landing pages, call script, collateral and other means by which Independent Contractor performs sales and marketing services under this Agreement and in accordance with Article 2, including the Rules of Engagement.
              
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="M.">
              <b>“Non-qualified Call</b> or <b>Lead”</b> means a call or lead that is received by the Comlink portal or a COMLINK call center agent that is delivered by Independent Contractor where the customer is not a potential sale for the products and services offered by Comlink from the Comlink Service Providers.
              
            </li>
            <li className="agreement-listing" data-list-text="N.">
              <b>“Qualified Call</b> or <b>Lead”</b> means a call or lead that is received by the Comlink portal or a COMLINK call center agent, delivered from Independent Contractor by means of a toll-free number (“TFN”) assigned by Comlink to Independent Contractor or warm transfer where the customer has been properly informed and is willing to speak with a Comlink call center agent for more than two minutes about obtaining products or services from the Comlink Service Providers in accordance with the Comlink approved Marketing Tactics or pre-qualification guidelines submitted by Comlink and as amended from time to time.
              
            </li>
            <li className="agreement-listing" data-list-text="O.">
              <p className="agreement-listing">“ <b>Qualified Sale</b>” means a Qualified Call which results in an order of an RGU, product or service from a Comlink Service Provider. </p>
            </li>
            <li className="agreement-listing" data-list-text="P.">
              <p className="agreement-listing">“ <b>RGU</b>” means, in context of MSO and Telecommunication Companies, a revenue generating unit and refers to the phone, Internet and video products or services that Comlink sells to Independent Contractor Customers, regardless of tier. Each product or service is considered a single RGU even if a customer orders more than one product or service in a single transaction. For example, a customer who orders video and Internet, will be considered to have sold two RGUs. </p>
            </li>
            <li className="agreement-listing" data-list-text="Q.">
              <b>“RGU Conversion Rate”</b> means the rate at which Independent Contractor’s Qualified Calls are closed according to the following formula: Qualified Sales/Qualified Calls. The RGU Conversion Rate will be calculated on a monthly basis and shall be targeted for at least twenty percent (20%) RGU
              
              <p className="agreement-listing">3</p>
              <p className="agreement-listing">Conversion Rate.</p>
            </li>
            <li className="agreement-listing" data-list-text="R.">
              <p className="agreement-listing">“ <b>RGU Install Rate</b>” means the ratio at which Independent Contractor’s Qualified Sale or Qualified Web- Generated Sale take install or receipt of the products or services offered by Comlink Service Providers as a percentage, e.g., Month 1 has 100 orders; 60 customers take install and pay the first bill. The RGU Install rate is equal to 60%. The RGU Install Rate shall be targeted for at least fifty percent (50%). </p>
            </li>
            <li className="agreement-listing" data-list-text="S.">
              <b>“Commission”</b> means the manner in which an Independent Contractor Customer is referred to a Comlink Service Provider, which shall occur when the Independent Contractor Customer contacts Comlink via a warm transfer from Independent Contractor to a Comlink call center or using the COMLINK portal such other manner as the Parties may mutually agree upon from time-to-time.
              
            </li>
            <li className="agreement-listing" data-list-text="T.">
              <b>“Commission Fees”</b> means any fees earned by Independent Contractor under this Agreement.
              
            </li>
            <li className="agreement-listing" data-list-text="U.">
              <b>“Rules of Engagement”</b> means those rules that Independent Contractor must at all time follow in the performance of the Independent Contractor Services, and as typically passed from the Comlink Service Provider to Comlink for obligatory use by Comlink and the Comlink Independent Contractors.
              
            </li>
            <li className="agreement-listing" data-list-text="V.">
              <b>“URL” </b>means Universal Resource Locator, which will design the unique Internet protocol address for locating and accessing an Internet web site and page.
              
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="II.">
      <b>Description of the Independent Contractor Services.</b>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing">Independent Contractor desires to sell services from Comlink Service Providers and as mutually amended, agreed and supplemented from time to time.</p>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <b>Rules of Engagement. </b> Independent Contractor will comply in all respects with the Rules of Engagement set forth in Schedule 2.
         
        </li>
        <li className="agreement-listing" data-list-text="C.">
          <b>Use of Marketing Tactics. </b> All of Independent Contractor’s Marketing Tactics related to selling under this Agreement, including, but not limited to, the use of Advertisements in Print, on website(s) and Banner Ads, must be pre-approved in writing by Comlink, without exception. Independent Contractor acknowledges that Comlink’s ability to continue offering its services to Comlink Service Providers is subject to and conditioned upon Comlink following the brand guidelines are subject to rules of engagement of Comlink Service Providers. Accordingly, a material, critical and ongoing condition of this Agreement is the compliance with the Rules of Engagement and the pre-approval of Independent Contractor’s Marketing Tactics by Comlink in accordance with the provisions of this Agreement. In the event of a failure to comply with this provision, Comlink may, inter alia, immediately suspend this Agreement, terminate the Agreement and pursue all legal remedies including injunction and damages, and all other remedies available under this Agreement. Comlink may, in its reasonable discretion, determine when a violation of this provision has occurred. Upon notification of a violation of this provision, Independent Contractor agrees to immediately suspend the offending Marketing Tactic until the parties have resolved the issue to Comlink reasonable satisfaction.
         
        </li>
        <li className="agreement-listing" data-list-text="D.">
          <b>Independent Contractor</b> understands and agrees that the requirement to comply in all respects with the Rules of Engagement and to obtain <i>pre-approval of all Marketing Tactics </i>are material terms of this Agreement, the individual breach of which will give rise to Comlink’s immediate right to terminate this Agreement and the forfeiture of Commission Fees, as further provided in Section 6 (Term and Termination), without prejudice to any other rights and obligations 
          <p className="agreement-listing">4</p>
          <b>of COMLINK for breach of this provision.</b>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="E.">
          <b>Independent D2D/Event Contractors</b> are not permitted to outbound dial or Robodial or receive inbound calls from residential customers to process sales orders. Residential customers can only be contacted via Comlink Approved Door to Door territory or at Comlink approved Events.
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="III.">
      <b>Commissions.</b>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <b>Restrictions on Commissions.</b>
          <p className="agreement-listing">
            
          </p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="1.">
              <p className="agreement-listing">Independent Contractor will not hold itself out as a Comlink Service Provider or Independent Contractor of a Comlink Service Provider except in accordance with Marketing Tactics approved by Comlink in writing.</p>
            </li>
            <li className="agreement-listing" data-list-text="2.">
              <p className="agreement-listing">Independent Contractor will not make any representations regarding the Comlink Services or the services of any Comlink Service Provider, other than those expressly approved in writing or distributed by Comlink or the applicable Comlink Service Provider.</p>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <b>Integration and User Information.</b> Beginning from the Effective Date, Independent Contractor will, subject to applicable laws, privacy policies and contractual and other obligations, disclose to Comlink certain identifying information (“User Information ”) it obtains from each Independent Contractor Customer that is a Commission so that the Independent Contractor Customer can avoid re-entering information that it may already have provided to Independent Contractor. Comlink will own all rights in and to such User Information. Independent Contractor acknowledges and agrees that, except as expressly provided herein, all User Information will constitute Confidential Information of Comlink, and will be treated as Comlink Confidential Information in accordance with the confidentiality provisions of this Agreement. In addition, each of Parties agrees not to disclose the User Information in a manner that would violate any Applicable Law or regulations, including without limitation, anti-spamming laws and regulations governing the use of Customer Proprietary Network Information (CPNI).
          
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="IV.">
      <b>Commission Fees.</b> COMLINK shall pay the Commission Fees on a weekly or monthly basis as calculated in accordance with INDEPENDENT CONTRACTOR Partnership Rate Card hereto.
      
    </li>
    <li className="agreement-listing" data-list-text="V.">
      <b>Payment Terms.</b>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing">Comlink will pay all Commission Fees on a weekly or monthly basis, in US dollars, within thirty (30) days from the end of the month in which an order is installed by Comlink. This can vary based on when Comlink receives commission funds from the customer or carrier.</p>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <p className="agreement-listing">Accompanying all payments, Comlink will provide to Independent Contractor a statement itemizing the basis for its calculation of the Commission Fees.</p>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="C.">
          <p className="agreement-listing">Commission Fee schedules are subject to change at any-time without written notice. This disclaimer is being posted do to the large amount of carriers we offer and volume fluctuations that can affect commissions rates. Please see your Comlink rate card for more details</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">E. If applicable, upon execution of this Agreement, Independent Contractor will complete and return IRS form W-9 to Comlink.</p>
          <p className="agreement-listing">
            
          </p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="VI.">
      <b>Term and Termination.</b>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing">Unless terminated as provided herein, the term of this Agreement will begin on the Effective Date</p>
          <p className="agreement-listing">5</p>
          <p className="agreement-listing">and continue for an initial period of one year. Thereafter, the Agreement will continue automatically for additional periods of one year, unless either Party terminates the Agreement as provided herein.</p>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <p className="agreement-listing">Either Party may terminate this Agreement for convenience upon thirty (30) days prior written notice.</p>
        </li>
        <li className="agreement-listing" data-list-text="C.">
          <p className="agreement-listing">Comlink may terminate this Agreement immediately by written notice (including email) to Independent Contractor if Comlink reasonably determines that Independent Contractor has failed to comply in all respects with the Rules of Engagement or is engaged in improper or unapproved Marketing Tactics or fraud. In such event, Independent Contractor agrees that, amongst any other available remedies at law, all Commission Fees owed and not yet paid by Comlink shall be forfeited.</p>
          <p className="agreement-listing">E. Upon the expiration or termination of this Agreement for any reason, each Party will immediately deactivate all phone numbers, Order Entry Tools and Links to the other Party’s Site. Except as provided in Section 6.c. immediately above, all accrued payment obligations of COMLINK to Independent Contractor, including those of COMLINK with respect to Qualified Calls that have not yet been paid, will survive expiration or termination of this Agreement. Any and all other obligations under this Agreement which, by their very nature, should reasonably survive the termination or expiration of this Agreement, will so survive. Each Party will, at the request and expense of the other, provide reasonable assistance to effect an orderly termination.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="VII.">
      <b>Exclusivity.</b> During the term of this Agreement and for thirty (30) days following the termination of this Agreement, Independent Contractor will not enter into Commission agreements with other third parties to provide services that are the same as or substantially similar to the Independent Contractor Services that Independent Contractor is providing to Comlink unless Comlink provides written approval.
      
    </li>
    <li className="agreement-listing" data-list-text="VIII.">
      <b>Tracking.</b> Comlink will use commercially reasonably good faith efforts to track all Qualified Calls that generate Commission Fees to Independent Contractor and report such information as part of the payment process. Independent Contractor acknowledges and accepts that the tracking system used by Comlink may not be fail-safe and that there may, on occasion, be instances of that are not credited to Independent Contractor. Comlink will try to avoid and/or correct all such omissions; provided, however, that the occasional failure to do so shall not be deemed to be a material breach by Comlink of this Agreement.
      
    </li>
    <li className="agreement-listing" data-list-text="IX.">
      <b>Confidentiality Obligations.</b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing">During the Term of this Agreement and for a period of three (3) years thereafter, each Party will hold all Confidential Information disclosed by the other Party in confidence, using the same degree of care that it uses to protect its own Confidential Information of like importance. To the extent that any Confidential Information disclosed hereunder consists of computer software, application programming interfaces (“ <b>APIs</b>”) or similar technology, the receiving Party may only use such technology in furtherance of the express purposes of this Agreement and may not decompile, disassemble or reverse engineer any such Confidential Information. </p>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <p className="agreement-listing">Each Party may, however, disclose Confidential Information on a “need-to-know” basis under an obligation of confidentiality to its employees and professional advisors, provided that such employees and advisors comply with the provisions of this Section.</p>
        </li>
        <li className="agreement-listing" data-list-text="C.">
          <p className="agreement-listing">The terms and conditions of this Agreement will be deemed to be the Confidential Information of each Party and will not be disclosed without the written consent of the other Party.</p>
        </li>
        <li className="agreement-listing" data-list-text="D.">
          <p className="agreement-listing">The terms and conditions of any Comlink Service Provider agreement or similar agreement of Comlink and which is provided to Independent Contractor for review, will be deemed to be the Confidential Information of Comlink.</p>
        </li>
        <li className="agreement-listing" data-list-text="E.">
          <p className="agreement-listing">Upon the termination of this Agreement, each Party will return to the other all Confidential</p>
          <p className="agreement-listing">6</p>
          <p className="agreement-listing">Information of the other which is then in its possession or control, and will remove all digital representations thereof in any form from all electronic storage media in its possession or under its control.</p>
        </li>
        <li className="agreement-listing" data-list-text="F.">
          <p className="agreement-listing">Each Party acknowledges that its failure to comply with the provisions of this Section may cause irreparable harm to the other Party which cannot be adequately compensated for in money, and accordingly acknowledges that the other Party will be entitled to seek, in addition to any other remedies available to it, interlocutory and permanent injunctive relief to restrain any anticipated, present or continuing breach of this Section without having to post a bond or other security.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="X.">
      <b>Ownership.</b> All software, application programming interfaces, business processes, marketing techniques or technology used by a Party to fulfill its obligations hereunder shall at all times be, and shall be deemed to be, solely the property of such Party, including all patents, copyrights, trademarks, trade secrets and other Intellectual Property Rights therein. Except as it pertains to the limited rights of one Party to use the Marks of another Party and relating to User Information, no rights in any intellectual property are conveyed in this Agreement.
      
    </li>
    <li className="agreement-listing" data-list-text="XI.">
      <b>Representations and Warranties of the Parties.</b>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing">Each Party represents and warrants to the other Party that:</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">1 it has the full corporate power and authority to enter into this Agreement and to perform its obligations hereunder;</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="2.">
              <p className="agreement-listing">none of the Intellectual Property Rights licensed or furnished by such Party to the other Party hereunder does not and will not, during the term hereof, infringe or conflict with the Intellectual Property Rights of any third party;</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="3.">
              <p className="agreement-listing">i n performing this Agreement, it will comply in all material respects with all applicable laws and regulations;</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="4.">
              <p className="agreement-listing">in accessing the other Party’s sites, or utilizing any aspect of the other Party’s services on behalf of itself or a prospective customer, it will do so in accordance with the other Party’s stated privacy and other applicable policies; and</p>
            </li>
            <li className="agreement-listing" data-list-text="5.">
              <p className="agreement-listing">no website used in this Agreement will be used to disseminate any malicious code, including without limitation viruses, worms, trojans, adware or spyware, or contains or will contain any material that is pornographic, libelous, defamatory, obscene or infringing.</p>
            </li>
          </ol>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          EXCEPT FOR THE REPRESENTATIONS AND WARRANTIES EXPRESSLY SET FORTH IN THIS SECTION, EACH PARTY DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NEITHER PARTY WARRANTS THAT ITS WEBSITE OR SERVICES WILL OPERATE ERROR-FREE OR WITHOUT INTERRUPTION.
          <p className="agreement-listing">
            
          </p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="XII.">
      <b>Indemnities.</b> Each Party agrees to defend, indemnify and hold the other Party and its directors, officers, employees and contractors (including Comlink Service Providers) harmless from and against all third party claims, losses, costs, damages, expenses and liabilities, including court costs and reasonable legal fees brought by a third party (collectively “Claims ”) arising from or in connection with, or based on allegations: arising out of any material breach or non-performance of any provision of this Agreement or personal injury or damage to property caused by such Party or its employees or contractors in connection with its performance of this Agreement. The indemnified Party shall promptly notify the indemnifying Party of any Claims, and the indemnifying Party shall have the right to defend such Claim with counsel of its choosing. At the request of an indemnifying Party, the indemnified Party shall reasonably cooperate in the defense and settlement of such Claim at the indemnifying Party’s
      
      <p className="agreement-listing">7</p>
      <p className="agreement-listing">expense.</p>
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="XIII.">
      <b>Limitation of Liability.</b> Neither Party shall be liable to the other Party hereunder for any special, consequential, indirect, incidental, exemplary or punitive damages or loss of profit, whether in contract, tort or otherwise, even if such Party has been advised of the possibility of such damages. Except with regard to violations by Independent Contractor of the Rules of Engagement, the Warranties and as set forth in Article II.C, each Party’s liability to the other Party hereunder will not exceed the amounts paid to Independent Contractor over an immediately preceding twelve (12) month period, calculated as of the date that the default or indemnification obligation arose.
      
    </li>
    <li className="agreement-listing" data-list-text="XIV.">
      <b>Independent Contractor Status of Parties.</b> Nothing in the Agreement shall be deemed to constitute a partnership or joint venture between Independent Contractor and Comlink. No Party or their respective employees, agents, customers, or subcontractors shall be deemed an employee, agent, customer, or subcontractor of the other Party by virtue of the performance of the services set forth in this Agreement. Under no circumstances shall a Party’s employees, agents, customers, or subcontractors, be entitled to any benefits which the other Party may offer to its own employees, agents, customers, or subcontractors from time to time.
      
    </li>
    <li className="agreement-listing" data-list-text="XV.">
      <b>Notices. </b> All notices and other correspondence to be served under this Agreement, whether as notice of process or otherwise, shall be made in writing and shall be sufficiently be deemed given on the date received by:
     
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing">certified mail (postpaid and return receipt requested), or</p>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <p className="agreement-listing">Federal Express or comparable overnight courier of national reputation with proof of receipt.</p>
          <p className="agreement-listing">Any required notices or correspondence shall be sent to:</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">If to Comlink: If to Independent Contractor:</p>
          <p className="agreement-listing">
            
          </p>
          <div className="form--fileds ms-5">
              <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-6" for="exampleInputEmail1">
                      Matthew Santini, CEO
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      Comlink Total Solutions Corp.
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      2891 Center Pointe DR
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">STE 203 </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      Fort Myers, Florida 33916
                    </label>
                  </div>
                </div>
                <p className="mt-4">With a copy to:</p>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Absolute Law, PA </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      12585 New Brittany Blvd.
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      Fort Myers, Florida 33907
                    </label>
                  </div>
                </div>
              </div>
            </div>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="XVI.">
      <b>Publicity.</b> Each Party hereto has the right periodically to publicize the relationship set forth in this Agreement, provided the publicity has been submitted to and approved by the other Party prior to disclosure, such approval not to be unreasonably withheld or delayed. The Parties will agree on the timing and content of a press release for release promptly upon signing of this Agreement, announcing the Parties’ relationship.
      
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">8</p>
    </li>
    <li className="agreement-listing" data-list-text="XVII.">
      <b>Miscellaneous Provisions .
      </b>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing">The failure of either Party to exercise any right provided for herein shall not be deemed to be a waiver of any other right hereunder. No waiver of any right or breach of this Agreement shall be deemed to be a waiver of any subsequent exercise of a right or breach of this Agreement. No waiver of any right of obligation in this Agreement may be made except through a writing signed by the waiving Party.</p>
        </li>
      </ol>
    </li>
  </ol>
  <p className="agreement-listing">
    
  </p>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="C.">
      <p className="agreement-listing">Neither Party shall be liable to the other for any loss or damage which may be suffered by the other due to any cause beyond the Party’s reasonable control including, but not limited to, any failure of third-party software, telephone or database systems, act of God, terrorism, inclement weather, failure or shortage of power supplies, power outages, flood, drought, lightning or fire, strike, lock-out, trade dispute or labor disturbance, the act or omission of government, telecommunications operators or administrators or other competent authorities, war, military operation, or difficulty, delay or failure in manufacture, production or supply by third parties of materials or equipment necessary to carry out this Agreement.</p>
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="D.">
      <p className="agreement-listing">This Agreement shall be governed by and construed in accordance with the laws of the Delaware without regard to its conflict-of-law rules. The Parties hereby consent to the exclusive jurisdiction of the state or federal courts located in Washington DC for resolution of disputes arising out of this Agreement.</p>
    </li>
  </ol>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">E If any term of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that will not affect: (a) the legality, validity or enforceability in that jurisdiction of any other term of this Agreement; or (b) the legality, validity or enforceability in other jurisdictions of that or any other provision of this Agreement.</p>
  <p className="agreement-listing">
    
  </p>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="F.">
      <p className="agreement-listing">This Agreement and the schedules annexed hereto constitute the entire Agreement between the Parties regarding the subject matter hereunder and supersede all prior and contemporaneous representations, proposals, discussions, and communications, whether made orally or in writing. This Agreement may be modified or amended only in writing signed by each of the Parties.</p>
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="G.">
      <p className="agreement-listing">A person who is not a party to this Agreement shall not have any third party rights under this Agreement.</p>
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="H.">
      <p className="agreement-listing">This Agreement may be executed and delivered in any number of counterparts by facsimile or electronic mail, all of which taken together will constitute one and the same agreement.</p>
    </li>
  </ol>
  <p className="agreement-listing">
    
  </p>
  {/* <p className="agreement-listing">.</p> */}
  <p className="agreement-listing">
    
  </p>
  {/* <p className="agreement-listing">9</p> */}
  <p className="agreement-listing">
    
  </p>
  <b>SCHEDULE 2A RULES OF ENGAGEMENT</b>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">In order to maintain consistency and to honor Comlink’s relationships with all of the Comlink Service Providers, it is imperative that Comlink accurately represent brands and products in accordance with contractual obligations and not engage in any actions or omissions that will cause Comlink to be in breach of its agreements with the Comlink Providers. These requirements <u>must be honored</u> by Independent Contractor, whether Independent Contractor is directly or indirectly involved in the offering of the products and services of any Comlink Service Provider under this Agreement. Comlink may unilaterally update these Rules of Engagement from time-to-time to, among other things; remain compliant with its agreements with the Comlink Service Providers. In the event that Independent Contractor does not agree to any amendment of this Schedule 2, it shall immediately cease marketing and terminate any campaigns as to the relevant Comlink Service Provider. </p>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="1.">
      <b>Spam/Unsolicited Commercial Email (UCE).</b> Independent Contractor may not participate in mass, unsolicited emailing (i.e., spamming).
      
    </li>
    <li className="agreement-listing" data-list-text="2.">
      <b>Spyware and Other Parasitic Software.</b> Independent Contractor is prohibited from forming a relationship, formal or informal, with any provider of spyware or parasitic software.
      
    </li>
    <li className="agreement-listing" data-list-text="3.">
      <b>Website Restrictions.</b> Independent Contractor is prohibited from including content on any of the Independent Contractor Sites that is reasonably determined, in Comlink’s sole discretion to contain any of the following:
      
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">X-rated or sexually explicit materials;</p>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">Violence or discord;</p>
        </li>
        <li className="agreement-listing" data-list-text="c.">
          <p className="agreement-listing">Discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;</p>
        </li>
        <li className="agreement-listing" data-list-text="d.">
          <p className="agreement-listing">Illegal, morally questionable or controversial activities;</p>
        </li>
        <li className="agreement-listing" data-list-text="e.">
          <p className="agreement-listing">Violation of intellectual property rights.</p>
          <p className="agreement-listing">
            
          </p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="4.">
      <b>Behavior/Conduct.</b> Independent Contractors are to conduct themselves professionally and respectfully when dealing with potential customers, Comlink employees, Comlink Service Provider or any group or individual associated or Independent Contractord Comlink. Abusive and/or unprofessional behavior is prohibited.
      
    </li>
    <li className="agreement-listing" data-list-text="5.">
      <b>Domain Masking.</b> Independent Contractor is prohibited from hiding the actual domain name of any Independent Contractor Site from the URL field of a user’s web browser in favor of another name. For Comcast, Independent Contractor may not use a Comcast branded term in the Comcast table below in any URL without the express written permission of Comcast and Comlink.
      
    </li>
    <li className="agreement-listing" data-list-text="6.">
      <b>Branded Search.</b> Independent Contractor shall not use any Comlink Service Provider name or trademark, including a trademark of a company Independent Contractord with a Comlink Service Provider, in any Advertisement, including paid search.
      
    </li>
  </ol>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">10</p>
  <p className="agreement-listing">
    
  </p>
  <b>COMLINK’S DO NOT CALL and Telemarketing Policy</b>
  <p className="agreement-listing">Telemarketing</p>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">The federal Telephone Consumer Protection Act, 47 U.S.C. § 227 et. seq., (“TCPA”) places restrictions on the use of telephone equipment to market or promote products and services. Numerous states have adopted statutes modeled after or more restrictive than the TCPA, each with its own penalty scheme. Another related and significant regulatory regime in this area is the Telemarketing and Consumer Fraud Prevention Act, 15 U.S.C. § 6101 et. seq., as implemented by the Federal Trade Commission in the Telemarketing Sales Rule, 16 CFR Part 310 (“TSR”). In 2003, the FTC and FCC established and began enforcement of a National Do Not Call Registry. The FCC has also implemented stricter rules for enforcing the TCPA which began to take effect in 2012. Many states also have their own Do Not Call Registries, as well as telemarketer registration and bonding requirements. They also often impose stricter requirements than the federal law. In many instances these state laws apply to <b>all forms </b>of outbound and inbound telemarketing. Thus, even if you are otherwise making a permitted call, you may still be required to register and/or post a bond in certain states prior to placing or receiving a telemarketing call. </p>
  <p className="agreement-listing">The potential penalties for violating these laws are serious. For example, the government can impose</p>
  <p className="agreement-listing">$16,000.00 in penalties “for each such violation” of the TCPA or the Telemarketing Sales Rule. In addition, under the TCPA, consumers can bring private rights of action to seek the greater of actual damages or</p>
  <p className="agreement-listing">$500, which can be trebled to $1500 by the Court if the conduct is deemed willful. Because of the myriad of laws making compliance difficult, as well as the penalties and loss of reputation and goodwill associated with non-compliance, COMLINK does not provide discretionary marketing funds for use in connection with outbound telemarketing solicitations.</p>
  <p className="agreement-listing">Following are COMLINK ’s specific policies and guidelines regarding particular forms of outbound telemarketing:</p>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="A.">
      <p className="agreement-listing">Facsimile advertising.</p>
      <p className="agreement-listing">
        
      </p>
      <b>
        Facsimile advertising</b> is a form of outbound telemarketing solicitation that is 
        <u>expressly disapproved of for</u>
        <u>ANY use in advertising “COMLINK ” branded products and services.</u>
        In addition, it is considered a violation of this Policy Statement for any Employee to use facsimile advertising in connection with Dish Network or any “satellite television” product if such advertisement is likely to lead to consumer confusion and the mistaken belief that such advertising relates to COMLINK - brand products or services.
      
    </li>
    <li className="agreement-listing" data-list-text="B.">
      <p className="agreement-listing">Pre-recorded messages.</p>
      <b>
        Pre-recorded </b> message advertising is also a form of outbound telemarketing solicitation that is 
        <u>expressly</u>
        <u>disapproved of for ANY use in advertising “COMLINK ” branded products and services.</u>
        In addition, it is considered a violation of this Policy Statement for any Employee to use pre- recorded message advertising in connection with any Comlink’s Major Telecom Partners example: DIRECTV or any “satellite or cable television” product if such advertisement is likely to lead to consumer confusion and the mistaken belief that such advertising relates to COMLINK-brand products or Comlinks Major telecom carrier partner services.
     
    </li>
    <li className="agreement-listing" data-list-text="C.">
      <p className="agreement-listing">Text or SMS messages.</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">Several courts have determined that text or SMS messages are legally the same as phone calls or pre- recorded messages. Therefore, text message advertising is also a form of outbound telemarketing solicitation that is <u>
          expressly disapproved of for ANY use in advertising “COMLINK ” branded
        </u>
      </p>
      <p className="agreement-listing">11</p>
      <p className="agreement-listing">
        
      </p>
      <b>
        <u>products and services.</u></b>
        In addition, it is considered a violation of this Policy Statement for any Employee to use text message advertising in connection with Dish Network or any “satellite television” product if such advertisement is likely to lead to consumer confusion and the mistaken belief that such advertising relates to COMLINK -brand products or services.
      
    </li>
    <li className="agreement-listing" data-list-text="D.">
      <p className="agreement-listing">Outbound unsolicited telephone calls by live operators (“cold calling”).</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">Using live operators (with or without using dialing equipment) to place unsolicited (no applicable existing business relationship or qualifying inquiry) outbound telemarketing calls, sometimes also referred to as “cold calls,” <u>
          <b>expressly disapproved of for ANY use in advertising “COMLINK ” branded products and</b>
        </u>
        <b></b>
        <u>
          <b>services</b>
        </u>. In addition, it is considered a violation of this Policy Statement for any Employee to place such calls in connection with Dish Network or any “satellite television” product if such advertisement is likely to lead to consumer confusion and the mistaken belief that such advertising relates to COMLINK -brand products or services.
      </p>
    </li>
    <li className="agreement-listing" data-list-text="E.">
      <p className="agreement-listing">Returning Calls to Prospective Customers who have made a “qualified” inquiry, and calls to existing or former customers</p>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="1.">
          <p className="agreement-listing">
            <i>Inquiries from prospective customers</i>. Employees may <i>generally </i>make or return telephone calls to prospective customers who initiate contact with them to inquire about COMLINK products and services, notwithstanding the consumer’s registration in the federal Do Not Call registry or similar state registries. Current federal law permits an outbound telemarketing call to a consumer if the consumer has made an inquiry within the past 90 days regarding a product or service offered by the Employee, <u>
              <b>unless</b>
            </u>
            <b></b>the consumer has previously asked to be placed on the Employee’s <i>internal </i>Do Not Call list. Some state laws do impose more restrictive time frames, require registration and/or bonding requirements or even prohibit return calls to consumers on their state Do Not Call registries altogether, so obtaining advice of counsel regarding the laws in the jurisdictions within which you plan to operate is imperative. Moreover, in recent years, states have become increasingly active in restricting the scope of traditional EBR calls (e.g. New York). You must review any state’s telemarketing laws before you choose to place any type of telemarketing call into a state.
          </p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">You must also have a written Do Not Call policy, train your operators on the policy, enforce compliance with the policy, provide it upon a consumer request, and timely honor all requests to be placed on your internal Do Not Call list. You must scrub against your internal list (and more restrictive state lists) before making return calls to consumers who have made an inquiry.</p>
          <p className="agreement-listing">Typically, in order to qualify for an “inquiry” exemption to Do-Not-Call registries, the inquiry by the consumer must be made to you, the actual Employee, and not to a third party marketer or lead generator (unless that generator clearly and conspicuously identifies you and that it is acting on your behalf). The inquiry must also be specific to COMLINK products or services, not simply, for example, a general consent to receive more information about “electronic products” or “satellite systems.”</p>
          <p className="agreement-listing">COMLINK ’s policy requires that return calls be made by a live operator – no pre-recorded messages, including “Press 1” or other approaches should be used. Any other method could result in violation of the law.</p>
          <p className="agreement-listing">In addition, the FCC’s stricter rules under the TCPA announced in 2012 do not allow telemarketing calls to be made using automatic dialing equipment unless the marketer has proof of the consumer’s prior express consent to receive calls. In the case of calls to cellular phones, the proof of consent must be <u>written</u>
            (requiring written or ESIGN consent proof). Therefore, return calls should not only be made by live operator – no pre-recorded messages – but best practices
          </p>
          <p className="agreement-listing">12</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">suggest having operators manually dial to return calls in order to avoid potential problems in proving consent.</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">If automatic dialing equipment is to be used to return customer inquiries, special steps must be taken to avoid violations. First, return calls should not be made with automatic dialing equipment to any number simply “captured” through a caller ID or other system. Return calls should only be placed to consumers who talked to an agent and provided consent to future calls. Consent must be clearly and expressly given (e.g., “may I call you back at this number ?” and/or “would</p>
          <p className="agreement-listing">you like to provide a number so I may call you back?”). If the consumer says no or refuses to provide a number, the consumer may not be called back using automatic dialing equipment. If the agent is unable to ask for consent (e.g., the caller hangs up or the call drops), the consumer may not be called using automatic dialing equipment. Second, because using automatic dialing equipment to make telemarketing calls to <u>cellular phones</u>
            requires consent to be <u>written</u>, calls in which consent is obtained must be recorded and stored to maintain ESIGN proof of consent.
          </p>
          <p className="agreement-listing">Either all calls need to be recorded and preserved, or, policies and procedures could be developed for identifying cellular telephones (including asking the consumer if the number they are providing is a cellular number) and recording and maintaining written consents only insofar as cellular phones are concerned. Finally, all call abandonment rules must be adhered to, including the new FCC’s 2012 rule change requiring abandoned call messages to include an interactive opt out for DNC requests..</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">There are several key aspects to remember when claiming “inquiry” call exemptions to the TSR or state laws:</p>
          <ul>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">Time frames in which calls can be returned must be strictly obeyed, both in terms of overall call return period (as noted, federal law permits return calls within 90 days of inquiry, but some state laws are more restrictive) and hour, day of week and holiday restrictions (e.g., no calls before 8 AM or on holidays). In addition, some states impose registration and bonding requirements which may apply to all types of telemarketing calls, including calls to consumers who have made an inquiry, and to inbound calls. You must consult with legal counsel to ensure compliance in every state to which you return or place calls to inquiring consumers.</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">Consumer inquiries to third parties do not create an exemption unless, in the process of obtaining the lead, the Independent Contractor clearly and conspicuously discloses that the consumer will receive a call from you, the actual Employee/seller.</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">That is true even as to subsidiaries and Independent Contractord companies. Consumer inquiries to your subsidiary or Independent Contractor do not provide you an exemption to call the consumer back, unless, the consumer would reasonably expect you to respond to the inquiry. Similarly, your subsidiaries and Independent Contractors cannot return calls to consumers who make an inquiry to you, unless, the consumer would reasonably expect the subsidiary or Independent Contractor to call. And finally, such permissible return calls must involve the product the consumer originally inquired about.</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="•">
              <b>Remember:</b> COMLINK ’s approval is required if you plan on using a third party to generate leads, and under no circumstance should you call a consumer whose name and phone number was provided by a lead generator unless the consumer is aware of your identity and you can prove the consumer consented to receiving a call from you, as the actual Employee/seller, about COMLINK products or services.
              
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">The burden is on you to maintain records and documents to “prove that an inquiry was made by the consumer” and to the extent autodialing equipment is used, the appropriate consent was obtained, including proof of written/ESIGN consent for returning calls to cellular phones. This is not only a COMLINK</p>
            </li>
          </ul>
          <p className="agreement-listing">13</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">policy requirement, but a legal requirement. You should never place a return call to a consumer unless you have proof that the consumer made an inquiry and consented to receiving calls from you. Relying on capturing a call number through caller – ID technology is not sufficient. You must provide such proof upon the request of COMLINK or any regulatory enforcement agency.</p>
        </li>
        <li className="agreement-listing" data-list-text="2.">
          <p className="agreement-listing">
            <i>Calls made to a consumer where there is an existing prior business relationship which includes a financial transaction. </i>Under federal law, you may also call consumers who have engaged in a financial transaction with you within the past 18 months prior to the telemarketing call. Again, however, several states have stricter laws and it is incumbent upon you to consult with counsel to ensure compliance in every jurisdiction in which you plan to make such calls, as every year more states are passing more restrictive laws. <u>Further, as with the consumer inquiry exemption described above, the</u>
            <u>following restrictions apply</u>:
          </p>
          <p className="agreement-listing">
            
          </p>
          <ul>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">This exemption does not apply if the consumer has asked to be placed on your internal Do Not Call list.</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">This exemption does not apply to your Independent Contractors or subsidiaries, as described above.</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">The burden is on you to maintain documents and records in order to establish that the exemption exists. These documents proving the relationship must be produced upon the request of COMLINK or any regulatory enforcement agency.</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="•">
              <p className="agreement-listing">Some state laws are more restrictive, so you must consult with local counsel to determine whether or not existing business relationship telemarketing calls can be made to consumers in particular states.</p>
            </li>
          </ul>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="F.">
      <p className="agreement-listing">General Telemarketing Requirements</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">In addition to the national database Do Not Call provisions, the Telemarketing Sales Rule also sets forth significant requirements which must be complied with when engaging in <u>any</u> type of telemarketing (including calls made in response to a consumer’s inquiry or to customers with whom you have an existing business relationship). Similar requirements also exist at the state level. At a minimum, Employees engaging in any type of telemarketing sales call must comply with the following requirements: </p>
      <p className="agreement-listing">Immediate disclosures. You must immediately and promptly disclose at the beginning of the call your identity, the purpose of the call, the nature of products being sold and certain other disclosures.</p>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <ul>
          <li className="agreement-listing" data-list-text="•">
            <p className="agreement-listing">Calling time restrictions – you can generally only make calls between 8:00 a.m. and 9:00</p>
            <p className="agreement-listing">p.m. in the consumer’s time zone. Some states have more restrictive time restrictions.</p>
            <p className="agreement-listing">
              
            </p>
          </li>
          <li className="agreement-listing" data-list-text="•">
            <p className="agreement-listing">Maintenance of internal Do Not Call policies – all Employees or Contractors who call consumers must maintain a written Do Not Call policy, train all employees about that policy and police for compliance. Further, upon request, a copy of the written policy must be sent to any consumer requesting the same. Lastly, you must maintain and scrub against your internal Do Not Call list and place consumers on that list immediately after receiving the request.</p>
            <p className="agreement-listing">
              
            </p>
          </li>
          <li className="agreement-listing" data-list-text="•">
            <p className="agreement-listing">Additional disclosures. Prior to the conclusion of the call, you must disclose the total cost including shipping and handling charges of any product purchased, all material conditions of the sale including any material limitations on free equipment or installation offers (such as any COMLINK requirement to maintain a certain level of service for a specified period of time) and certain additional</p>
            <p className="agreement-listing">14</p>
            <p className="agreement-listing">
              
            </p>
            <p className="agreement-listing">disclosures regarding any prize promotions.</p>
            <p className="agreement-listing">
              
            </p>
          </li>
          <li className="agreement-listing" data-list-text="•">
            <p className="agreement-listing">Record Keeping Requirements. In addition to maintaining records concerning any claimed exemption from a Do Not Call registry, you are also required to maintain other records concerning the telemarketing sale of products or services, including copies of all advertisements and promotional materials, sales and prize records, and certain contact information concerning telemarketing employees.</p>
            <p className="agreement-listing">
              
            </p>
          </li>
          <li className="agreement-listing" data-list-text="•">
            <p className="agreement-listing">Bonding and Registration Requirements: Some states require telemarketers to be registered and/or provide a bond, even for inbound calls in some states. You must consult with counsel to determine whether or not these requirements are triggered by your intended activities.</p>
            <p className="agreement-listing">
              
            </p>
          </li>
          <li className="agreement-listing" data-list-text="•">
            <p className="agreement-listing">Miscellaneous requirements: You may not engage in threats of intimidation, repetitive and annoying calls, or make false and misleading statements.</p>
          </li>
        </ul>
      </ol>
    </li>
  </ol>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="II.">
      <p className="agreement-listing">No Third Party Solicitors/Marketing Agents</p>
      Your Agreement with COMLINK does not allow the use of third parties to solicit sales absent express written approval of COMLINK . Approval must be given by both the Regional Vice President/Senior Director and Senior Vice President – Sales. No other employee is authorized to provide approval, written or otherwise. Thus, you are not authorized to use any agent, independent contractor or any other third party to conduct marketing campaigns as addressed in this Policy Statement. In the event that COMLINK suspects you are using a third party to telemarket, email, text, conduct home solicitations, or any other similar form of marketing, COMLINK may immediately terminate your Agreement.
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="III.">
      <p className="agreement-listing">Internet Marketing</p>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="A.">
          <p className="agreement-listing"><b>E-Mail</b></p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">A federal law (The Can Spam Act), effective January 1, 2004, places numerous restrictions on e-mail marketing messages that companies may send to users. The Can Spam Act creates tough penalties such as criminal sanctions with up to 5-year jail sentences and fines including statutory damages of up to $2 million per incident (trebled to $6 million for knowing violations). The law prohibits deceptive practices that mislead consumers, such as using misleading subject lines or headers, masking the marketer’s identity in the reply address, or falsifying registration information. This federal law pre-empts most state laws related to e-mail regulation, but you need to check with counsel to ensure no additional state requirements apply.</p>
          <p className="agreement-listing">COMLINK does not engage in nor does it condone illegal e-mail marketing, sometimes referred to as “spamming.” Accordingly, COMLINK will not tolerate spamming from Employees. Your Agreement requires you to comply with all applicable laws, and also requires that in all of your activities as an independent contractor for COMLINK , as well as in your separate equipment business, you engage in no practice which impugns COMLINK ’s commercial reputation and goodwill. Spamming not only may result in the violation of laws, but also reflects poorly on the COMLINK -brand name.</p>
          <p className="agreement-listing">If you choose to market via e-mail, you are responsible for adopting and adhering to policies and procedures that will prevent illegal spamming. You need to speak with your legal counsel to ensure compliance with the law, but at a minimum, your policies should address the following: E-mail messages containing advertisements are required to include a clear and conspicuous identification that the message is an advertisement or solicitation. Further, the law prohibits misleading practices such as using misleading subject lines or headers, masking the marketer’s identity in the reply address, or falsifying registration information. Messages must also include a clear and conspicuous notice that recipients can “opt-out” of receiving future messages, and the message itself must include an immediate opt-out mechanism – either a</p>
          <p className="agreement-listing">15</p>
          <p className="agreement-listing">
            
          </p>
          <p className="agreement-listing">functioning return address or an automated opt-out method. The opt-out mechanism must work for at least thirty (30) days after the e-mail was sent. The sender has ten (10) days to remove an opt-out from its marketing list. Messages must further contain a valid physical postal address. Harvesting of e-mail addresses on the Internet or randomly generating electronic mail addresses by computer is strictly prohibited. Harvesting activities constitute aggravated violations which may result in trebled fines.</p>
          <p className="agreement-listing">Some prohibitions (not any related to deceptive practices) may not apply if the advertiser has the recipient’s express affirmative consent to receive e-mail advertisements. However, consent can only be demonstrated where the recipient expressly consented to receive e-mail advertisements from that advertiser either in response to a clear and conspicuous request for consent or at the recipient&#39;s own initiative. Finally, e-mail messages sent to facilitate, complete or confirm a commercial transaction are exempt. This includes messages that reflect account statements, change of status or terms, product updates and upgrades, warranty information, safety or security information, subscriptions, memberships and other similar commercial relationships. However, this exemption does not provide marketers with a broad “existing business relationship” exemption. Thus, before undertaking any campaign, you should consult with your legal counsel to ensure compliance with all new developments in the law.</p>
          <b>COMLINK </b>expects you to keep, maintain and utilize the required “opt-out” list to prevent sending messages to consumers who have expressed a desire not to be contacted via e-mail. You must have in place, and train all employees involved in this marketing activity on, written policies and procedures to ensure that all requests – including any that may be made to your postal address – are timely addressed and honored. These written policies and procedures must be made available to COMLINK upon request.
          <p className="agreement-listing">
            
          </p>
          To the extent you claim exemption from the law due to affirmative consent or completion or confirmation of a commercial transaction, you must preserve and maintain proof of such exemption status. Such proof must be made available to COMLINK upon request.
          <p className="agreement-listing">
            
          </p>
          Failure to comply or produce materials to COMLINK upon its request may result in termination of your Agreement.
        </li>
        <li className="agreement-listing" data-list-text="B.">
          <b>Banner Ads</b> and Other Website Marketing
        </li>
      </ol>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">
        <a href="http://ftc.gov/os/2013/03/130312dotcomdisclosures.pdf" target="_blank">State, federal, and foreign laws generally related to advertising apply in both the online and off- line environments. Accordingly, when using or operating banner ads or otherwise advertising on websites or through other Internet channels, you should consult with your legal counsel to ensure compliance with all such laws. This includes ensuring that the content of any banner ads or other advertisements contain no misrepresentations or misleading statements about COMLINK -branded products or services, and that all material information is clearly and conspicuously disclosed to consumers, including a clear and conspicuous disclosure in close proximity to any offer of the material limitations and requirements associated with such offer. In March 2013, the FTC released staff guidance entitled “ </a>
        <a href="http://ftc.gov/os/2013/03/130312dotcomdisclosures.pdf" target="_blank">.com Disclosures: How to Make Effective Disclosures in Digital Advertising</a>.” This guidance takes into account the growing use of small screen devices by consumers, and provides examples of advertising that may be problematic, so should be reviewed by you and your counsel.
      </p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">Your legal counsel can provide information and advice about other required disclosures in the on-line environment, but at a minimum, your on-line marketing should clearly and conspicuously provide your identity and contact information (to avoid confusion that the advertising was placed by COMLINK ), the nature of the products and services being offered, all material terms associated with that offer, and the types of information (including personally identifiable information) that you collect from and about consumers.</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">16</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">With regard to the collection of information, you should be certain to disclose the types of information collected <b>passively </b>from the consumer, such as through cookies, Internet Protocol (“IP”) addresses, web bugs, and other passive collection devices. Employees should also fully disclose to consumers information about any <b>third parties </b>that help support, or otherwise obtain information from, the Employee’s Internet marketing activities (such as DoubleClick, Coremetrics, etc.). You should realize that the failure to fully disclose and otherwise properly handle information collection, use, and disclosure might violate any number of applicable state, federal, and/or foreign consumer protection and privacy laws. COMLINK has the right to immediately terminate its Agreement with any Employee that COMLINK believes might have engaged in illegal or inappropriate banner or other website advertising. </p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">Employees &amp; Contractors must also comply with all applicable laws and intellectual property rights when using search engines and search engine listings. In particular, Employees must observe all such laws and rights protecting COMLINK ’s company name, logos and/or trademarks. Employees must not use COMLINK ’s marks in their web addresses or metatags, and must identify themselves as Employees that sell COMLINK -branded products.</p>
      <p className="agreement-listing">Finally, it is important to note that internet marketing cannot be used to generate consumer “inquiries” for subsequent telemarketing activities unless the e-mail, web-form, etc., clearly and conspicuously identifies your business and you obtain the consumer’s demonstrable consent to receive a follow-up telephone call from you about COMLINK products or services. For more information on this topic, see Section I on Telemarketing above.</p>
      <p className="agreement-listing">
        
      </p>
    </li>
    <li className="agreement-listing" data-list-text="IV.">
      <p className="agreement-listing">Cooling-Off Laws</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">Federal, state and local jurisdictional laws govern the sale of goods or services at a consumer’s place of residence. Under the FTC Rule, 17 CFR 429, a buyer in a home solicitation sale must be given a “cooling off” period which consists of three business days during which he or she may cancel an agreement without obligation. The seller may not collect or retain any cancellation fee. The Federal Rule defines a door-to-door sale as the sale of consumer goods or services in which seller or his representative solicits the sale (including those in response to buyer invitation) and the buyer’s agreement or offer to purchase is made at a place other than the seller’s place of business. Many states have similar statutes which address sales that result from direct contact by the supplier at a place other than the seller’s place of business. It is important to note that several state home solicitation statutes also apply to sales that are conducted through telemarketing activities. Various local ordinances also apply to such sales activities, including peddler registration requirements. Similarly, some local municipalities prohibit door sales.</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">If you are conducting sales of goods or services which fall under these criteria, you should consult your legal counsel before undertaking any home solicitation marketing activities. At a minimum, in order to comply with the FTC Rule, state statutes and local ordinances, you must provide the buyers with all required documents and information. You should use a signed agreement or offer to purchase form that is signed by the buyer. This form should include a statement regarding the buyer’s right to cancel. Several states also require that the seller provide a Notice of Cancellation form that the buyer can complete and submit to the seller in the event he wishes to cancel the contract. One example of these forms is provided below; however, you must consult your legal counsel to ensure compliance with varied federal, state and local laws:</p>
      <p className="agreement-listing">
        
      </p>
      <p className="agreement-listing">17</p>
      <p className="agreement-listing">
        
      </p>
      <b>CONTRACTOR NON-EMPLOYEE AGREEMENT</b>
      <p className="agreement-listing">
        
      </p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="1.">
          <p className="agreement-listing">The Contractor is not an employee of Comlink, but is an independent contractor.</p>
        </li>
        <li className="agreement-listing" data-list-text="2.">
          <p className="agreement-listing">The Contractor must comply with all of the rules and regulations for Comlink and each of its Telecom partners or subsidiaries.</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="a.">
              <p className="agreement-listing">For a full list of rules and regulations, see the rules and regulations section of the</p>
              <p className="agreement-listing">
                <u>comlinkpartnerprograml.com</u>
                website.
              </p>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="3.">
          <p className="agreement-listing">The Contractor will be compensated for all contracted service provider installed sales properly submitted through the Comlink portal.</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="a.">
              <p className="agreement-listing">Comlink only compensates contractors for installed sales.</p>
            </li>
            <li className="agreement-listing" data-list-text="b.">
              <p className="agreement-listing">All independent contractor’s commissions are subject to Comlink receiving funds from the Service provider /carrier.</p>
              <ol className="alpha-listing"

>
                <li className="agreement-listing" data-list-text="(1)">
                  <p className="agreement-listing">See Comlink approved provider list in the Comlink Compensation structure for more details.</p>
                </li>
              </ol>
            </li>
            <li className="agreement-listing" data-list-text="c.">
              <p className="agreement-listing">Once commissions have been received, funds will be dispersed to the Contractor via Direct Deposit on Comlink’s payroll funding date.</p>
            </li>
            <li className="agreement-listing" data-list-text="d.">
              <p className="agreement-listing">See the Comlink compensation guide for full detail on carrier compensation.</p>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="4.">
          <p className="agreement-listing">All sales are subject to carrier charge back time frames.</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="a.">
              <p className="agreement-listing">Charge backs are sales that are installed but cancelled within a set period of time by each individual telecom carrier.</p>
            </li>
            <li className="agreement-listing" data-list-text="b.">
              <p className="agreement-listing">If Charge backs occur said contract will be deducted the charge backed commissions from future commissions or be required to reimburse Comlink for charged back commissions within thirty (30) days of the charge back.</p>
            </li>
            <li className="agreement-listing" data-list-text="c.">
              <p className="agreement-listing">A terminated contractor’s final commission payments will be held in escrow until the allotted carrier charge back window has expired.</p>
              <ol className="alpha-listing"

>
                <li className="agreement-listing" data-list-text="(1)">
                  <p className="agreement-listing">Once the charge back window expired, any remaining funds will be released to the contractor for final payment.</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="5.">
          <p className="agreement-listing">Comlink has the right to:</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="a.">
              <p className="agreement-listing">terminate this agreement if it is determined that the Contractor is:</p>
              <ol className="alpha-listing"

>
                <li className="agreement-listing" data-list-text="(1)">
                  <p className="agreement-listing">committing fraudulent or illegal activity towards new or existing customers or potential customers.</p>
                </li>
                <li className="agreement-listing" data-list-text="(2)">
                  <p className="agreement-listing">violating any of the rules and regulations for Comlink or its Telecom carrier partners or subsidiaries;</p>
                </li>
                <li className="agreement-listing" data-list-text="(3)">
                  <p className="agreement-listing">doing anything considered detrimental to Comlink’s business and reputation.</p>
                  <p className="agreement-listing">
                    
                  </p>
                </li>
              </ol>
            </li>
            <li className="agreement-listing" data-list-text="b.">
              <p className="agreement-listing">withhold and forfeit any commissions from the Contractor if:</p>
              <ol className="alpha-listing"

>
                <li className="agreement-listing" data-list-text="(1)">
                  <p className="agreement-listing">fraudulent or illegal practices, including but not limited to marketing practices and selling practices, were used to obtain those commissions.</p>
                </li>
                <li className="agreement-listing" data-list-text="(2)">
                  <p className="agreement-listing">obtained in violation of the rules and regulations for Comlink or any of its Telecom partners or subsidiaries.</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="6.">
          <p className="agreement-listing">This agreement is subject to change at any time without written notice provided by Comlink.</p>
        </li>
      </ol>
    </li>
  </ol>
  <p className="agreement-listing">18</p>
  <p className="agreement-listing">
    
  </p>
  <b>INTERNATIONAL CONTRACTOR NON-EMPLOYEE AGREEMENT</b>
  <p className="agreement-listing">
    
  </p>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="1.">
      <p className="agreement-listing">The International Contractor is not an employee of Comlink, but is an independent contractor.</p>
    </li>
    <li className="agreement-listing" data-list-text="2.">
      <p className="agreement-listing">The Contractor must comply with all of the rules and regulations for Comlink and each of its Telecom partners or subsidiaries.</p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">For a full list of rules and regulations, see the rules and regulations section of the</p>
          <p className="agreement-listing">
            <u>comlinkoartnerprogram.com</u>
            website.
          </p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="3.">
      <p className="agreement-listing">The International Contractor will be compensated for all contracted service provider installed sales properly submitted through the Comlink portal.</p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">Comlink only compensates international contractors for installed sales.</p>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">All international independent contractor’s commissions are subject to Comlink receiving funds from the Service provider /carrier.</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="(1)">
              <p className="agreement-listing">See Comlink approved provider list in the Comlink Compensation structure for more details.</p>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="c.">
          <p className="agreement-listing">Once commissions have been received, funds will be dispersed to the International Contractor via Direct Deposit on Comlink’s payroll funding date.</p>
        </li>
        <li className="agreement-listing" data-list-text="d.">
          <p className="agreement-listing">See the Comlink compensation guide for full detail on carrier compensation.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="4.">
      <p className="agreement-listing">All sales are subject to Comlink carrier charge back time frames.</p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">Charge backs are sales that are installed but cancelled within a set period of time by each individual telecom carrier.</p>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">If Charge backs occur said contract will be deducted the charge backed commissions from future commissions or be required to reimburse Comlink for charged back commissions within thirty (30) days of the charge back.</p>
        </li>
        <li className="agreement-listing" data-list-text="c.">
          <p className="agreement-listing">A terminated international contractor’s final commission payments will be held in escrow until the allotted carrier charge back window has expired.</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="(1)">
              <p className="agreement-listing">Once the charge back window expired, any remaining funds will be released to the international contractor for final payment.</p>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="5.">
      <p className="agreement-listing">Comlink has the right to:</p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">terminate this agreement if it is determined that the International Contractor is:</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="(1)">
              <p className="agreement-listing">committing fraudulent or illegal activity towards new or existing customers or potential customers.</p>
            </li>
            <li className="agreement-listing" data-list-text="(2)">
              <p className="agreement-listing">violating any of the rules and regulations for Comlink or its Telecom carrier partners or subsidiaries;</p>
            </li>
            <li className="agreement-listing" data-list-text="(3)">
              <p className="agreement-listing">doing anything considered detrimental to Comlink’s business and reputation.</p>
              <p className="agreement-listing">
                
              </p>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">withhold and forfeit any commissions from the Contractor if:</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="(1)">
              <p className="agreement-listing">fraudulent or illegal practices, including but not limited to marketing practices and selling practices, were used to obtain those commissions.</p>
              <p className="agreement-listing">19</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="(2)">
              <p className="agreement-listing">obtained in violation of the rules and regulations for Comlink or any of its Telecom partners or subsidiaries.</p>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="6.">
      <p className="agreement-listing">The International Contractor shall open and maintain a bank account within the United States or its territories for receipt of payment of all commissions during the period of time this agreement is in effect.</p>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">The International Contractor shall pay any and all wire transfer fees or other expenses, including, but not limited to any taxes that may be due and owing on amounts received or disbursed.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="7.">
      <p className="agreement-listing">This agreement is subject to change at any time without written notice provided by Comlink.</p>
    </li>
  </ol>
  <p className="agreement-listing">
    
  </p>
  <b>MUTUAL NON-DISCLOSURE AGREEMENT</b>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">The Parties to this Agreement, in consideration of the mutual covenants contained herein, hereby agree as follows:</p>
  <ol className="alpha-listing"

>
    <li className="agreement-listing" data-list-text="1.">
      <b>CONFIDENTIAL INFORMATION .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">For the purposes of this Agreement, &quot;Confidential Information&quot; will include all information designated as confidential or which could reasonably be assumed to be confidential, including without limitation, any and all present or future product or servic e information, technical or financial information, business strategies, practices, procedures, customer names or related data, details of any contracts entered into by the parties, advertising and promotional ideas or material, other business information, tec hnical information, documents, drawings, models or inventions that may be disclosed by one party (&quot;Disclosing Party&quot;) to the other party (&quot;Recipient&quot;) during the term of this Agreement, whether such disclosure is in written, oral, el electronic, website-based or other form.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="2.">
      <b>TERM .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">This Agreement will commence on the Effective Date and will continue for three (3) years, unless earlier terminated.</p>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">This Agreement may be terminated by either party with thirty (30) days prior written notice to the other.</p>
        </li>
        <li className="agreement-listing" data-list-text="c.">
          <p className="agreement-listing">The Agreement will automatically renew for another three (3) year term upon expiration of the initial term or the most recent renewal term, unless terminated as set forth in Section 2.b.</p>
        </li>
        <li className="agreement-listing" data-list-text="d.">
          <p className="agreement-listing">Upon termination, the Recipient will cease all use of the Confidential Information provided by the Disclosing Party and, upon request, will return or destroy such confidential Information.</p>
        </li>
        <li className="agreement-listing" data-list-text="e.">
          <p className="agreement-listing">The terms and conditions of this Agreement will survive termination with respect to Confidential Information disclosed prior to termination.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="3.">
      <b>PURPOSE .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">The Recipient may use the Confidential Information it receives only for the purpose of exploring a possible mutually agreeable business arrangement between the parties.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="4.">
      <b>CONFIDENTIALITY .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">The Recipient and its representatives will maintain the secret and confidential nature of the Confidential Information it receives, will permit access only to employees with a need to know, and will prevent access to it by third parties.</p>
          <p className="agreement-listing">
            
          </p>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">Further, the Recipient will, at a minimum, use the same degree of care that it uses with respect to its own confidential information to prevent its use or disclosure, but in no event will Recipient use less than reasonable care.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="5.">
      <b>EXCEPTIONS .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">This Agreement imposes no obligation upon the Recipient with respect to the following categories of information:</p>
          <ol className="alpha-listing"

>
            <li className="agreement-listing" data-list-text="(1)">
              <p className="agreement-listing">information that at the time of disclosure to Recipient was in the public domain (other than as a result of any breach of this Agreement);</p>
            </li>
            <li className="agreement-listing" data-list-text="(2)">
              <p className="agreement-listing">information that was known by the Recipient prior to receipt from the Disclosing Party (as proven by Recipient&#39;s written records);</p>
              <p className="agreement-listing">20</p>
              <p className="agreement-listing">
                
              </p>
            </li>
            <li className="agreement-listing" data-list-text="(3)">
              <p className="agreement-listing">information that, after disclosure to the Recipient, becomes known to the general public through no act or omission of Recipient; or</p>
            </li>
            <li className="agreement-listing" data-list-text="(4)">
              <p className="agreement-listing">information that is disclosed to the Recipient without an obligation of confidentiality by a third party having the legal right to do so (as proven by its written records).</p>
            </li>
          </ol>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">The Recipient may disclose Confidential Information as required by court order, but only to the extent required by such order; provided, however, that the Recipient provides notice to the Disclosing Party of such requirement to disclose, unless such notice is lawfully prohibited by the requesting governmental entity.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="6.">
      <b>OWNERSHIP .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">The Recipient acquires no rights under this Agreement to any Confidential Information of the Disclosing Party, and no right under any patent, copyright or trademark is granted by Disclosing Party to Recipient under this Agreement.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="7.">
      <b>DISCLAIMER .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          THE DISCLOSING PARTY MAKES NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE CONFIDENTIAL INFORMATION AND, IN PARTICULAR WITH RESPECT TO THE NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR OTHER RIGHTS OF THIRD PARTIES, EXCEPT THE DISCLOSING PARTY DOES REPRESENT THAT IT HAS THE RIGHT TO DISCLOSE THE CONFIDENTIAL INFORMATION TO THE RECIPIENT .
          
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="8.">
      <b>RELATIONSHIP .
      </b>
      <ol className="alpha-listing"

>
        <li className="agreement-listing" data-list-text="a.">
          <p className="agreement-listing">This Agreement does not create any joint venture, partnership or other fiduciary relationship between the parties.</p>
        </li>
        <li className="agreement-listing" data-list-text="b.">
          <p className="agreement-listing">Neither this Agreement nor the disclosure or receipt of Confidential Information will constitute or imply any promise or intention to make any purchase of products or services by either party or any commitment by either party with respect to the present or future marketing, or use of any product or service of the other, nor will it constitute any promise by either party to have any further transactions whatsoever with the other.</p>
        </li>
      </ol>
    </li>
    <li className="agreement-listing" data-list-text="9.">
      <b>EQUITABLE RELIEF .
      </b>
      <p className="agreement-listing">a Each party acknowledges that damages for improper disclosure of Confidential Information may be irreparable; therefore, the injured party is entitled to seek equitable relief, including without limitation, injunction and preliminary injunction, in addition to other remedies.</p>
    </li>
    <li class="agreement-listing">
                GENERAL CONDITIONS.
                <ol class="alpha-listing">
                  <li class="agreement-listing">
                    Governing Law. This Agreement will be construed in
                    accordance with Florida law without reference to conflict of
                    laws principles. For any disputes arising out of this
                    Agreement, the parties hereby consent and submit to the
                    exclusive jurisdiction of the federal and state courts
                    sitting in Lee County, Florida.
                  </li>
                  <li class="agreement-listing">
                    Entire Agreement. This Agreement sets forth the entire
                    understanding and agreement between the parties and
                    supersedes all proposals or communications, oral or written,
                    between the parties relating to the subject matter of the
                    Agreement. No modification of the Agreement will be binding
                    unless it is in writing and is signed by authorized
                    representatives of both parties.
                  </li>
                  <li class="agreement-listing">
                    Waiver. No waiver of any right under this Agreement will be
                    deemed effective unless contained in writing signed by a
                    duly authorized representative of the party waiving such
                    right, and no waiver of any past or present right arising
                    from any breach or failure to perform will be deemed to be a
                    waiver of any future right arising under this Agreement
                  </li>
                  <li class="agreement-listing">
                    Severability. If any provision in this Agreement is invalid
                    or unenforceable, that provision will be construed, limited,
                    modified or, if necessary, severed, to the extent necessary,
                    to eliminate its invalidity or unenforceability, and the
                    other provisions of this Agreement will remain in full force
                    and effect.
                  </li>
                  <li class="agreement-listing">
                    Assignment. Neither party may assign this Agreement without
                    the express written consent of the other party, which will
                    not be unreasonably withheld or delayed.
                  </li>
                  <li class="agreement-listing">
                    Notices. Any notice required or permitted to be sent under
                    this Agreement will be delivered by hand, by overnight
                    courier, by facsimile, or by registered mail, return receipt
                    requested, to the address of the parties first set forth in
                    this Agreement or to such other address of the parties
                    designated in writing in accordance with this Section
                  </li>
                  <li class="agreement-listing">
                    Execution in counterparts. This Agreement may be executed in
                    any number of counterparts, each of which will be deemed an
                    original, but such counterparts will together constitute but
                    one and the same document. Facsimile copies of this
                    Agreement are given the dignity of original documents.
                  </li>
                </ol>
              </li>
  </ol>
  <p className="agreement-listing">21</p>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">given the dignity of original documents.</p>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">IN WITNESS WHEREOF, the parties have executed this Agreement as of the Effective Date.</p>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">Comlink Total Solutions Corp. <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
  </p>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">By: <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u> By: <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u> Matthew Santini, CEO <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>, <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
  </p>
  <p className="agreement-listing">
    
  </p>
  <p className="agreement-listing">22</p>
</div>          
            
          </React.Fragment>
        )}

        <div className="col-xl-9" ref={validationRef}>
          <div className="form_content">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                    <label for="exampleInputEmail1">
                      Full Name:
                      <span className="red_staric">*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      placeholder=""
                      name="signersName"
                      type="text"
                      value={data.signersName}
                      className={`form-control `}
                    />
                    {errors.signersName && (
                      <div className="red_staric">{errors.signersName}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                    <label for="exampleInputEmail1">
                      Initials:
                      <span className="red_staric">*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      placeholder=""
                      name="signersTitle"
                      type="text"
                      value={data.signersTitle}
                      className={`form-control `}
                    />
                    {errors.signersTitle && (
                      <div className="red_staric">{errors.signersTitle}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                    <label for="exampleInputEmail1">
                      Signature:
                      <span className="red_staric">*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      placeholder=""
                      name="signature"
                      style={{ fontStyle: "italic" }}
                      type="text"
                      value={data.signature}
                      className={`form-control `}
                    />
                    {errors.signature && (
                      <div className="red_staric">{errors.signature}</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* <div className="d-flex flex-xl-row flex-column justify-content-center align-items-center">
          <div className="d-flex justify-content-center flex-lg-row flex-column align-items-center mb-4">
            {sign == null && (
              <React.Fragment>
                <label className="me-3">Enter your sign here</label>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    style: {
                      maxwidth: "300px",
                      height: "200px",
                      border: "2px solid black",
                      padding: "10px",
                      borderRadius: "10px", // Add this line to set the border radius
                    },
                  }}
                />
                <div className="d-flex mt-lg-0 mt-3">
                  <button
                    className="btn green-borderd-btn ms-md-3 small-btn mt-md-0 mt-3"
                    onClick={getSignatureData}
                  >
                    Apply
                  </button>
                  <button
                    className="btn theme-btn small-btn ms-md-5 mt-md-0 mt-3 ms-md-0 ms-2"
                    onClick={clearSignature}
                  >
                    Clear
                  </button>
                </div>
              </React.Fragment>
            )}
            <div ref={validationRef}>
              {sign && (
                <React.Fragment>
                  <img
                    style={{
                      maxwidth: "300px",
                      height: "200px",
                      border: "2px solid black",
                      padding: "10px",
                      borderRadius: "10px", // Add this line to set the border radius
                    }}
                    src={sign}
                    alt="Signature"
                    className="signature-pad"
                  />
                  <button
                    className="btn theme-btn small-btn ms-md-3 mt-md-0 mt-3"
                    onClick={() => setSignatureData(null)}
                  >
                    Retry
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
          {errors.sign && <div className="red_staric">{errors.sign}</div>}
        </div> */}
        <div className="terms_condition d-flex align-items-start">
          {/* <label className="custom-control overflow-checkbox relative">
            <input
              name="agreement"
              checked={agreement}
              className={`overflow-control-input ${
                errors.agreement ? "is-invalid" : ""
              }`}
              type="checkbox"
              onChange={handleCheckboxChange}
            />
            <span className="overflow-control-indicator "></span>
            <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
              <p className="Submitting_form">
                By Submitting This Form Above You Permit Comlink Express Consent
                To Contact You At The Number And/Or Email Address You Have
                Provided Above Via A Representative And With Automated
                Technology In Relation To This Inquiry Via Phone, E-Mail, Or Sms
                Text Message. You Understand That Making A Purchase Is Not
                Required To Consent To Receive Communications From Comlink.
                <span className="red_staric">*</span>
              </p>
            </span>
          </label> */}
          <button
            onClick={() => handleUpButtonClick()}
            type="button"
            className="btn btn-sm btn-primary fix-ed-butt-on-scro-ll-btm"
          >
            <img src={upArrow} alt="upArrow" />
          </button>
        </div>
        {/* {errors.agreement && (
          <div className="red_staric">{errors.agreement}</div>
        )} */}
        <div className="agreement-footer">
          <img src={confidentialLogo} alt="confidentialLogo" />
        </div>
        <p className="d-flex justify-content-center">
          Proprietary & Confidential Information of Comlink Total Solutions
          Corp.
        </p>
      </div>
    </div>
  );
};

export default Agreements;
